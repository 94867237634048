import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const sIDs = new Set([]);
const grno = new Set([]);

export default function AssetObservation() {
  const [open, setOpen] = useState(false);
  const [scoreOpen, setScoreOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState("");
  const [designation, setDesignation] = useState("");
  const [observers, setObservers] = useState([]);
  const [observations, setObservations] = useState([]);
  const [selectedObservation, setSelectedObservation] = useState({});
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [editVariables, setEditVariables] = useState([]);
  const [selectedObserver, setSelectedObserver] = useState(null);
  const [selectInputObserver, setSelectedInputObserver] = React.useState("");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [inactiveStudents, setInactiveStudents] = useState([]);
  const [formData, setFormData] = useState({
    schoolid: "",
    sessionname: "",
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchoolId(schoolId);
      setSchool(accessObject[0].School.NameOfSchool);
      if (schoolId !== null) {
        _fetchAssetObservationVariables();
      }
    }
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      const schoolName = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .School.NameOfSchool;
      setSchoolId(schoolID);
      setSchool(schoolName);
      if (schoolID !== null) {
        _fetchAssetObservationVariables();
      }
    }
  }, []);

  useEffect(() => {
    //_fetchObserverations();
  }, []);

  //   const _onSubmit = async (e) => {
  //     e.preventDefault();
  //     const observationData = {
  //       SchoolID: schoolId,
  //       ObserverName: formData.ObserverName,
  //       ObserverDesignation: formData.ObserverDesignation,
  //       Comments: formData.Comments,
  //       Scores: selectedVariables,
  //       ObservationDate: formData.ObservationDate,
  //     };
  //     console.log("selectedVariables", selectedVariables);
  //     console.log("formData", observationData);
  //     setLoading((prevState) => !prevState);
  //     try {
  //       const { data, status } = await axiosInstance.post(
  //         "/asset-observation",
  //         observationData
  //       );
  //       console.log("observationData", data);
  //       if (status === 200) {
  //         //_fetchObserverations();
  //         setLoading((prevState) => !prevState);
  //         setSnackMessage(data.message);
  //         setSnackOpen(true);
  //         setOpen(false);
  //       }
  //     } catch (error) {
  //       setLoading((prevState) => !prevState);
  //       console.log(error);
  //     }
  //   };

  const _onSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams();
    searchParams.append("sessionname", formData.sessionname);
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    try {
      const { data, status } = await axiosInstance.get(
        "Rollback/get-inactive-students",
        {
          params: searchParams,
        }
      );

      if (status === 200) {
        let d = [...data.data[0]];
        d.forEach((x) => {
          x.selected = false;
        });
        console.log("d",d)
        setInactiveStudents(d);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (e) => {
    e.preventDefault();
    const payload = {
      sessionname: formData.sessionname,
      IDs: Array.from(sIDs),
      grno: Array.from(grno),

    };
    for(var i = 0; i<1;i++)
      {
        console.log("payload"+payload.IDs)
        console.log("payload"+payload.sessionname)
        console.log("payload"+payload.grno)
      }
    try {
      const { data, status } = await axiosInstance.post("Rollback/update-status-code", payload);

      if (status === 200) {
        console.log(data);
        _onSubmit(e); // Refresh the list after update
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchInactiveStudents = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/asset-observation/${schoolId}`
        );
        console.log("observerations", data.data);
        if (status === 200) {
          setObservations(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/asset-observation/${schoolID}`
        );
        console.log("observerations", data.data);
        if (status === 200) {
          setObservations(data.data);
        }
      }
    } catch (error) {}
  };

  const _fetchAssetObservationVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-school-observation-list"
      );
      if (status === 200) {
        let tempArr = [...data.data];
        tempArr.forEach((x) => {
          x.VariablesDetailID = x.ID;
          x.Score = 5;
        });
        setVariables(tempArr);
        setSelectedVariables(tempArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleVariableChange = (e, variable) => {
    console.log("variable", variable, e);
    setSelectedVariables((prevState) =>
      prevState.map((item) => {
        if (variable.ID === item.ID) {
          return {
            VariablesDetailID: variable.ID,
            Score: e.target.value,
          };
        }
        return item;
      })
    );
  };

  const _handleDateOfObservation = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ObservationDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const snack_handleClose = () => {
    setSnackOpen(false);
  };

  function updateIndividualSelection(index, checked, sId, GRNO) {
    let array = [...inactiveStudents];
    array[index]["selected"] = checked;
    setInactiveStudents(array);
    if (checked) {
      sIDs.add(sId);
      grno.add(GRNO);
    } else {
      sIDs.delete(sId);
      grno.delete(GRNO);
    }
  }

  function updateAllStudents(checked) {
    let array = [...inactiveStudents];
    array.forEach((x) => {
      x.selected = checked;
    });

    setInactiveStudents(array);
    if (checked) {
      array.forEach((x) => {
        sIDs.add(x.SID);
      grno.add(x.GRNO);

      });
    } else {
      array.forEach((x) => {
        sIDs.delete(x.SID);
      grno.delete(x.GRNO);

      });
    }
  }

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Student Rollback Module
          </Typography>
        </Stack>
        <Box width={300}>
          <FormControl fullWidth>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                className="w-49"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Rollback
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={sIDs.size == 0}
                className="w-49 ms-1"
                onClick={updateStatus}
              >
                Update
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Student Rollback Form
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Session"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="sessionname"
                  onChange={_handleOnChange}
                  size="small"
                >
                  <MenuItem value="2015-2016">2015-2016</MenuItem>
                  <MenuItem value="2016-2017">2016-2017</MenuItem>
                  <MenuItem value="2017-2018">2017-2018</MenuItem>
                  <MenuItem value="2018-2019">2018-2019</MenuItem>
                  <MenuItem value="2019-2020">2019-2020</MenuItem>
                  <MenuItem value="2020-2021">2020-2021</MenuItem>
                  <MenuItem value="2021-2022">2021-2022</MenuItem>
                  <MenuItem value="2022-2023">2022-2023</MenuItem>
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                  <MenuItem value="2024-2025">2024-2025</MenuItem>
                  <MenuItem value="2025-2026">2025-2026</MenuItem>
                  <MenuItem value="2026-2027">2026-2027</MenuItem>
                  <MenuItem value="2027-2028">2027-2028</MenuItem>
                  <MenuItem value="2028-2029">2028-2029</MenuItem>
                  <MenuItem value="2029-2030">2029-2030</MenuItem>
                  <MenuItem value="2030-2031">2030-2031</MenuItem>
                  <MenuItem value="2031-2032">2031-2032</MenuItem>
                  <MenuItem value="2032-2033">2032-2033</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={formData.sessionname == "" || loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Get Rollback Students
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {inactiveStudents.length != 0 && (
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    onClick={(e) => {
                      updateAllStudents(e.target.checked);
                    }}
                  />
                )}
                Select
              </TableCell>
              <TableCell align="left">SID</TableCell>
              <TableCell align="center">Student Name</TableCell>
              <TableCell align="center">Class Name</TableCell>
              <TableCell align="center">Father's Name</TableCell>
              <TableCell align="center">GRNO</TableCell>
              <TableCell align="right">StatusCode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inactiveStudents.map((student, index) => {
              return (
                <TableRow
                  key={String(index)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Checkbox
                      checked={student.selected}
                      inputProps={{ "aria-label": "controlled" }}
                      onClick={(e) => {
                        updateIndividualSelection(
                          index,
                          e.target.checked,
                          student.SID,
                          student.GRNO
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{student.StudentName}</TableCell>
                  <TableCell align="center">{student.ClassName}</TableCell>
                  <TableCell align="center">{student.FathersName}</TableCell>
                  <TableCell align="center">{student.GRNO}</TableCell>
                  <TableCell align="right">{student.StatusCode}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
