import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axiosInstance, { baseURL } from "../../Api/axios.instance";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import { Avatar, Button, CardHeader, Chip, IconButton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import { TextField, MenuItem } from "@mui/material/";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import "../../Styles/styles.css";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MinioUrl } from "./../../Api/axios.instance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function fetchSiblings(studentId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/students/find-siblings/${studentId}`
      );
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

const _createUnderTaking = async (data, studentId) => {
  try {
    const { data: reponse, status } = await axiosInstance.post(
      `/students/undertaking/${studentId}`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (status === 200) {
      return reponse;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const addMedicalProfileData = async (data, id) => {
  try {
    const { data: reponse, status } = await axiosInstance.post(
      `/students/medical-profile/${id}`,
      data
    );
    if (status === 200) {
      return reponse;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StudentDetails(params) {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [studentData, setStudentData] = useState({});
  const [open, setOpen] = useState(false);
  const [openMedicalModal, setMedicalModalOpen] = useState(false);
  const [medicalProfileData, setMedicalProfileData] = useState({
    Vaccination: true,
    BloodGroup: "O negative",
    AnyAlergy: 0,
    AnyDisease: 0,
    AnyDiseaseDetail: "none",
    GeneticDisease: 0,
    PhysicalDisability: 0,
  });
  const [feeStructure, setFeeStructure] = useState(null);
  const [_class, setClass] = useState(null);
  const [_classATOA, setClassATOA] = useState(null);
  const [siblings, setSiblings] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMedicalOpen, setSnackMedicalOpen] = useState(false);
  const [underTakingData, setUnderTakingData] = useState({
    is_Syed: "",
    Applicant_Eligible: "",
    CurrentSession: "",
    DateofTaking: dayjs(),
    Gold_Value: "",
    Silver_Value: "",
    Cash_Value: "",
    Comm_Stuff_Value: "",
    Extra_Assets: "",
    Loan: "",
    Net_worth: "",
  });
  const formRef = useRef(null);
  let { state: locationState, pathname } = useLocation();
  useEffect(() => {
    if (locationState !== null && locationState !== undefined) {
      console.log("Student Data", locationState.studentData);
      setStudentData(locationState.studentData);
    } else {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get(`/students/${id}`);
          if (status === 200) {
            setStudentData(data.data);
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (studentData.StudentMedical) {
      setMedicalProfileData(studentData.StudentMedical);
    }
    if (studentData.Fees) {
      setFeeStructure(studentData.Fees);
    }
    if (studentData.ClassModel) {
      setClass(studentData.ClassModel);
    }
    if (studentData.ClassAtTheTimeOfAdmissionModel) {
      setClassATOA(studentData.ClassAtTheTimeOfAdmissionModel);
    }
    if (studentData.SID) {
      _fetchSiblings(studentData.SID);
      // _createUnderTaking(data, studentData.SID);
    }
  }, [studentData]);
  const _fetchSiblings = async (id) => {
    try {
      const response = await fetchSiblings(id);
      setSiblings(response);
    } catch (error) {
      setSiblings([]);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const _onHandleChange = (e) => {
    setUnderTakingData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleDataOfUnderTaking = (event) => {
    setUnderTakingData((prevValue) => {
      return {
        ...prevValue,
        DateofTaking: dayjs(event).format("YYYY-MM-DD"),
      };
    });
  };

  const handleOpen = (item) => (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleMedicalModalOpen = () => {
    setMedicalModalOpen(true);
  };
  const handleMedicalModalClose = () => setMedicalModalOpen(false);

  const _submitMedicalDetails = async (e) => {
    e.preventDefault();
    const id = studentData.SID;
    try {
      const newMedicalProfileData = await addMedicalProfileData(
        medicalProfileData,
        id
      );
      formRef.current.reset();
      setSnackMedicalOpen(true);
      handleMedicalModalClose();
    } catch (error) {
      console.log(error);
    }
  };

  const _SubmitForm = async (e) => {
    e.preventDefault();
    console.log("DATA", underTakingData);
    if (studentData.SID) {
      const underTaking = await _createUnderTaking(
        underTakingData,
        studentData.SID
      );
      if (underTaking.hasOwnProperty("success")) {
        setSnackOpen(true);
        (async () => {
          try {
            const { data, status } = await axiosInstance.get(
              `/students/${studentData.SID}`
            );
            if (status === 200) {
              setStudentData(data.data);
            }
          } catch (error) {
            console.log(error);
            return null;
          }
        })();
        setOpen(false);
      } else {
        alert("An Error Has Occured");
      }
    }
    formRef.current.reset();
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };

  const medicalSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackMedicalOpen(false);
  };

  const _setMedicalProfileData = React.useCallback((e) => {
    setMedicalProfileData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    // console.log(medicalProfileData)
  });
  const _onFeePaid = (item) => (event) => {};
  return (
    <Box sx={{ width: "100%" }}>
      <Card variant="outlined" className="show-more">
        <CardContent>
          <div className="row align-items-center mb-5">
            <div className="col-12 col-md-8">
              <Box sx={{ width: "100%" }}>
                <div className="student-profile--wrapper">
                  <div className="student-profile--inner">
                    <Avatar
                      variant="square"
                      src={
                        studentData.image !== null
                          ? `${MinioUrl}/students/${studentData.image}`
                          : ""
                      }
                      sx={{ width: 150, height: 150, mr: 2 }}
                    />
                    <div className="student-content">
                      <h4>{studentData.StudentName}</h4>
                      <p className="text-align">
                        <div className="icon-wrap">
                          <CorporateFareRoundedIcon />
                        </div>
                        {studentData?.School?.NameOfSchool}
                        <div className="class-name">
                          <div className="icon-wrap">
                            <SchoolRoundedIcon />
                          </div>
                          <span>{studentData?.ClassModel?.ClassName}</span>
                        </div>
                      </p>
                      <Chip
                        avatar={
                          // <Avatar alt="Natacha" src={} />
                          <LocalHospitalIcon sx={{ width: 20, height: 20 }} />
                        }
                        className="cursor-pointer"
                        label="Set Medical Profile"
                        variant="outlined"
                        onClick={handleMedicalModalOpen}
                      />
                    </div>
                  </div>
                  <div className="student-class"></div>
                </div>
              </Box>
            </div>
            <div className="col-12 col-md-4 text-right"></div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Student Info" {...a11yProps(0)} />
                <Tab label="Guardian Info" {...a11yProps(1)} />
                <Tab label="School Information" {...a11yProps(2)} />
                <Tab label="Under Taking" {...a11yProps(3)} />
                <Tab label="Siblings Info" {...a11yProps(4)} />
                <Tab label="Fee Structure" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Student Name:</div>
                    <div className="data-value">{studentData.StudentName}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Father's Name:</div>
                    <div className="data-value">{studentData.FathersName}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Guardian's Name:</div>
                    <div className="data-value">{studentData.GuardianName}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Guardian's Relation:</div>
                    <div className="data-value">
                      {studentData.GuardianRelation}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Gender:</div>
                    <div className="data-value">{studentData.Gender}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Date Of Birth:</div>
                    <div className="data-value">{studentData.DOB}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">School Code:</div>
                    <div className="data-value">{studentData.School_code}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Address:</div>
                    <div className="data-value">{studentData.Address}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">UC:</div>
                    <div className="data-value">{studentData.UC}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Taluka:</div>
                    <div className="data-value">{studentData.Taluka}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">District:</div>
                    <div className="data-value">{studentData.District}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Mother Tongue:</div>
                    <div className="data-value">
                      {studentData.MotherTongue === "0" && "Urdu"}
                      {studentData.MotherTongue === "1" && "Sindhi"}
                      {studentData.MotherTongue === "2" && "Punjabi"}
                      {studentData.MotherTongue === "3" && "Pashto"}
                      {studentData.MotherTongue === "4" && "Saraiki"}
                      {studentData.MotherTongue === "5" && "Balochi"}
                      {studentData.MotherTongue === "6" && "Hindko"}
                      {studentData.MotherTongue === "7" && "Other"}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Nationality:</div>
                    <div className="data-value">{studentData.Ntionality}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Religion:</div>
                    <div className="data-value">
                      {studentData.Religion === "0" && "Islam"}
                      {studentData.Religion === "1" && "Christianity"}
                      {studentData.Religion === "2" && "Judaism"}
                      {studentData.Religion === "3" && "Buddhism"}
                      {studentData.Religion === "4" && "Hinduism"}
                      {studentData.Religion === "5" && "Sikhism"}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Nazra Status:</div>
                    <div className="data-value">
                      {studentData.IsNazra_Done == 1 ? "Yes" : "No"}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Old School Name:</div>
                    <div className="data-value">
                      {studentData.Old_School_Name}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Old Class Name:</div>
                    <div className="data-value">
                      {studentData.Old_ClassName}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Reason For Leaving:</div>
                    <div className="data-value">
                      {studentData.Reason_Leaved}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Orphan:</div>
                    <div className="data-value">
                      {studentData.IsOrphan !== null
                        ? studentData.IsOrphan
                          ? "Yes"
                          : "NO"
                        : "Not Defined"}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Dropout Date:</div>
                    <div className="data-value">
                      {dayjs(studentData.Dropout_Date).format("YYYY-MM-DD")}
                    </div>
                  </div>
                </Box>
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">NIC Guardian:</div>
                    <div className="data-value">{studentData.NIC_Guardian}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Mother qualification:</div>
                    <div className="data-value">
                      {studentData.Mother_qualification}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Father Qualification:</div>
                    <div className="data-value">
                      {studentData.Father_Qualification}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Father Occupation:</div>
                    <div className="data-value">
                      {studentData.Father_Occupation}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Father Nazra Done?</div>
                    <div className="data-value">
                      {studentData.IsFatherNazra_Done}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Mother Nazra Done?:</div>
                    <div className="data-value">
                      {studentData.IsMotherNazra_Done}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Mother Occupation:</div>
                    <div className="data-value">
                      {studentData.Mother_Occupation}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Siblings</div>
                    <div className="data-value">{studentData.Siblings}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Dependents:</div>
                    <div className="data-value">{studentData.Dependents}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Average Monthly Income:</div>
                    <div className="data-value">
                      {studentData.Average_Monthly_Income}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">NIC Father:</div>
                    <div className="data-value">{studentData.NIC_Father}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">NIC Mother:</div>
                    <div className="data-value">{studentData.NIC_Mother}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Present Address:</div>
                    <div className="data-value">
                      {studentData.Present_Address}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Permanent Address:</div>
                    <div className="data-value">
                      {studentData.PermanentAddress}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Parent Cell No:</div>
                    <div className="data-value">
                      {studentData.Parent_Cell_No}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Parent Whatssapp No:</div>
                    <div className="data-value">
                      {studentData.Parent_Whatssapp_No}
                    </div>
                  </div>
                </Box>
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">GR #</div>
                    <div className="data-value">{studentData.GRNO}</div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Fee</div>
                    <div className="data-value">{studentData.Fee}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Class</div>
                    <div className="data-value">
                      {_class !== null ? _class.ClassName : "Not Defined"}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Date Of Addmission:</div>
                    <div className="data-value">
                      {dayjs(studentData.Date_Of_Addmission).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">
                      Class at the time of Admission
                    </div>
                    <div className="data-value">
                      {_classATOA !== null
                        ? _classATOA.ClassName
                        : "Not Defined"}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Remarks:</div>
                    <div className="data-value">{studentData.Remarks}</div>
                  </div>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                sx={{ mb: 1 }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <div className="profile-data">
                    <div className="data-key">Current Session:</div>
                    <div className="data-value">
                      {studentData.ActiveSession}
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: "100%", marginBottom: "8px" }}></Box>
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={3}>
              {studentData?.UnderTaking !== null ? (
                <>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">is Syed</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.is_Syed ? "True" : "False"}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Applicant Eligible</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Applicant_Eligible
                            ? "True"
                            : "False"}
                        </div>
                      </div>
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Current Session:</div>
                        <div className="data-value">
                          {studentData.CurrentSession}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Date of UnderTaking:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.DateofTaking !== null
                            ? dayjs(
                                studentData.UnderTaking?.DateofTaking
                              ).format("DD-MM-YYYY")
                            : "Not Defined"}
                        </div>
                      </div>
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Gold Value:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Gold_Value}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Silver Value:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Silver_Value}
                        </div>
                      </div>
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Cash Value:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Cash_Value}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Commercial Stuff Value:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Comm_Stuff_Value}
                        </div>
                      </div>
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Extra Assets:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Extra_Assets}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Loan:</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Loan}
                        </div>
                      </div>
                    </Box>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                    sx={{ mb: 1 }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <div className="profile-data">
                        <div className="data-key">Net worth</div>
                        <div className="data-value">
                          {studentData.UnderTaking?.Net_worth}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}></Box>
                  </Stack>
                </>
              ) : (
                <div className="button-wrapper">
                  <Button type="button" onClick={handleOpen()}>
                    Perform Under Taking
                  </Button>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Student Name</TableCell>
                      <TableCell align="center">School</TableCell>
                      <TableCell align="center">Father Name</TableCell>
                      <TableCell align="center">Father CNIC</TableCell>
                      <TableCell align="center">Fee</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {siblings.map((item, index) => (
                      <TableRow
                        key={0}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.StudentName}
                        </TableCell>
                        <TableCell align="center">{item.School_code}</TableCell>
                        <TableCell align="center">{item.FathersName}</TableCell>
                        <TableCell align="center">
                          {item.NIC_Father ?? item.NIC_Guardian}
                        </TableCell>
                        <TableCell align="center">{item.Fee ?? 0}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Fee Head</TableCell>
                      <TableCell align="center">Narration</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Due Date </TableCell>
                      <TableCell align="center">Paid Date</TableCell>
                      <TableCell align="right">Paid</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feeStructure?.map((item) => (
                      <TableRow
                        key={0}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.Fee_Head}
                        </TableCell>
                        <TableCell align="center">{item.Narration}</TableCell>
                        <TableCell align="center">{item.Amount}</TableCell>
                        <TableCell align="center">
                          {dayjs(item.DueDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(item.PaidDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {item.Paid ? "YES" : "NO"}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            type="button"
                            variant="contained"
                            sx={{ marginRight: "10px" }}
                            onClick={_onFeePaid(item)}
                          >
                            Set to Paid
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
      {medicalProfileData !== null ? (
        <Card variant="outlined">
          <CardContent>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "column",
              }}
              sx={{ mb: 2 }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom
                >
                  Medical Profile
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Is Vaccinated?</TableCell>
                        <TableCell align="center">Any Disease?</TableCell>
                        <TableCell align="center">Disease Details</TableCell>
                        <TableCell align="center">Genetic Disease? </TableCell>
                        <TableCell align="center">
                          Physical Disability?
                        </TableCell>
                        <TableCell align="right">Blood Group</TableCell>
                        <TableCell align="right">Any Allergies?</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={0}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {medicalProfileData.Vaccination === "true"
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {medicalProfileData.AnyDisease === "true"
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {medicalProfileData.AnyDiseaseDetail}
                        </TableCell>
                        <TableCell align="center">
                          {medicalProfileData.GeneticDisease === "true"
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {medicalProfileData.PhysicalDisability === "true"
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell align="right">
                          {medicalProfileData.BloodGroup}
                        </TableCell>
                        <TableCell align="right">
                          {medicalProfileData.AnyAlergy}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      ) : (
        ""
      )}

      <Modal
        open={openMedicalModal}
        onClose={handleMedicalModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          sx={style}
          component="form"
          onSubmit={_submitMedicalDetails}
          ref={formRef}
        >
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              id="standard-select-currency"
              select
              label="Vaccination"
              sx={{ display: "flex", flex: 1 }}
              value={medicalProfileData?.Vaccination}
              onChange={_setMedicalProfileData}
              variant="outlined"
              size="small"
              name="Vaccination"
            >
              <MenuItem key={0} value={true}>
                YES
              </MenuItem>
              <MenuItem key={1} value={false}>
                NO
              </MenuItem>
            </TextField>
            <TextField
              value={medicalProfileData?.BloodGroup}
              onChange={_setMedicalProfileData}
              label="Blood Group"
              variant="outlined"
              size="small"
              sx={{ display: "flex", flex: 1 }}
              name="BloodGroup"
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              id="standard-select-currency"
              select
              label="Any Alergy"
              sx={{ display: "flex", flex: 1 }}
              value={medicalProfileData?.AnyAlergy}
              onChange={_setMedicalProfileData}
              variant="outlined"
              size="small"
              name="AnyAlergy"
            >
              <MenuItem key={0} value={0}>
                NO
              </MenuItem>
              <MenuItem key={1} value={1}>
                YES
              </MenuItem>
            </TextField>
            <TextField
              id="standard-select-currency"
              select
              label="Any Disease"
              sx={{ display: "flex", flex: 1 }}
              value={medicalProfileData?.AnyDisease}
              onChange={_setMedicalProfileData}
              variant="outlined"
              size="small"
              name="AnyDisease"
            >
              <MenuItem key={0} value={0}>
                NO
              </MenuItem>
              <MenuItem key={1} value={1}>
                YES
              </MenuItem>
            </TextField>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              value={medicalProfileData?.AnyDiseaseDetail}
              onChange={_setMedicalProfileData}
              label="Any Disease Detail"
              variant="outlined"
              size="small"
              disabled={medicalProfileData?.AnyDisease === 0 ? true : false}
              sx={{ display: "flex", flex: 1 }}
              name="AnyDiseaseDetail"
            />
            <TextField
              id="standard-select-currency"
              select
              label="Genetic Disease"
              sx={{ display: "flex", flex: 1 }}
              value={medicalProfileData?.GeneticDisease}
              onChange={_setMedicalProfileData}
              variant="outlined"
              size="small"
              name="GeneticDisease"
            >
              <MenuItem key={0} value={0}>
                NO
              </MenuItem>
              <MenuItem key={1} value={1}>
                YES
              </MenuItem>
            </TextField>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              id="standard-select-currency"
              select
              label="Physical Disability"
              sx={{ display: "flex", flex: 1 }}
              value={medicalProfileData?.PhysicalDisability}
              onChange={_setMedicalProfileData}
              variant="outlined"
              size="small"
              name="PhysicalDisability"
            >
              <MenuItem key={0} value={0}>
                NO
              </MenuItem>
              <MenuItem key={1} value={1}>
                YES
              </MenuItem>
            </TextField>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Button variant="contained" type="submit" sx={{ width: "100%" }}>
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style} component="form" onSubmit={_SubmitForm} ref={formRef}>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              id="standard-select-currency"
              select
              label="is Syed"
              sx={{ display: "flex", flex: 1 }}
              value={underTakingData.is_Syed}
              onChange={_onHandleChange}
              variant="outlined"
              size="small"
              name="is_Syed"
            >
              <MenuItem key={0} value={true}>
                YES
              </MenuItem>
              <MenuItem key={1} value={false}>
                NO
              </MenuItem>
            </TextField>
            <TextField
              id="standard-select-currency"
              select
              label="Applicant Eligible"
              sx={{ display: "flex", flex: 1 }}
              value={underTakingData.Applicant_Eligible}
              onChange={_onHandleChange}
              variant="outlined"
              size="small"
              // disabled={formData.is_Syed ? true : false}
              name="Applicant_Eligible"
            >
              <MenuItem key={0} value={true}>
                YES
              </MenuItem>
              <MenuItem key={1} value={false}>
                NO
              </MenuItem>
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                value={underTakingData.DateofTaking}
                label="Date of Under Taking"
                inputFormat="MM/DD/YYYY"
                onChange={handleDataOfUnderTaking}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              value={underTakingData.Gold_Value || ""}
              onChange={_onHandleChange}
              label="Gold Value"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Gold_Value"
              type="number"
            />
            <TextField
              value={underTakingData.Silver_Value || ""}
              onChange={_onHandleChange}
              label="Silver Value"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Silver_Value"
              type="number"
            />
            <TextField
              value={underTakingData.Cash_Value || ""}
              onChange={_onHandleChange}
              label="Cash Value"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Cash_Value"
              type="number"
            />
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              value={underTakingData.Comm_Stuff_Value || ""}
              onChange={_onHandleChange}
              label="Commercial Stuff Value"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Comm_Stuff_Value"
              type="number"
            />
            <TextField
              value={underTakingData.Extra_Assets || ""}
              onChange={_onHandleChange}
              label="Extra Assets"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Extra_Assets"
              type="number"
            />
            <TextField
              value={underTakingData.Loan || ""}
              onChange={_onHandleChange}
              label="Loan"
              variant="outlined"
              size="small"
              disabled={underTakingData.is_Syed ? true : false}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Loan"
              type="number"
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <TextField
              disabled={underTakingData.is_Syed ? true : false}
              value={underTakingData.Net_worth || ""}
              onChange={_onHandleChange}
              label="Net Worth"
              variant="outlined"
              size="small"
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{ display: "flex", flex: 1 }}
              name="Net_worth"
              type="number"
            />
          </Stack>

          <Button variant="contained" type="submit">
            Create Under Taking
          </Button>
        </Stack>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Under Taking Data has been added.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackMedicalOpen}
        autoHideDuration={6000}
        onClose={medicalSnack_handleClose}
      >
        <Alert
          onClose={medicalSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Medical Profile Data Changes Saved!.
        </Alert>
      </Snackbar>
    </Box>
  );
}
