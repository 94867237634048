import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FixedAssets() {
  const navigate = useNavigate();
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  const [snackbar, setSnackBar] = useState(false);
  const [snackbarError, setSnackBarError] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackErrorMessage, setSnackErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [uID, setUID] = useState(null);
  const [schools, setSchools] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [variables, setVariables] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [fixedAssetsData, setFixedAssetsData] = useState([]);
  const [selectedFixedAssets, setSelectedFixedAssets] = useState({});
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [formData, setFormData] = useState({
    SchoolID: schoolId,
    CostCenterId: null,
    CostCenterType: null,
    AccountCode: null,
    AssetListItemType: "",
    Description: "",
    Model: "",
    DepreciationPerYear: 0,
    AssetCode: `AI(${small_id})`,
    StaffID: "",
    CreatedByUser: "",
    OpeningBal: 0,
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL" || accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      console.log("schoolId in useEffect", accessObject);
      const UserId = accessObject[0].UserId;
      if (UserId === undefined) {
        const getUserID = JSON.parse(localStorage.getItem("User"));
        console.log("OK", getUserID);
        setUID(getUserID.id);
      } else {
        setUID(UserId);
      }
      setSchoolId(schoolId);

      console.log("schoolId", accessObject);
      _fetchClassesBySchool(schoolId);
      _fetchTeachersBySchool(schoolId);
    }
    _fetchsetFixedAssetsData();
    _fetchFixedAssetListVariables();
  }, []);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const _fetchFixedAssetListVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-fixed-asset-list"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchsetFixedAssetsData = async () => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      fetchsetFixedAssetsData(schoolID);
    }
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      fetchsetFixedAssetsData(schoolID);
    }
  };

  const fetchsetFixedAssetsData = async (schoolId) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/fixed-assets/${schoolId}`
      );
      console.log("setFixedAssetsData data", data.data);
      if (status === 200) {
        setFixedAssetsData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      console.log("setTeachers", data.data);
      if (status === 200) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    const strSchoolId = "" + schoolId;
    const strAssetQuantity = "" + formData.OpeningBal;
    const pad = "00000";
    const maskedSchoolID =
      pad.substring(0, pad.length - strSchoolId.length) + strSchoolId;
    const maskedAssetQuantity =
      pad.substring(0, pad.length - strAssetQuantity.length) + strAssetQuantity;
    const AssetListItemType = formData.AssetListItemType.substring(
      0,
      3
    ).toLocaleUpperCase();
    // const assetCode =
    //   maskedSchoolID + "-" + AssetListItemType + "-" + maskedAssetQuantity;
    const assetCode = maskedSchoolID + "-" + AssetListItemType;
    console.log("formData assetCode", assetCode);
    const fixedAssetsData = {
      SchoolID: schoolId,
      CostCenterId: formData.CostCenterId,
      CostCenterType: formData.CostCenterType,
      AccountCode: formData.AccountCode,
      AssetListItemType: formData.AssetListItemType,
      Description: formData.Description,
      Model: formData.Model,
      DepreciationPerYear: formData.DepreciationPerYear,
      AssetCode: assetCode,
      StaffID: formData.StaffID,
      CreatedByUser: formData.CreatedByUser,
      OpeningBal: formData.OpeningBal,
    };
    console.log("fixedAssetsData", fixedAssetsData);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/fixed-assets",
        fixedAssetsData
      );
      console.log("Response", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpen(false);
        _fetchsetFixedAssetsData();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    console.log("selectedPtmAttendance", selectedFixedAssets);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/fixed-assets/${selectedFixedAssets.FixedAssetRecordId}`,
        selectedFixedAssets
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpenEdit(false);
        _fetchsetFixedAssetsData();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      if (accesLevel === "ADMIN") {
        return {
          ...prevState,
          CreatedByUser: uID,
          [e.target.name]: e.target.value,
        };
      }
      return {
        ...prevState,
        SchoolID: schoolId,
        CreatedByUser: uID,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleAssetItemChange = (e) => {
    const isPresent = fixedAssetsData.some(
      (asset) => asset.AssetListItemType === e.target.value
    );

    console.log("isPresent", isPresent);

    if (isPresent === false) {
      setFormData((prevState) => {
        if (accesLevel === "ADMIN") {
          return {
            ...prevState,
            CreatedByUser: uID,
            [e.target.name]: e.target.value,
          };
        }
        return {
          ...prevState,
          SchoolID: schoolId,
          CreatedByUser: uID,
          [e.target.name]: e.target.value,
        };
      });
    } else {
      setFormData((prevState) => {
        return {
          ...prevState,
          AssetListItemType: "",
        };
      });
      setSnackErrorMessage(
        "This asset item is already exist in asset item list! please select other Asset Item."
      );
      setSnackBarError(true);
      console.log("IN ELSE");
    }
  };

  const handleNavigate = () => {
    navigate("/fixed-assets/fixed-asset-observation");
  };

  const _handleOnAdminSchoolChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        SchoolID: e.target.value,
        CreatedByUser: uID,
      };
    });
  };

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    console.log("schools", cluster[0].School);
    setSchools(cluster[0].School);
  };

  const _handleOnEditChange = (e) => {
    setSelectedFixedAssets((prevState) => {
      return {
        ...prevState,
        CreatedByUser: uID,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleClick = (item) => (event) => {
    console.log("observation", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedFixedAssets(item);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  const snackError_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBarError(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" component="h1">
                Fixed Assets Module
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container spacing={2}>
                  {/*<Grid item xs={6}>
                    {currentPageCRUD.read && (
                      <FormControl fullWidth sx={{ flex: "1 1 50%" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleNavigate}
                        >
                          Asset observation
                        </Button>
                      </FormControl>
                    )}
                  </Grid>*/}
                  <Grid item xs={12}>
                    {currentPageCRUD.create && (
                      <FormControl fullWidth sx={{ flex: "1 1 50%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                        >
                          Add Asset
                        </Button>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Item Name</TableCell>
                  <TableCell align="center">Item Description</TableCell>
                  <TableCell align="center">Item Model</TableCell>
                  <TableCell align="center">Depreciation Per Year</TableCell>
                  <TableCell align="center">Opening Quantity</TableCell>
                  <TableCell align="center">Asset Code</TableCell>
                  <TableCell align="center">Assigned Staff</TableCell>
                  <TableCell align="center">Created By User</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  fixedAssetsData.length > 0 &&
                  fixedAssetsData.map((item, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.AssetListItemType}
                        </TableCell>
                        <TableCell align="center">{item.Description}</TableCell>
                        <TableCell align="center">{item.Model}</TableCell>
                        <TableCell align="center">{`${item.DepreciationPerYear}%`}</TableCell>
                        <TableCell align="center">{item.OpeningBal}</TableCell>
                        <TableCell align="center">{item.AssetCode}</TableCell>
                        <TableCell align="center">
                          {item.Teacher ? item.Teacher.TeacherName : ""}
                        </TableCell>
                        <TableCell align="center">
                          {item.User ? item.User.username : ""}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(item)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  navigate("fixed-asset-transaction", {
                    state: {
                      selectedFixedAssets,
                    },
                  })
                }
              >
                Transaction
              </MenuItem>
              {currentPageCRUD.update && (
                <MenuItem onClick={() => setOpenEdit(true)}>Edit</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>Create Fixed Asset List</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onSubmit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Fixed Asset List"
                        sx={{ display: "flex", flex: 1 }}
                        value={formData.AssetListItemType}
                        variant="outlined"
                        name="AssetListItemType"
                        required
                        onChange={_handleAssetItemChange}
                        size="small"
                      >
                        {variables.length > 0 &&
                          variables.map((variable, index) => (
                            <MenuItem key={index} value={variable.Caption}>
                              {variable.Caption}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Quantity"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="OpeningBal"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Description"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Description"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Model"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Model"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Depreciation Per Year"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="DepreciationPerYear"
                        type="number"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Staff to Assign"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="StaffID"
                        value={formData.StaffID}
                        // required
                        onChange={_handleOnChange}
                        size="small"
                      >
                        {teachers.map((teacher, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={teacher.SchoolTeachersQId}
                            >
                              {teacher.TeacherName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Create Fixed Asset
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="sm"
            open={openEdit}
            onClose={handleEditClose}
          >
            <DialogTitle>Edit Fixed Asset</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onEdit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Fixed Asset List"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedFixedAssets.AssetListItemType}
                        variant="outlined"
                        name="AssetListItemType"
                        required
                        onChange={_handleOnChange}
                        size="small"
                      >
                        {variables.length > 0 &&
                          variables.map((variable, index) => (
                            <MenuItem key={index} value={variable.Caption}>
                              {variable.Caption}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Quantity"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="OpeningBal"
                        value={selectedFixedAssets.OpeningBal}
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Description"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Description"
                        value={selectedFixedAssets.Description}
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Model"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Model"
                        value={selectedFixedAssets.Model}
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Depreciation Per Year"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="DepreciationPerYear"
                        type="number"
                        value={selectedFixedAssets.DepreciationPerYear}
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Staff to Assign"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="StaffID"
                        value={selectedFixedAssets.StaffID}
                        // required
                        onChange={_handleOnEditChange}
                        size="small"
                      >
                        {teachers.map((teacher, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={teacher.SchoolTeachersQId}
                            >
                              {teacher.TeacherName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Update Fixed Asset
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarError}
        autoHideDuration={6000}
        onClose={snackError_handleClose}
      >
        <Alert
          onClose={snackError_handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
