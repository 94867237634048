import { Button, Divider, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

export default function Card({
  cardProps,
  counter,
  text,
  linkLabel,
  link,
  icon,
}) {
  const configuredTabs = ["PTM Parent Attendance", "CAP Parent Attendance"];
  return (
    <Paper {...cardProps}>
      <Stack justifyContent="space-between" sx={{ p: 2 }} flexDirection="row">
        <Stack>
          <Typography variant="subtitle2" color="white">
            {text}
          </Typography>
          <Typography
            variant={configuredTabs.includes(text) ? "p" : "h4"}
            color="white"
          >
            {counter}
          </Typography>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          {icon}
        </Stack>
      </Stack>
      <Divider />
    </Paper>
  );
}
