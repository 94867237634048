import React, { useEffect } from "react";
import { Chip, IconButton, Switch, Snackbar, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FeeList from "./FeeList";
import axiosInstance from "../../Api/axios.instance";
import { Button, Divider, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import AddFee from "./AddFee";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { getStudents } from "../Students/Students";
import { LoadingButton } from "@mui/lab";
import MuiAlert from "@mui/material/Alert";
import { getClasses } from "../ClassSubjects/Classes";
import useSession from "../../CustomHooks/useSession";

const AlertSnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Fee() {
  const [feeList, setFeeList] = React.useState([]);
  const [item, setItem] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const addFeeRef = React.useRef(null);
  const [students, setStudents] = React.useState([]);
  const [selectedstudents, setSelectedStudents] = React.useState([]);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [classes, setClasses] = React.useState([]);
  const [studentInput, setStudentInput] = React.useState("");
  const [classInput, setClassInput] = React.useState("");
  const [filters, setFilters] = React.useState({
    studentId: null,
    classId: null,
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [action, setAction] = React.useState("Add");
  const [selectedItem, setSelectedItem] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedItem(null);
    setAction("Add");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    _fetchFeeList();
    _fetchStudents();
    _fetchClasses();
    console.log("SELECTED ITEM", selectedItem);
  }, [selectedItem]);
  const _fetchFeeList = async () => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        setLoading((prevState) => !prevState);
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        const { status, data } = await axiosInstance.get(`/fees/by-school`, {
          params: searchParams,
        });
        if (status === 200) {
          setFeeList(data.data);
          setLoading((prevState) => !prevState);
        } else {
          setLoading((prevState) => !prevState);
          setFeeList([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenSnack = (newState) => {
    setSnackOpen(newState);
  };
  const handleSnackMessage = (newState) => {
    setSnackMessage(newState);
  };
  const _fetchStudents = async () => {
    try {
      const schoolId =
        localStorage.getItem("UserAccess") !== null
          ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
          : 0;
      const response = await getStudents(schoolId);
      setStudents(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const _generateVoucher = () => {
    addFeeRef.current.submitForm();
  };
  const _onStudentChange = (event, newValue) => {
    setFilters((prevStat) => {
      return {
        ...prevStat,
        studentId: newValue,
      };
    });
  };
  const _onClassChange = (event, newValue) => {
    setFilters((prevStat) => {
      return {
        ...prevStat,
        classId: newValue,
      };
    });
  };
  const _onStudentInputValue = (event, newInputValue) => {
    setStudentInput(newInputValue);
  };
  const _onClassInputValue = (event, newInputValue) => {
    setClassInput(newInputValue);
  };
  const _setDateValue = (type) => (event) => {
    setFilters((prevStat) => {
      return {
        ...prevStat,
        [type]: event,
      };
    });
  };
  const _applyFilter = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        if (filters.classId !== null) {
          searchParams.append("class_id", filters.classId?.ID);
        }
        if (filters.studentId !== null) {
          searchParams.append("student_id", filters.studentId?.SID);
        }
        if (filters.from) {
          searchParams.append("from", filters.from);
        }
        if (filters.to) {
          searchParams.append("to", filters.to);
        }
        const { status, data } = await axiosInstance.get(`/fees/by-school`, {
          params: searchParams,
        });
        setFeeList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _clearFilters = () => {
    setFilters({
      studentId: null,
      classId: null,
      from: dayjs().format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
    _fetchFeeList();
  };
  const _editFee = (item) => {
    setSelectedItem(item);
    setAction("Edit");
    setOpen(true);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Fee Management Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Generate Voucher button to generate a new fee
          </Typography>
        </Stack>
        {currentPageCRUD.create && (
          <Button variant="contained" onClick={handleClickOpen}>
            Generate Voucher
          </Button>
        )}
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ mt: 2, mb: 2 }}>
        {currentPageCRUD.read && (
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Autocomplete
                    sx={{ display: "flex", flex: 1, mr: 1 }}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={students}
                    getOptionLabel={(option) =>
                      option.StudentName ?? "Select Student"
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.SID}>
                          {option.StudentName}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Student" />
                    )}
                    value={filters.studentId}
                    onChange={_onStudentChange}
                    inputValue={studentInput}
                    onInputChange={_onStudentInputValue}
                    isOptionEqualToValue={(option, value) =>
                      option.SID === value.SID
                    }
                  />
                  <Autocomplete
                    sx={{ display: "flex", flex: 1, mr: 1 }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.ID}>
                          {option.ClassName}
                        </li>
                      );
                    }}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={classes}
                    renderInput={(params) => (
                      <TextField {...params} label="Class" />
                    )}
                    getOptionLabel={(option) =>
                      option.ClassName ?? "Select Class"
                    }
                    value={filters.classId}
                    onChange={_onClassChange}
                    inputValue={classInput}
                    onInputChange={_onClassInputValue}
                    isOptionEqualToValue={(option, value) =>
                      option.ID === value.ID
                    }
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      inputFormat="DD-MM-YYYY"
                      label="From Date"
                      value={filters.from}
                      onChange={_setDateValue("from")}
                      renderInput={(params) => (
                        <TextField
                          sx={{ display: "flex", flex: 1, mr: 1 }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      inputFormat="DD-MM-YYYY"
                      label="To Date"
                      value={filters.to}
                      onChange={_setDateValue("to")}
                      renderInput={(params) => (
                        <TextField
                          sx={{ display: "flex", flex: 1 }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ mt: 2 }}
                >
                  <LoadingButton onClick={_applyFilter}>
                    Apply Filter
                  </LoadingButton>
                  <Button variant="text" onClick={_clearFilters}>
                    Clear Filter
                  </Button>
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <FeeList
        loading={loading}
        snackOpen={handleOpenSnack}
        snackMessage={handleSnackMessage}
        list={feeList}
        fetchList={_fetchFeeList}
        edit={_editFee}
        returenedItem={setSelectedItem}
      />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {action === "Add" ? "Generate" : "Edit"} Fee Voucher
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <AddFee
              selectedItem={selectedItem}
              action={action}
              ref={addFeeRef}
              fetchList={_fetchFeeList}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={_generateVoucher}>
            {action === "Add" ? "Generate" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <AlertSnack
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </AlertSnack>
      </Snackbar>
    </Box>
  );
}
