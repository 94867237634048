import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import QuizIcon from "@mui/icons-material/Quiz";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function SchoolRecord() {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [openScore, setOpenScore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [editSelectedVariables, setEditSelectedVariables] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [school, setSchool] = useState("");
  const [schoolId, setSchoolId] = useState(null);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({
    SchoolID: schoolId,
    RecordingDate: dayjs().format("YYYY-MM-DD"),
    TeacherID: null,
    Name: null,
    Designation: null,
    Department: null,
  });
  const openAnchor = Boolean(anchorEl);
  useEffect(() => {
    if (accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchSchoolRecordVariables();
      _fetchSchoolRecords();
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchSchoolRecordVariables();
      _fetchSchoolRecords();
    }
    if (accesLevel === "CLUSTER") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        // _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchSchoolRecordVariables();
      _fetchSchoolRecords();
    }
  }, []);

  useEffect(() => {
    console.log("aagya")
    if (formData.TeacherID !== 0) {
      setFormData({
        ...formData,
        Name: null,
        Designation: null,
        Department: null,
      });
    }
  }, [formData.TeacherID]);

  useEffect(() => {}, []);
  const _fetchSchoolRecordVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-school-record"
      );
      if (status === 200) {
        console.log("Testing", data.data);
        setVariables(data.data);
        setSelectedVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchSchoolRecords = async () => {
    try {
      const { data, status } = await axiosInstance.get("/school-record");
      if (status === 200) {
        console.log("Testing", data.data);
        setRecords(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      if (status === 200) {
        let arr = [...data.data];
        arr.unshift({ SchoolTeachersQId: 0, TeacherName: "None" });
        setTeachers(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleOnChange = (e) => {
    console.log(e.target);
    setFormData((prevState) => {
      return {
        ...prevState,
        SchoolID: schoolId,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleEditOnChange = (e) => {
    setSelectedRecord((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _handleDateOfRecording = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        RecordingDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleEditDateOfRecording = (e) => {
    setSelectedRecord((prevState) => {
      return {
        ...prevState,
        RecordingDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleVariableChange = (e, index, variable) => {
    const { name, value } = e.target;
    const newVariables = [...selectedVariables];
    newVariables[index][name] = value;
    newVariables[index].VariableID = variable.ID;
    newVariables[index].ManagedOn = formData.RecordingDate;

    setSelectedVariables(newVariables);
  };
  const _handleEditVariableChange = (e, index) => {
    const { name, value } = e.target;
    const newVariables = [...selectedRecord.SchoolRecordScore];
    newVariables[index][name] = value;
    newVariables[index].ManagedOn = formData.RecordingDate;

    setSelectedRecord((prevState) => {
      return {
        ...prevState,
        SchoolRecordScore: newVariables,
      };
    });
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const schoolRecordData = {
      SchoolID: formData.SchoolID,
      TeacherID: formData.TeacherID == 0 ? null : formData.TeacherID,
      RecordingDate: formData.RecordingDate,
      Scores: selectedVariables,
      Name: formData.Name,
      Designation: formData.Designation,
      Department: formData.Department,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/school-record",
        schoolRecordData
      );
      if (status === 200) {
        _fetchSchoolRecords();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
        setFormData({
          SchoolID: schoolId,
          RecordingDate: dayjs().format("YYYY-MM-DD"),
          TeacherID: null,
          Name: null,
          Designation: null,
          Department: null,
        })
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();
    const schoolRecordData = {
      TeacherID: selectedRecord.TeacherID,
      RecordingDate: selectedRecord.RecordingDate,
      Scores: selectedRecord.SchoolRecordScore,
      Name: formData.Name,
      Designation: formData.Designation,
      Department: formData.Department,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/school-record/${selectedRecord.id}`,
        schoolRecordData
      );
      if (status === 200) {
        _fetchSchoolRecords();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenEdit(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onDelete = async () => {
    handleAnchorClose();
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.delete(
        `/school-record/${selectedRecord.id}`
      );
      if (status === 200) {
        _fetchSchoolRecords();
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _selectTeacherName = (id) => {
    const name = teachers.find((teacher) => id === teacher.SchoolTeachersQId);
    if (name !== null && name !== undefined) {
      return name.TeacherName;
    }
    return name;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (record) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecord(record);
    setEditSelectedVariables(record.SchoolRecordScore);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _handleScoreOpen = (record) => () => {
    console.log("record", record);
    setSelectedRecord(record);
    setOpenScore(true);
  };
  const handleScoreClose = () => {
    setOpenScore(false);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
    handleAnchorClose();
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const snack_handleClose = () => {
    setSnackOpen(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" component="h1">
                School Record Module
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  Add Record
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Recording Date</TableCell>
                  {/* <TableCell align="center">Teacher Name</TableCell> */}
                  <TableCell align="center">Questions</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  records.map((record, index) => {
                    return (
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(record.RecordingDate).format("YYYY-MM-DD")}
                        </TableCell>
                        {/* <TableCell align="center" sx={{ width: "20%" }}>
                          {record.Teacher ? record.Teacher.TeacherName : ""}
                        </TableCell> */}
                        <TableCell align="center" sx={{ width: "20%" }}>
                          {currentPageCRUD.read && (
                            <Button
                              contained
                              color="primary"
                              onClick={_handleScoreOpen(record)}
                            >
                              Score
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(record)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleAnchorClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {currentPageCRUD.delete && (
              <MenuItem onClick={_onDelete} color="error">
                Delete
              </MenuItem>
            )}

            {currentPageCRUD.update && (
              <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
            )}
          </Menu>
        </Stack>
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
          <DialogTitle>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" component="h1">
                School Record Module
              </Typography>
              <Typography variant="subtitle1" component="h4">
                <strong>School: </strong> {school}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                <strong>Date: </strong> {formData.RecordingDate}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                <strong>Audit By: </strong>{" "}
                {formData.TeacherID !== null
                  ? _selectTeacherName(formData.TeacherID)
                  : "Select Teacher"}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box onSubmit={_onSubmit} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date of Recording"
                          name="RecordingDate"
                          value={formData.RecordingDate}
                          onChange={_handleDateOfRecording}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Teacher"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="TeacherID"
                        // value={formik.values.TeacherID}
                        required
                        onChange={_handleOnChange}
                        size="small"
                      >
                        {teachers.map((teacher, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={teacher.SchoolTeachersQId}
                            >
                              {teacher.TeacherName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    {((formData?.TeacherID??0) == 0) && (
                      <>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Name"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Name"
                            value={formData.Name}
                            required
                            onChange={_handleOnChange}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Designation"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Designation"
                            value={formData.Designation}
                            required
                            onChange={_handleOnChange}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Department"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Department"
                            value={formData.Department}
                            required
                            onChange={_handleOnChange}
                            size="small"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <SectionDivider text="School Record Questions" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            School Record / Register / File
                          </TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Performance</TableCell>
                          <TableCell align="center">
                            Remarks by Auditor
                          </TableCell>
                          <TableCell align="right">
                            Final Remarks by Auditor
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {variables.map((variable, index) => {
                          return (
                            <TableRow
                              key={String(index)}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {variable.Caption}
                                <TextField
                                  size="small"
                                  label="VariableID"
                                  variant="outlined"
                                  sx={{ display: "none", flex: 1 }}
                                  type="hidden"
                                  name="VariableID"
                                  value={variable.ID}
                                  onChange={(e) =>
                                    _handleVariableChange(e, index, variable)
                                  }
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ width: "20%" }}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Status"
                                  sx={{ display: "flex", flex: 1 }}
                                  variant="outlined"
                                  name="UpdateStatus"
                                  value={selectedVariables.UpdateStatus}
                                  onChange={(e) =>
                                    _handleVariableChange(e, index, variable)
                                  }
                                  size="small"
                                >
                                  <MenuItem value={1}>YES</MenuItem>
                                  <MenuItem value={0}>NO</MenuItem>
                                </TextField>
                              </TableCell>
                              <TableCell align="center" sx={{ width: "20%" }}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Performance"
                                  sx={{ display: "flex", flex: 1 }}
                                  variant="outlined"
                                  name="ConditionScore"
                                  value={selectedVariables.ConditionScore}
                                  onChange={(e) =>
                                    _handleVariableChange(e, index, variable)
                                  }
                                  size="small"
                                >
                                  <MenuItem value={4}>Excellent</MenuItem>
                                  <MenuItem value={3}>Good</MenuItem>
                                  <MenuItem value={2}>Better</MenuItem>
                                  <MenuItem value={1}>
                                    Need Improvement
                                  </MenuItem>
                                </TextField>
                              </TableCell>
                              <TableCell align="right" sx={{ width: "20%" }}>
                                <TextareaAutosize
                                  aria-label="minimum height"
                                  minRows={1}
                                  maxLength={60}
                                  placeholder="Narration"
                                  name="Narration"
                                  value={selectedVariables.Narration}
                                  onChange={(e) =>
                                    _handleVariableChange(e, index, variable)
                                  }
                                  size="small"
                                  style={{ width: "100%", padding: 15 }}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{ width: "20%" }}>
                                <TextareaAutosize
                                  aria-label="minimum height"
                                  minRows={1}
                                  maxLength={60}
                                  placeholder="Final Narration"
                                  name="Auditor_Narration"
                                  value={selectedVariables.Auditor_Narration}
                                  onChange={(e) =>
                                    _handleVariableChange(e, index, variable)
                                  }
                                  size="small"
                                  style={{ width: "100%", padding: 15 }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // onClick={formik.handleSubmit}
                  >
                    Create Record
                    {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openEdit}
          onClose={handleEditClose}
        >
          <DialogTitle>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" component="h1">
                School Record Module
              </Typography>
              <Typography variant="subtitle1" component="h4">
                <strong>School: </strong> {school}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                <strong>Date: </strong> {dayjs().format("DD-MM-YYYY")}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                <strong>Audit By: </strong>
                {selectedRecord.TeacherID !== null
                  ? _selectTeacherName(selectedRecord.TeacherID)
                  : "Select Teacher"}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box onSubmit={_onEdit} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date of Recording"
                          name="RecordingDate"
                          value={selectedRecord.RecordingDate}
                          onChange={_handleEditDateOfRecording}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Teacher"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="TeacherID"
                        value={selectedRecord.TeacherID}
                        required
                        onChange={_handleEditOnChange}
                        size="small"
                      >
                        {teachers.map((teacher, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={teacher.SchoolTeachersQId}
                            >
                              {teacher.TeacherName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    {formData.TeacherID == 0 && (
                      <>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Name"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Name"
                            value={formData.Name}
                            required
                            onChange={_handleEditOnChange}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Designation"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Designation"
                            value={formData.Designation}
                            required
                            onChange={_handleEditOnChange}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="standard-select-currency"
                            label="Enter Department"
                            sx={{ display: "flex", flex: 1 }}
                            variant="outlined"
                            name="Department"
                            value={formData.Department}
                            required
                            onChange={_handleEditOnChange}
                            size="small"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <SectionDivider text="School Record Questions" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            School Record / Register / File
                          </TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Performance</TableCell>
                          <TableCell align="center">
                            Remarks by Auditor
                          </TableCell>
                          <TableCell align="right">
                            Final Remarks by Auditor
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(selectedRecord).length > 0 &&
                          selectedRecord.SchoolRecordScore.map(
                            (variable, index) => {
                              return (
                                <TableRow
                                  key={String(index)}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {variable?.Variable?.Caption}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "20%" }}
                                  >
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Status"
                                      sx={{ display: "flex", flex: 1 }}
                                      value={variable.UpdateStatus}
                                      variant="outlined"
                                      name="UpdateStatus"
                                      onChange={(e) =>
                                        _handleEditVariableChange(e, index)
                                      }
                                      size="small"
                                    >
                                      <MenuItem value={true}>YES</MenuItem>
                                      <MenuItem value={false}>NO</MenuItem>
                                    </TextField>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "20%" }}
                                  >
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Performance"
                                      sx={{ display: "flex", flex: 1 }}
                                      value={variable.ConditionScore}
                                      variant="outlined"
                                      name="ConditionScore"
                                      onChange={(e) =>
                                        _handleEditVariableChange(e, index)
                                      }
                                      size="small"
                                    >
                                      <MenuItem value={4}>Excellent</MenuItem>
                                      <MenuItem value={3}>Good</MenuItem>
                                      <MenuItem value={2}>Better</MenuItem>
                                      <MenuItem value={1}>
                                        Need Improvement
                                      </MenuItem>
                                    </TextField>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "20%" }}
                                  >
                                    <TextareaAutosize
                                      aria-label="minimum height"
                                      minRows={1}
                                      placeholder="Narration"
                                      name="Narration"
                                      size="small"
                                      value={variable.Narration}
                                      onChange={(e) =>
                                        _handleEditVariableChange(e, index)
                                      }
                                      style={{ width: "100%", padding: 15 }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "20%" }}
                                  >
                                    <TextareaAutosize
                                      aria-label="minimum height"
                                      minRows={1}
                                      placeholder="Final Narration"
                                      name="Auditor_Narration"
                                      size="small"
                                      value={variable.Auditor_Narration}
                                      onChange={(e) =>
                                        _handleEditVariableChange(e, index)
                                      }
                                      style={{ width: "100%", padding: 15 }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Update Record
                    {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openScore}
          onClose={handleScoreClose}
        >
          <DialogTitle>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" component="h1">
                School Record Question Score
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Divider sx={{ mt: 1, mb: 2 }} />
            {Object.keys(selectedRecord).length > 0 &&
              selectedRecord.SchoolRecordScore.map((score, index) => {
                return (
                  <Paper
                    sx={{ padding: "10px 20px", marginBottom: "20px" }}
                    component={Paper}
                    key={String(index)}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle1" component="span">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <QuizIcon
                                color="primary"
                                style={{ marginRight: "5px" }}
                              />
                              <span>{score?.Variable?.Caption}</span>
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="right">
                          <Typography variant="subtitle1" component="span">
                            <strong>Date:</strong>{" "}
                            {dayjs(score.ManagedOn).format("DD-MM-YYYY")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ width: "100%" }}>
                                <div className="profile-data">
                                  <div className="data-key">Status:</div>
                                  <div className="data-value">
                                    {score.UpdateStatus ? "YES" : "NO"}
                                  </div>
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ width: "100%" }}>
                                <div className="profile-data">
                                  <div className="data-key">Performance:</div>
                                  <div className="data-value">
                                    {score.ConditionScore === 1 &&
                                      "Need Improvement"}
                                    {score.ConditionScore === 2 && "Better"}
                                    {score.ConditionScore === 3 && "Good"}
                                    {score.ConditionScore === 4 && "Excellent"}
                                  </div>
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography variant="subtitle1" component="span">
                            <strong style={{ marginRight: "5px" }}>
                              Remarks:
                            </strong>
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {score.Narration !== null
                              ? score.Narration
                              : "No Remarks"}
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {score.Auditor_Narration !== null
                              ? score.Auditor_Narration
                              : "No Final Remarks"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                );
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleScoreClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={6000}
          onClose={snack_handleClose}
        >
          <Alert
            onClose={snack_handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
