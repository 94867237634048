import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axiosInstance from "../../Api/axios.instance";
import { Typography, Button, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import useSession from "../../CustomHooks/useSession";
import { useLocation, useNavigate } from "react-router-dom";

export function getClusters() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/clusters/all");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function Clusters(props) {
  const { state, pathname } = useLocation();
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [clusters, setClusters] = React.useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    _fetchClusters();
  }, []);
  const _fetchClusters = async () => {
    try {
      const response = await getClusters();
      setClusters(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _onDetailsClick = (details) => (e) => {
    navigate(`details/${details.RegionId}`, {
      state: {
        clusters: details,
      },
    });
  };
  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography variant="h4" gutterBottom>
          Clusters
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{
            color: grey[600],
          }}
        >
          List of Clusters (Regions)
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Region Name</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Reason</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageCRUD.read &&
              clusters.map((row, index) => (
                <TableRow
                  key={String(index)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.RegionName}
                  </TableCell>
                  <TableCell align="center">{row.CreatedDate}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">
                    {row.Reason === "" || row.Reason === null ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{
                          color: grey[600],
                        }}
                      >
                        No Reason Defined
                      </Typography>
                    ) : (
                      row.Reason
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained" onClick={_onDetailsClick(row)}>
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
