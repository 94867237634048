import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Modal from "@mui/material/Modal";
// import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";

export default function ActiveStudentDetails() {
  const [results, setResults] = useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 500) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    _onSubmit();
  }, [null]);

  useEffect(() => {
    console.log(results);
  }, [results]);

  const _onSubmit = async () => {
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/active-student-report-school-wise",
          {
            params: searchParams,
          }
        );
        console.log("activeStudentDetails", data.data[0]);
        setResults(data.data[0]);
      }
      if (accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/active-student-report-school-wise",
          {
            params: searchParams,
          }
        );
        console.log("activeStudentDetails", data.data[0]);
        setResults(data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const cardWidth = 280; // Adjust the width of the card
    const cardHeight = 200; // Adjust the height of the card
    const startY = 10; // Adjust the starting Y position
    const spaceBetweenCards = 10; // Adjust the space between cards
    const rowsPerPage = 1; // Number of rows to display per page
    const pageCount = Math.ceil(results.length / rowsPerPage);
  
    for (let page = 0; page < pageCount; page++) {
      if (page > 0) {
        doc.addPage(); // Add a new page for each set of rows
      }
  
      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, results.length);
      const rowsToShow = results.slice(startIndex, endIndex);
  
      rowsToShow.forEach((item, index) => {
        const cardY = startY + index * (cardHeight + spaceBetweenCards);
  
        // Draw card border
        doc.setDrawColor(0);
        doc.setFillColor(255);
        doc.rect(10, cardY, cardWidth, cardHeight, "F"); // Adjust dimensions as needed
  
        // Add content to card
        const startX = 15;
        let textY = cardY + 10; // Adjust starting Y position
  
        Object.entries(item).forEach(([key, value]) => {
          if (textY < cardY + cardHeight - 5) { // Check if there's enough space in the card
            doc.setFontSize(10); // Adjust font size
            doc.text(startX, textY, `${key}: ${value}`);
            textY += 5; // Adjust spacing between lines
          } else {
            // If there's not enough space for all content, continue on next page
            doc.addPage();
            textY = startY + 10; // Reset Y position for the new page
          }
        });
      });
    }
  
    doc.save("Active-Students-Details.pdf");
  };
  
  const exportXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(results, {
      header: [],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Active Students Details");
    XLSX.writeFile(workbook, "Active-Students-Details.xlsx");
  };

  const cellStyle = {
    fontSize: "12px",
    border: "0px",
  };


  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Active Student's Details
          </Typography>
        </Stack>
      </Stack>

      {/* PDF XLSX */}
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2}>
        {results.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {index + 1}. {item.StudentName}
                </Typography>
                <Divider />
                <Grid container spacing={2} style={{ marginTop: "8px" }}>

                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ActiveSession:</strong> {item.ActiveSession}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Address:</strong> {item.Address}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Average_Monthly_Income:</strong>{" "}
                      {item.Average_Monthly_Income}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ClassID:</strong> {item.ClassID}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ClassName:</strong> {item.ClassName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>CurrentSession:</strong> {item.CurrentSession}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>DOB:</strong> {item.DOB}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Date_Of_Addmission:</strong>{" "}
                      {item.Date_Of_Addmission}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Dependents:</strong> {item.Dependents}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>District:</strong> {item.District}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Father_Occupation:</strong>{" "}
                      {item.Father_Occupation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Father_Qualification:</strong>{" "}
                      {item.Father_Qualification}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>FathersName:</strong> {item.FathersName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Fee:</strong> {item.Fee}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GRNO:</strong> {item.GRNO}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Gender:</strong> {item.Gender}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GuardianName:</strong> {item.GuardianName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GuardianRelation:</strong> {item.GuardianRelation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsFatherNazra_Done:</strong>{" "}
                      {item.IsFatherNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsMotherNazra_Done:</strong>{" "}
                      {item.IsMotherNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsNazra_Done:</strong> {item.IsNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsOrphan:</strong> {item.IsOrphan}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>MotherTongue:</strong> {item.MotherTongue}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Mother_Occupation:</strong>{" "}
                      {item.Mother_Occupation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Mother_qualification:</strong>{" "}
                      {item.Mother_qualification}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Father:</strong> {item.NIC_Father}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Guardian:</strong> {item.NIC_Guardian}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Mother:</strong> {item.NIC_Mother}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NameOfSchool:</strong> {item.NameOfSchool}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Ntionality:</strong> {item.Ntionality}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Old_ClassName:</strong> {item.Old_ClassName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Old_School_Name:</strong> {item.Old_School_Name}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Cell_No:</strong> {item.Parent_Cell_No}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Education_Highest_Level:</strong>{" "}
                      {item.Parent_Education_Highest_Level}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Whatssapp_No:</strong>{" "}
                      {item.Parent_Whatssapp_No}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>PermanentAddress:</strong> {item.PermanentAddress}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Present_Address:</strong> {item.Present_Address}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>RegionName:</strong> {item.RegionName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Religion:</strong> {item.Religion}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Remarks:</strong> {item.Remarks}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>SID:</strong> {item.SID}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>School_code:</strong> {item.School_code}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Siblings:</strong> {item.Siblings}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>StatusCode:</strong> {item.StatusCode}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Taluka:</strong> {item.Taluka}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>UC:</strong> {item.UC}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}