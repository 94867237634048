import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2980b9",
      light: "#34495e",
    },
    pale: {
      main: "#f5f6fa",
      contrastText: "#383838",
    },
    logout: {
      main: "#ecf0f1",
      contrastText: "#383838",
    },
  },
});

export { theme as styles, ThemeProvider as StyleProvider };
