import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useSession() {
  const { state, pathname } = useLocation();
  const [role] = useState(localStorage.getItem("Role"));
  const [access] = useState(JSON.parse(localStorage.getItem("UserAccess")));
  const [token] = useState(localStorage.getItem("userToken"));
  const [user] = useState(localStorage.getItem("User"));
  const [accesLevel] = useState(
    JSON.parse(localStorage.getItem("User")).AccessLevel
  );
  const roleObjData = Object.keys(role).length > 0 && role;
  const [roleObj] = useState(JSON.parse(roleObjData));
  const permissionObjData =
    Object.keys(roleObj).length > 0 && roleObj.permissions;
  const [permissionObj] = useState(JSON.parse(permissionObjData));
  const [pages] = useState(permissionObj.pages);
  const [currentPage] = useState(
    pages.filter((item) => item.route === pathname)
  );

  const [schools, setSchools] = useState([]);
  useEffect(() => {
    const USER_ACCESS = JSON.parse(localStorage.getItem("UserAccess"));
    setSchools(USER_ACCESS.map((item) => item.SchoolId));
  }, []);

  return { role, access, token, user, accesLevel, schools, currentPage };
}
