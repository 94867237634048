import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Schools from "../Schools";
import axiosInstance from '../../Api/axios.instance';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

export default function SchoolDetails(params) {
  const { id } = useParams()
  const [schoolData, setSchoolData] = useState({})
  let { state: locationState, pathname } = useLocation();
  useEffect(() => {
    if (locationState != null) {
      setSchoolData(locationState.schoolData)
    } else {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get(`/schools/${id}`);
          if (status === 200) {
            setSchoolData(data.data)
          }
        } catch (error) {
          console.log(error)
          return null;
        }
      })();
    }

  }, [])


  return (
    <Box sx={{ width: "100%" }}>
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Name Of School:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.NameOfSchool}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Address:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.Address}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Area:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.Area} {schoolData.SquareYardsOrKanal}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Registered:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.Registered}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Ownership Status:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.OwnerShipStatus}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              No. Of Rooms:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.NoOfRooms}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Status:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.Status}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Date Created:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.DateCreated}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Date Closed:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.DateClosed}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Reason For Closure:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.ReasonForClosure || "Data Not Found"}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Date Data Entry:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.DateDataEntry}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginBottom:'20px' }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Data Entry Operator:
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
              {schoolData.DataEntryOperator || "No Data Found"}
            </Typography>
          </Box>
        </CardContent>
      </Card >
    </Box >
  );
}
