import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
// import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import axiosInstance from "../../Api/axios.instance";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserDetails(params) {
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const { id } = useParams();
  const [userData, setuserData] = useState({});
  const [schools, setSchools] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [selection, setSelection] = useState({
    accessLevel: "ADMIN",
    role: "",
    cluster: "",
    school: "",
    staffId: "",
    username: "",
    password: "",
  });
  let { state: locationState, pathname } = useLocation();
  useEffect(() => {
    if (locationState !== undefined) {
      setuserData(locationState.userData);
    } else {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get(`/users/${id}`);
          if (status === 200) {
            setuserData(data.data);
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      })();
    }
  }, []);

  const handleChange = (e) => {
    setSelection((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    if (e.target.name === "cluster") {
      setSelection((prevState) => {
        return {
          ...prevState,
          school: "",
        };
      });
      const schools = params.clusters.filter(
        (item) => item.RegionId === e.target.value
      );
      setSchools(schools[0].School);
    }
  };
  const _renderSchools = () => {
    if (
      schools.length > 0 &&
      selection.accessLevel !== "ADMIN" &&
      selection.accessLevel !== "CLUSTER"
    ) {
      return (
        <FormControl sx={{ display: "flex", flex: 1 }}>
          <InputLabel id="demo-simple-select-label-school">
            Select School
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-school"
            id="demo-simple-select"
            value={selection.school}
            label="Select Access Level"
            onChange={handleChange}
            name="school"
          >
            {schools.map((school, index) => (
              <MenuItem key={String(index)} value={school.SchoolId}>
                {school.NameOfSchool}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/*<Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Username:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {userData.username ? userData.username : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Role Id:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {userData.RoleId ? userData.RoleId : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Access Level:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {userData.AccessLevel ? userData.AccessLevel : "Undefined"}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>*/}
      <Box component="form" onSubmit={() => {}}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          sx={{ mb: 2 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
        >
          <FormControl sx={{ display: "flex", flex: 1 }}>
            <InputLabel id="demo-simple-select-label">
              Select Access Level
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="accessLevel"
              value={selection.accessLevel}
              label="Select Access Level"
              onChange={handleChange}
            >
              <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
              <MenuItem value={"CLUSTER"}>CLUSTER</MenuItem>
              <MenuItem value={"SCHOOL"}>SCHOOL</MenuItem>
              <MenuItem value={"STAFF"}>STAFF</MenuItem>
              {/* <MenuItem value={"TEACHER"}>TEACHER</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl sx={{ display: "flex", flex: 1 }}>
            <InputLabel id="demo-simple-select-label-role">
              Select Role
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label-role"
              id="demo-simple-select"
              //   value={selection.role}
              name="role"
              label="Select Access Level"
              //   onChange={handleChange}
            >
              {/*{roles.map((role, index) => (
                <MenuItem key={String(index)} value={role.RoleId}>
                  {role.RoleName}
                </MenuItem>
              ))}*/}
            </Select>
          </FormControl>
        </Stack>
        {selection.accessLevel !== "ADMIN" && (
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <FormControl sx={{ display: "flex", flex: 1 }}>
              <InputLabel id="demo-simple-select-label-cluster">
                Select Custer
              </InputLabel>
              <Select
                required={selection.accessLevel !== "ADMIN"}
                labelId="demo-simple-select-label-cluster"
                id="demo-simple-select"
                // value={selection.cluster}
                label="Select Access Level"
                // onChange={handleChange}
                name="cluster"
              >
                {/*{clusters.map((cluster, index) => (
                  <MenuItem key={String(index)} value={cluster.RegionId}>
                    {cluster.RegionName}
                  </MenuItem>
                ))}*/}
              </Select>
            </FormControl>
            {_renderSchools()}
          </Stack>
        )}
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          sx={{ mb: 2 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
        >
          <TextField
            required
            label="Username"
            variant="standard"
            sx={{ display: "flex", flex: 1 }}
            name="username"
            onChange={handleChange}
          />
          <TextField
            required
            label="Full Name"
            variant="standard"
            sx={{ display: "flex", flex: 1 }}
            name="fullName"
            onChange={handleChange}
          />
          <TextField
            required
            label="Email"
            type="email"
            variant="standard"
            sx={{ display: "flex", flex: 1 }}
            name="email"
            onChange={handleChange}
          />
          {/*<FormControl sx={{ display: "flex", flex: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              id="standard-adornment-password"
              //   type={showPassword ? "text" : "password"}
              name="password"
              //   onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>*/}
        </Stack>
        <Box sx={{ mt: 3 }}>
          <LoadingButton variant="contained" fullWidth type="Submit">
            Save
          </LoadingButton>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={snack_handleClose}
        >
          <Alert
            onClose={snack_handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
