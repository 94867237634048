import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axiosInstance from "../../Api/axios.instance";
import UserList from "./UserList";
import AddUser from "./AddUser";
import { getClusters } from "../../Pages/Clusters/Clusters";
import RolesList from "./RolesList";
import AddUserRoles from "./AddUserRoles";
import useSession from "../../CustomHooks/useSession";

export function getStaff() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/staff");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getUsers() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/users");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getRoles() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/roles");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Users() {
  const [value, setValue] = React.useState(0);
  const [staff, setStaff] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    _fetchStaff();
    _fetchClusters();
    _fetchRoles();
    _fetchUsers();
  }, []);
  const _fetchStaff = async () => {
    try {
      const response = await getStaff();
      setStaff(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClusters = async () => {
    try {
      const response = await getClusters();
      setClusters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.user);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const _refetchUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.user);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Add Users" {...a11yProps(1)} />
          <Tab label="User Roles" {...a11yProps(2)} />
          <Tab label="Roles List" {...a11yProps(3)} />
        </Tabs>
      </Box>
      {currentPageCRUD.read && (
        <TabPanel value={value} index={0}>
          <UserList users={users} />
        </TabPanel>
      )}

      {currentPageCRUD.create && (
        <TabPanel value={value} index={1}>
          <AddUser
            staff={staff}
            clusters={clusters}
            roles={roles}
            refetch={_refetchUsers}
          />
        </TabPanel>
      )}

      {currentPageCRUD.create && (
        <TabPanel value={value} index={2}>
          <AddUserRoles />
        </TabPanel>
      )}

      {currentPageCRUD.read && (
        <TabPanel value={value} index={3}>
          <RolesList />
        </TabPanel>
      )}
    </Box>
  );
}
