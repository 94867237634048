import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Ptm() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [students, setStudents] = useState([]);
  const [ptmData, setPtmData] = useState([]);
  const [parentAttendance, setParentAttendance] = useState([]);
  const [ptmAttendance, setPtmAttendance] = useState([]);
  const [editPtmAttendance, setEditPtmAttendance] = useState([]);
  const [selectedPtmAttendance, setSelectedPtmAttendance] = useState({});
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [formData, setFormData] = useState({
    SchoolID: schoolId,
    Topic: "",
    classID: "",
    PtmDate: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      const searchParams = new URLSearchParams();
      searchParams.append("school_id", schoolId);
      setSchoolId(schoolId);
      _fetchClassesBySchool(schoolId);
      _fetchPTMAttendance(schoolId);
    }
    if (accesLevel === "ADMIN") {
      const schoolId = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      _fetchClassesBySchool(schoolId);
      _fetchPTMAttendance(schoolId);
    }
    _fetchPTMAttendance();
  }, []);

  const _fetchPTMAttendance = async (school_id) => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/ptm-attendance/${schoolId}`
        );
        console.log("ptm data", data.data);
        if (status === 200) {
          setPtmData(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolId = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/ptm-attendance/${schoolId}`
        );
        console.log("ptm data", data.data);
        if (status === 200) {
          setPtmData(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    console.log("PtmAttendance", ptmAttendance);
    const ptmData = {
      SchoolID: formData.SchoolID,
      Topic: formData.Topic,
      classID: formData.classID,
      PtmDate: formData.PtmDate,
      ptmAttendance: ptmAttendance,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/ptm-attendance",
        ptmData
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpen(false);
        _fetchPTMAttendance();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    console.log("selectedPtmAttendance", selectedPtmAttendance);
    const editPtmAttendanceData = {
      Topic: selectedPtmAttendance.Topic,
      PtmDate: selectedPtmAttendance.PtmDate,
      PTMAttendanceData: selectedPtmAttendance.PTMAttendanceModel,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/ptm-attendance/${selectedPtmAttendance.PTMID}`,
        editPtmAttendanceData
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpenEdit(false);
        _fetchPTMAttendance();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _onDelete = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await axiosInstance.delete(
        `/ptm-attendance/${selectedPtmAttendance.PTMID}`
      );
      console.log("response", data.data);
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchPTMAttendance();
        handleAnchorClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnCheckBoxChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].isPresent = !list[index].isPresent;
    list[index].SID = student.SID;

    setPtmAttendance(list);
  };

  const _handleOnEditCheckBoxChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...selectedPtmAttendance.PTMAttendanceModel];
    list[index].isPresent = !list[index].isPresent;
    list[index].SID = student.SID;

    setEditPtmAttendance(list);
  };

  const _handleDateOfPTM = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        SchoolID: schoolId,
        PtmDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleEditDateOfPTM = (e) => {
    setSelectedPtmAttendance((prevState) => {
      return {
        ...prevState,
        PtmDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        SchoolID: schoolId,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnEditChange = (e) => {
    setSelectedPtmAttendance((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        SchoolID: schoolId,
        classID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            FathersName: student.FathersName,
            isPresent: true,
          };
        });
        setStudents(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleEditClassChange = async (e) => {
    setSelectedPtmAttendance((prevState) => {
      return {
        ...prevState,
        SchoolID: schoolId,
        classID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            FathersName: student.FathersName,
            isPresent: true,
          };
        });
        setSelectedPtmAttendance((prevState) => {
          return {
            ...prevState,
            PTMAttendance: list,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleAttendanceOpen = (item) => () => {
    console.log("ITEM", item);
    setOpenAttendance(true);
    setParentAttendance(item);
  };
  const handleAttendanceClose = () => {
    setOpenAttendance(false);
  };
  const handleClick = (item) => (event) => {
    console.log("observation", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedPtmAttendance(item);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              PTM Module
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add PTM
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="center">Topic</TableCell>
                  <TableCell align="center">Class</TableCell>
                  <TableCell align="center">Parent Attendance</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  ptmData.map((item, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(item.PtmDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="center">{item.Topic}</TableCell>
                        <TableCell align="center">
                          {item.ClassModel.ClassName}
                        </TableCell>
                        <TableCell align="center">
                          <Button onClick={handleAttendanceOpen(item)}>
                            attendance
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(item)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {currentPageCRUD.update && (
                <MenuItem onClick={() => setOpenEdit(true)}>Edit</MenuItem>
              )}
              {currentPageCRUD.delete && (
                <MenuItem onClick={_onDelete}>Delete</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create PTM</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onSubmit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={formData.PtmDate}
                          name="StartTime"
                          onChange={_handleDateOfPTM}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Topic"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Topic"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Class"
                        sx={{ display: "flex", flex: 1 }}
                        // value={formData.classID}
                        variant="outlined"
                        name="ClassID"
                        required
                        onChange={_handleClassChange}
                        size="small"
                      >
                        {classes.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.ClassModel.ID}>
                              {item.ClassModel.ClassName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Parent Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Parent Attendance
                              </TableCell>
                              <TableCell align="center">Student Name</TableCell>
                              <TableCell align="right">Father Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {students.map((student, index) => {
                              return (
                                <TableRow
                                  key={String(index)}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <FormControlLabel
                                      value={student.isPresent}
                                      name="isPresent"
                                      control={
                                        <Checkbox checked={student.isPresent} />
                                      }
                                      onChange={(e) =>
                                        _handleOnCheckBoxChange(
                                          e,
                                          index,
                                          student
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    {student.StudentName}
                                  </TableCell>
                                  <TableCell align="right">
                                    {student.FathersName}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Create PTM
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openEdit}
            onClose={handleEditClose}
          >
            <DialogTitle>Edit PTM</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onEdit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={selectedPtmAttendance.PtmDate}
                          name="StartTime"
                          onChange={_handleEditDateOfPTM}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Topic"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedPtmAttendance.Topic}
                        name="Topic"
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Class"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedPtmAttendance.classID}
                        variant="outlined"
                        name="ClassID"
                        required
                        onChange={_handleEditClassChange}
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                      >
                        {classes.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.ClassModel.ID}>
                              {item.ClassModel.ClassName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Parent Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Parent Attendance
                              </TableCell>
                              <TableCell align="center">Student Name</TableCell>
                              <TableCell align="right">Father Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(selectedPtmAttendance).length > 0 &&
                              selectedPtmAttendance.PTMAttendanceModel.map(
                                (student, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        <FormControlLabel
                                          value={student.isPresent}
                                          name="isPresent"
                                          control={
                                            <Checkbox
                                              checked={student.isPresent}
                                            />
                                          }
                                          onChange={(e) =>
                                            _handleOnEditCheckBoxChange(
                                              e,
                                              index,
                                              student
                                            )
                                          }
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {student.Student.StudentName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {student.Student.FathersName}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Edit PTM
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openAttendance}
            onClose={handleAttendanceClose}
          >
            <DialogTitle>Parent Attendance</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Parent Attendance
                              </TableCell>
                              <TableCell align="center">Student Name</TableCell>
                              <TableCell align="right">Father Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(parentAttendance).length > 0 &&
                              parentAttendance.PTMAttendanceModel.map(
                                (attendance, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {attendance.isPresent
                                          ? "Present"
                                          : "Absent"}
                                      </TableCell>
                                      <TableCell align="center">
                                        {attendance.Student.StudentName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {attendance.Student.FathersName}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAttendanceClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
