import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import StaffList from "./StaffList";
import axiosInstance from "../../Api/axios.instance";
import AddStaff from "./AddStaff";
import AddTeacher from "./AddTeacher";
import { getClasses } from "../ClassSubjects/Classes";
import useSession from "../../CustomHooks/useSession";

export function getStaff() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/staff");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getTeachers() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/teachers");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Staff() {
  const [value, setValue] = React.useState(0);
  const [staff, setStaff] = React.useState([]);
  const [teachers, setSTeachers] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const { accesLevel, access } = useSession();

  React.useEffect(() => {
    _fetchStaff();
    _fetchTeachers();
    _fetchClasses();
  }, []);
  const _fetchStaff = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/teachers/by-school-id/${schoolID}`
        );
        if (status === 200) {
          setStaff(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/teachers/by-school-id/${schoolID}`
        );
        if (status === 200) {
          setStaff(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchTeachers = async () => {
    try {
      const response = await getTeachers();
      setSTeachers(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Staff List" {...a11yProps(0)} />
          {/* <Tab label="Add New Staff" {...a11yProps(1)} /> */}
          <Tab label="Add Teacher" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StaffList staff={staff} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <AddStaff getStaff={_fetchStaff} />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <AddTeacher teachers={teachers} classes={classes} />
      </TabPanel>
    </Box>
  );
}
