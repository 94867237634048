import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Logo from "../../Assets/logo.png";
import { Button, Grid, Divider, TextField, Box } from "@mui/material";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useSession from "../../CustomHooks/useSession";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
const drawerWidth = 240;

export default function Header({ appBarOpen, appBarClose }) {
  const [openSchool, setOpenSchool] = React.useState(false);
  const [schoolList, setSchoolList] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const [selectedCluster, setSelectedCluster] = React.useState({ RegionId: 0 });
  const [selectedSchool, setSelectedSchool] = React.useState({});
  const [loading, setLoading] = useState(false);
  const { accesLevel } = useSession();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [formData, setFormData] = useState({
    RegionId: "",
    SchoolId: "",
  });

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      const schoolName = localStorage.getItem("NameOfSchool");
      if (schoolName) {
        setSchoolName(schoolName);
      }
    }
  }, []);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    if (accesLevel === "CLUSTER") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);
  useEffect(() => {
    setUserName(JSON.parse(window.localStorage.getItem("User")).username);
    if (clusters.length > 0 && JSON.parse(localStorage.UserAccess).length > 0)
      handleOnAdminCluster(JSON.parse(localStorage.UserAccess)[0].RegionId);
  }, [clusters]);

  const open = Boolean(anchorEl);
  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    setSelectedCluster({
      CityId: cluster[0].CityId,
      ClosedDate: cluster[0].ClosedDate,
      CreatedDate: cluster[0].CreatedDate,
      DataEntryOperator: cluster[0].DataEntryOperator,
      DateDataEntry: cluster[0].DateDataEntry,
      Reason: cluster[0].Reason,
      RegionId: cluster[0].RegionId,
      RegionName: cluster[0].RegionName,
      RegionOrder: cluster[0].RegionOrder,
      Status: cluster[0].Status,
    });
    setSchoolList(cluster[0].School);
    setFormData((prevState) => {
      return {
        ...prevState,
        Cluster: selectedCluster,
        RegionId: e.target.value,
      };
    });
  };

  const handleOnAdminCluster = (id) => {
    console.clear();
    console.log(id);
    console.log(clusters);
    const cluster = clusters.filter((item) => item.RegionId === id);
    setSelectedCluster({
      CityId: cluster[0].CityId,
      ClosedDate: cluster[0].ClosedDate,
      CreatedDate: cluster[0].CreatedDate,
      DataEntryOperator: cluster[0].DataEntryOperator,
      DateDataEntry: cluster[0].DateDataEntry,
      Reason: cluster[0].Reason,
      RegionId: cluster[0].RegionId,
      RegionName: cluster[0].RegionName,
      RegionOrder: cluster[0].RegionOrder,
      Status: cluster[0].Status,
    });
    setSchoolList(cluster[0].School);
    setFormData((prevState) => {
      return {
        ...prevState,
        Cluster: selectedCluster,
        RegionId: id,
      };
    });
  };

  const _handleOnChange = (e) => {
    const school = schoolList.filter(
      (item) => item.SchoolId === e.target.value
    );
    localStorage.setItem("NameOfSchool", school[0].NameOfSchool);
    // alert("test");
    setSelectedSchool({
      SchoolId: school[0].SchoolId,
      NameOfSchool: school[0].NameOfSchool,
    });
    setFormData((prevState) => {
      return {
        ...prevState,
        School: selectedSchool,
        SchoolId: e.target.value,
      };
    });
  };
  const _onContinue = (e) => {
    e.preventDefault();
    const schoolData = [
      {
        Cluster: selectedCluster,
        RegionId: formData.RegionId,
        School: selectedSchool,
        SchoolId: formData.SchoolId,
        // UserAccessID: 138,
      },
    ];
    window.sessionStorage.setItem("workStation", "cluster");
    if (schoolData.SchoolId !== null) {
      window.localStorage.setItem("UserAccess", JSON.stringify(schoolData));
      setOpenSchool(false);
      window.location.reload();
    }
  };
  const _onDialog = () => {
    setDialogOpen(true);
  };
  const handleLogOut = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate("/login");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWorkspaceClose = () => {
    setOpenSchool(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <AppBar
      color="pale"
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Avatar alt="Baithak Logo" src={Logo} sx={{ width: 56, height: 56 }} />
        <Typography variant="h6" sx={{ ml: 2 }} flexGrow={1}>
          Baithak School Network (SEW)
          {(accesLevel === "ADMIN" || accesLevel === "CLUSTER") && (
            <Button
              onClick={() => setOpenSchool(true)}
              startIcon={<WorkspacesIcon />}
              sx={{ ml: 2 }}
            >
              Change Workspace
            </Button>
          )}
          {(accesLevel === "ADMIN" || accesLevel === "CLUSTER") && (
            <Button
              onClick={() => {
                window.sessionStorage.setItem(
                  "workStation",
                  window.sessionStorage.workStation == "admin"
                    ? "cluster"
                    : "admin"
                );
                window.location.reload();
              }}
              startIcon={<WorkspacesIcon />}
              sx={{ ml: 2 }}
            >
              {window.sessionStorage.workStation == "admin"
                ? "School Dashboard"
                : "Admin Dashboard"}
            </Button>
          )}
        </Typography>
        {accesLevel === "ADMIN" && (
          <Box sx={{ marginRight: "20px" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                textTransform: "uppercase",
              }}
            >
              <BusinessIcon />
              {schoolName}
            </Typography>
          </Box>
        )}
        <Button
          onClick={handleClick}
          variant="outlined"
          startIcon={<AccountCircleOutlinedIcon />}
        >
          {userName}
        </Button>
      </Toolbar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          Account Settings (Under Construction)
        </MenuItem>
        <MenuItem onClick={_onDialog}>Logout</MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to logout.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            No, Cancel
          </Button>
          <Button onClick={handleLogOut} autoFocus>
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSchool}
        onClose={handleWorkspaceClose}
      >
        <DialogTitle>Select School</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box onSubmit={_onContinue} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Cluster"
                    sx={{ display: "flex", flex: 1 }}
                    variant="outlined"
                    name="RegionId"
                    value={
                      accesLevel == "CLUSTER"
                        ? JSON.parse(localStorage.UserAccess)[0].RegionId
                        : 0
                    }
                    required
                    onChange={_handleOnAdminClusterChange}
                    disabled={accesLevel == "CLUSTER"}
                    size="small"
                  >
                    {clusters.map((cluster, index) => (
                      <MenuItem key={index} value={cluster.RegionId}>
                        {cluster.RegionName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select School"
                    sx={{ display: "flex", flex: 1 }}
                    // value={formik.values.TeacherID}
                    variant="outlined"
                    name="SchoolID"
                    required
                    onChange={_handleOnChange}
                    size="small"
                  >
                    {schoolList.map((school, index) => {
                      return (
                        <MenuItem key={index} value={school.SchoolId}>
                          {school.NameOfSchool}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    disabled={loading}
                    // onClick={_editSyllabus}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    continue
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}></Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWorkspaceClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
