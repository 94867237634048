import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function DailyEntries() {
  const [entries, setEntries] = useState([]);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  useEffect(() => {
    _fetchDailyEntries();
  }, []);

  const _fetchDailyEntries = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/reports/daily-entries"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setEntries(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Daily Entries Report
          </Typography>
          {/*<Typography variant="subtitle2" component="span">
            Select Date to generate a new Daily Entries Report
          </Typography>*/}
        </Stack>
        <Box width={300}>
          {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Select Date"
              //   value={formData.AssemblyDate}
              //   onChange={_handleAssemblyDate}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1 }}
                  size="small"
                  required
                  {...params}
                />
              )}
            />
          </LocalizationProvider>*/}
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button variant="outlined" color="primary" sx={{ mr: 1 }}>
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Cluster</TableCell>
                <TableCell align="center">School Name</TableCell>
                <TableCell align="center">Last Date Of Count</TableCell>
                <TableCell align="center">Today's Date Of Count</TableCell>
                <TableCell align="right">Total entries till Today</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.length > 0 &&
                entries.map((entry, index) => {
                  return (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {entry.cluster}
                      </TableCell>
                      <TableCell align="center">{entry.schoolName}</TableCell>
                      <TableCell align="center">
                        {entry.prevDate} ({entry.prev})
                      </TableCell>
                      <TableCell align="center">
                        {entry.currentDate} ({entry.current})
                      </TableCell>
                      <TableCell align="right">
                        {entry.month} ({entry.total})
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
