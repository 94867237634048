import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const StudentStrengthAdmin = ({ yearStrength }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Male",
        data: [],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Female",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  useEffect(() => {
    console.log(yearStrength);
    let l = [];
    let m = [];
    let f = [];
    yearStrength.forEach((x) => {
      l.push(x.RegionName);
      m.push(x.MaleCount);
      f.push(x.FemaleCount);
    });
    console.log(l, m, f);
    let d = {
      labels: [...l],
      datasets: [
        {
          label: "Male",
          data: m,
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Female",
          data: f,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    console.log(d);
    setData(d);
  }, [yearStrength]);
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "#000000",
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Region wise student chart",
      },
    },
  };
  return (
    <div>
      <Bar options={options} data={data} />{" "}
    </div>
  );
};

export default StudentStrengthAdmin;
