import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import axiosInstance from "../../Api/axios.instance";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getClusters } from "../Clusters/Clusters";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function saveClassSubjects(post) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.post(
        "/class-subjects",
        post
      );
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getClassSubjects(classId, schoolId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school-class/${classId}/${schoolId}`
      );
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function SubjectAttachment({
  classes: _classes,
  subjects: _subjects,
}) {
  const [clusters, setClusters] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("choose");
  const [selectedCluster, setSelectedCluster] = useState("choose");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("choose");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  useEffect(() => {
    _fetchClusters();
  }, []);
  useEffect(() => {
    setClasses(_classes);
    setSubjects(_subjects);
  }, [_classes, _subjects]);
  const _fetchClusters = async () => {
    try {
      const response = await getClusters();
      setClusters(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _onClusterChange = (event) => {
    const cluster = clusters.filter(
      (item) => item.RegionId === event.target.value
    );
    setSchools(cluster[0].School);
    setSelectedCluster(event.target.value);
  };
  const _onSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
  };
  const _onClassChange = async (e) => {
    setSelectedClass(e.target.value);
    try {
      const response = await getClassSubjects(e.target.value, selectedSchool);
      setSelectedSubjects(response.data.map((item) => String(item.SubjectID)));
    } catch (error) {
      console.log(error);
    }
  };
  const _renderClasses = () => {
    if (selectedSchool !== "choose" && classes.length > 0) {
      return (
        <Box sx={{ my: 3, width: "100%", px: 1 }} flexDirection="column">
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="demo-select-small-class">Select Class</InputLabel>
            <Select
              value={selectedClass}
              onChange={_onClassChange}
              labelId="demo-select-small-class"
              id="demo-select-small-class"
              label="Select Class"
            >
              <MenuItem value="choose" disabled>
                <em>Select Class</em>
              </MenuItem>
              {classes.map((_class, index) => (
                <MenuItem key={String(index)} value={_class.ID}>
                  {_class.ClassName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    }
    return null;
  };
  const _onSubjectSelect = (e) => {
    setSelectedSubjects((prevState) => {
      if (e.target.checked) {
        return [...prevState, e.target.value];
      } else {
        return prevState.filter((item) => item !== e.target.value);
      }
    });
  };
  const _renderSubjects = () => {
    if (selectedClass !== "choose") {
      return (
        <List>
          {subjects.map((item, index) => (
            <ListItem key={String(index)}>
              <Box
                display={"flex"}
                justifyContent="flex-start"
                width={"100%"}
                alignItems="center"
              >
                <Checkbox
                  color="success"
                  checked={selectedSubjects.includes(String(item.ID))}
                  value={item.ID}
                  onChange={_onSubjectSelect}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                />
                <Typography variant="body2">{item.SubjectName}</Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      );
    }
    return null;
  };
  const _submitSelectedSubjects = async () => {
    setLoading((prevState) => !prevState);
    const post = {
      classId: selectedClass,
      subjects: selectedSubjects,
      schoolId: selectedSchool,
    };
    try {
      const response = await saveClassSubjects(post);
      setLoading((prevState) => !prevState);
      setOpen(true);
    } catch (error) {
      console.log(error);
      setLoading((prevState) => !prevState);
    }
  };
  const handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };
  return (
    <Paper square>
      <Box sx={{ my: 1, px: 2 }}>
        <Typography variant="subtitle1">Attach Subjects to Classes</Typography>
        <Typography variant="subtitle2">
          Select appropriate subjects for the given class.
        </Typography>
      </Box>
      <Divider orientation="horizontal" sx={{ my: 1 }} />
      <Box sx={{ my: 3, width: "100%", px: 1 }} flexDirection="column">
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel id="demo-select-small-cluster">Select Cluster</InputLabel>
          <Select
            value={selectedCluster}
            onChange={_onClusterChange}
            labelId="demo-select-small-cluster"
            id="demo-select-small-cluster"
            label="Select Cluster"
          >
            <MenuItem value="choose" disabled>
              <em>Select Cluster</em>
            </MenuItem>
            {clusters.map((cluster, index) => (
              <MenuItem key={String(index)} value={cluster.RegionId}>
                {cluster.RegionName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "100%", mt: 2 }} size="small">
          <InputLabel id="demo-select-small-schools">Select School</InputLabel>
          <Select
            onChange={_onSchoolChange}
            value={selectedSchool}
            labelId="demo-select-small-schools"
            id="demo-select-small-schools"
            label="Age"
            disabled={schools.length === 0}
          >
            <MenuItem value="choose" disabled>
              <em>Select School</em>
            </MenuItem>
            {schools.map((school, index) => (
              <MenuItem key={String(index)} value={school.SchoolId}>
                {school.NameOfSchool}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider orientation="horizontal" sx={{ my: 1 }} />
      {_renderClasses()}
      {_renderSubjects()}
      <Divider orientation="horizontal" sx={{ my: 1 }} />
      <Box sx={{ p: 2 }}>
        {currentPageCRUD.create && (
          <LoadingButton
            type="button"
            onClick={_submitSelectedSubjects}
            loading={loading}
            disabled={loading || selectedSubjects.length === 0}
            variant="contained"
            sx={{ color: "white", display: "flex", flex: 1, width: "100%" }}
          >
            Save Changes
          </LoadingButton>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Subjects has been added.
        </Alert>
      </Snackbar>
    </Paper>
  );
}
