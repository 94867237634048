import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Button,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
  Snackbar,
  Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { getClasses } from "../ClassSubjects/Classes";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import Alert from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { getStudents } from "../Students/Students";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const SectionDivider = ({ text, changeDate }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography
        component="span"
        variant="subtitle2"
        sx={{ mx: 1, display: "flex", alignItems: "center" }}
      >
        {text}
        <IconButton onClick={changeDate} size="small">
          <CalendarTodayOutlinedIcon fontSize="10" />
        </IconButton>
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const AlertSnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StaffAttendance() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackEditOpen, setSnackEditOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [currentDate, setCurrentDate] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [dateOpen, setDateOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(false);
  const [selectedTeacherAttendance, setSelectedTeacherAttendance] =
    React.useState({});
  const [loadingClass, setLoadingClass] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [teachers, setTeachers] = React.useState([]);
  const [selectTeachers, setSelectTeachers] = React.useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [attendanceData, setAttendanceData] = React.useState([]);
  const [attendance, setAttendance] = React.useState([]);
  const [today, setToday] = React.useState(dayjs());
  const { currentPage } = useSession();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBox = Boolean(anchorEl);
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const { accesLevel, access } = useSession();
  React.useEffect(() => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    _fetchAttendance(currentDate);
    // fetchTeachers();
    _fetchTeachers();
    console.log("attendance", attendance);
    console.log("attendanceData", attendanceData);
    console.log("teachers", teachers);
  }, []);
  React.useEffect(() => {}, []);
  const _fetchAttendance = async (date) => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        searchParams.append("date", date);
        const { status, data } = await axiosInstance.get(
          `/staff-attendance/current-date`,
          {
            params: searchParams,
          }
        );
        console.log("DATE ++>", date);
        console.log("get attendance", data.data);
        // console.log(
        //   "att date",
        //   dayjs(data.data[0].Att_dateTime).format("YYYY-MM-DD").toString()
        // );
        // console.log("att date 1", data.data[0].Att_dateTime);
        if (data.data.length > 0) {
          console.log("attendance in IF");
          setAttendanceData(
            data.data.map((teacher) => {
              return {
                id: teacher.id,
                name: teacher.Teacher ? teacher.Teacher.TeacherName : "",
                designation: teacher.Teacher
                  ? teacher.Teacher.Designation.TeachersDesignation
                  : "",
                tID: teacher.tID,
                Present: teacher.Present,
                date: teacher.Att_dateTime,
              };
            })
          );
          setCurrentDate(
            dayjs(data.data[0].Att_dateTime).format("YYYY-MM-DD").toString()
          );
        } else {
          console.log("attendance in ELSE");
          setAttendanceData([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const fetchTeachers = async () => {
  //   try {
  //     const schoolId =
  //       localStorage.getItem("UserAccess") !== null
  //         ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
  //         : 0;
  //     const { data, status } = await axiosInstance.get(
  //       `/teachers/by-school/${schoolId}`
  //     );
  //     console.log("selectTeachers", data.data);
  //     console.log("schoolId", schoolId);
  //     if (status === 200) {
  //       setTeachers(data.data);
  //       return data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const _fetchTeachers = async () => {
    setLoadingClass(true);
    try {
      const schoolId =
        localStorage.getItem("UserAccess") !== null
          ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
          : 0;
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${schoolId}`
      );
      console.log("Response before if", data.data);
      if (data !== null) {
        console.log("Response in if", data.data);
        // setTeachers(response);
        setAttendance(
          data.data.map((teacher) => {
            return {
              name: teacher.TeacherName,
              designation: teacher.Designation
                ? teacher.Designation.TeachersDesignation
                : "",
              tID: teacher.SchoolTeachersQId,
              Present: true,
              date: dayjs().format("YYYY-MM-DD"),
            };
          })
        );
      } else {
        alert("no teacher found...");
      }
      setLoadingClass(false);
    } catch (error) {
      console.log(error);
      setLoadingClass(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowSuccess(false);
  };

  const handleDateClickOpen = () => {
    setDateOpen(true);
  };

  const handleDateClose = () => {
    setDateOpen(false);
  };
  const handleClick = (item) => (event) => {
    console.log("ITEM", item);
    setAnchorEl(event.currentTarget);
    setSelectedTeacherAttendance(item);
    // returenedItem(item);
  };
  const handleCloseBox = () => {
    setAnchorEl(null);
  };
  const handleEditToggle = (item) => (event) => {
    setSelectedTeacherAttendance((prevState) => {
      return {
        ...prevState,
        Present: !item.Present,
      };
    });
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleSelectedDateOpen = () => {
    setSelectedDate(true);
  };

  const handleSelectedDateClose = () => {
    setSelectedDate(false);
  };
  const handleChange = (e) => {
    setSelectedClass(e.target.value);
  };
  const handleToggle = (id) => (event) => {
    setAttendance((prevState) => {
      return prevState.map((item) => {
        if (item.tID === id) {
          return {
            ...item,
            Present: !item.Present,
          };
        }
        return item;
      });
    });
  };
  const _submitAttendance = async () => {
    // console.log("attendance", attendance[0].date);
    // console.log("today", dayjs(today).format("YYYY-MM-DD"));
    // console.log("currentDate", currentDate);
    setLoading((prevState) => !prevState);
    const date = attendance[0].date;
    // const currentDate = dayjs(today).format("YYYY-MM-DD");
    try {
      if (currentDate && currentDate === date) {
        setLoading((prevState) => !prevState);
        console.log("OFF");
        setSnackOpen(true);
        return;
      } else {
        console.log(attendance);
        console.log("ON");
        const { data, status } = await axiosInstance.post(
          "/staff-attendance",
          attendance
        );
        console.log(data.data);
        if (status === 200) {
          setLoading((prevState) => !prevState);
          _fetchAttendance(date);
          setShowSuccess(true);
          handleClose();
        }
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();
    // console.log("selectedTeacherAttendance ==>", selectedTeacherAttendance);
    try {
      const { status, data } = await axiosInstance.put(
        `/staff-attendance/${selectedTeacherAttendance.id}`,
        selectedTeacherAttendance
      );
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackEditOpen(true);
        _fetchAttendance();
        handleEditClose();
        handleCloseBox();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const snack_handleEditClose = () => {
    console.log("Snack bar Closed");
    setSnackEditOpen(false);
  };

  useEffect(() => {
    console.log("Attendance", attendance);
  }, [attendance]);

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Staff Attendance Management Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Generate Attendance button to generate a new attendance sheet
          </Typography>
        </Stack>
        {currentPageCRUD.create && (
          <Button variant="contained" onClick={handleClickOpen}>
            Generate Attendance
          </Button>
        )}
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <SectionDivider
        text={`Staff Attendance for ${today.format("dddd DD MMM, YYYY")}`}
        changeDate={handleSelectedDateOpen}
      />
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Box sx={{ mt: 2, mb: 2 }}>
        {currentPageCRUD.read}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Teacher Name</TableCell>
                <TableCell align="center">Designation</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Attendance</TableCell>
                <TableCell align="right">Action</TableCell>
                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read && attendanceData.length > 0 ? (
                attendanceData.map((teacher, index) => (
                  <TableRow
                    key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {teacher.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {teacher.designation}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(teacher.date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      {teacher.Present ? (
                        <Chip sx={{ ml: 1 }} label="P" color="success" />
                      ) : (
                        <Chip sx={{ ml: 1 }} label="A" color="error" />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={handleClick(teacher)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="center" colSpan={5}>
                    <Box
                      component={Paper}
                      sx={{ padding: 1, textAlign: "center" }}
                    >
                      <Typography variant="subtitle2" component="span">
                        <strong>No Attendance has been added yet!</strong>
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openBox}
            onClose={handleCloseBox}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/*{currentPageCRUD.delete && (
                          <MenuItem
                            // onClick={handleDialogClickOpen}
                            color="error"
                          >
                            Delete
                          </MenuItem>
                        )}*/}

            {currentPageCRUD.update && (
              <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
            )}
          </Menu>
        </TableContainer>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" variant="h6">
          Attendance Sheet
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            textAlign="center"
            variant="subtitle2"
            alignItems="center"
          >
            Attendance for {today.format("dddd DD MMM, YYYY")}{" "}
            <IconButton onClick={handleDateClickOpen} size="small">
              <CalendarTodayOutlinedIcon fontSize="8" />
            </IconButton>
          </DialogContentText>
          {showSuccess && (
            <Alert severity="success" sx={{ mt: 1 }}>
              Attendance has been successfully added.
            </Alert>
          )}
          <Box sx={{ mt: 2, mb: 1 }}>
            <List sx={{ width: "100%" }}>
              {attendance.length > 0 &&
                attendance.map((teacher, index) => (
                  <ListItem key={String(index)}>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary={`${teacher.name} - ${teacher.designation}`}
                    />
                    <Switch
                      edge="end"
                      onChange={handleToggle(teacher.tID)}
                      checked={teacher.Present}
                      inputProps={{
                        "aria-labelledby": "switch-list-label-wifi",
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={_submitAttendance}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleSelectedDateClose}
        open={selectedDate}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle variant="body2">Select new date</DialogTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 4 }}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Set a new date"
              value={today}
              onChange={(e) => {
                setToday(e);
                handleSelectedDateClose();
                setAttendance((prevState) => {
                  return prevState.map((item) => {
                    return {
                      ...item,
                      date: dayjs(e).format("YYYY-MM-DD"),
                    };
                  });
                });
                _fetchAttendance(dayjs(e).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Dialog>

      <Dialog onClose={handleDateClose} open={dateOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="body2">Select new date</DialogTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 4 }}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Set a new date"
              value={today}
              onChange={(e) => {
                setToday(e);
                handleDateClose();
                setAttendance((prevState) => {
                  return prevState.map((item) => {
                    return {
                      ...item,
                      date: dayjs(e).format("YYYY-MM-DD"),
                    };
                  });
                });
                _fetchAttendance(dayjs(e).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Update Staff Attendance
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Attendance of Date:{" "}
                  {`${dayjs(selectedTeacherAttendance.date).format(
                    "YYYY-MM-DD"
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ mt: 1, mb: 1 }} component={Paper}>
                  <List sx={{ width: "100%" }}>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary={`${selectedTeacherAttendance.name} - ${selectedTeacherAttendance.designation}`}
                      />
                      <Switch
                        edge="end"
                        onChange={handleEditToggle(selectedTeacherAttendance)}
                        checked={selectedTeacherAttendance.Present}
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
          <Button onClick={_onEdit}>Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <AlertSnack
          onClose={snack_handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Teacher Attendance has already been added! more then one attendance
          are not allowed.
        </AlertSnack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackEditOpen}
        autoHideDuration={6000}
        onClose={snack_handleEditClose}
      >
        <AlertSnack
          onClose={snack_handleEditClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </AlertSnack>
      </Snackbar>
    </Box>
  );
}
