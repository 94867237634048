import React from "react";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { TextField, Box, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../Api/axios.instance";
import { getClasses } from "../ClassSubjects/Classes";
import { getSubjects } from "../ClassSubjects/Subjects";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const _addTest = async (data) => {
  try {
    const { data: reponse, status } = await axiosInstance.post("/tests", data, {
      headers: { "Content-Type": "application/json" },
    });
    if (status === 200) {
      return reponse;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default function AddTest({ fetchTests }) {
  const [schoolId, setSchoolId] = useState(null);
  const [variations, setVariations] = useState([]);
  const { accesLevel, access } = useSession();
  const [formData, setFormData] = useState({
    Testype: null,
    Status: null,
    MaxMarks: null,
    PassingPercentage: null,
    SubjectID: null,
    SubjectVariation: "",
    SchoolID: schoolId,
    ClassID: "",
    TestDate: dayjs(),
    Session: `${dayjs().year() - 1}-${dayjs().year()}`,
    UserID: "",
    CreatedDateTime: "",
    UserID: "",
    ModificationDateTime: "",
    ModificationByUser: "",
  });
  const [open, setOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  React.useEffect(() => {
    // _fetchSubjects();
    _fetchClasses();
    _fetchSubjectVariationVariables();
    // console.log("schoolId", schoolID);
    if (accesLevel === "ADMIN") {
      // const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
      //   .SchoolId;
      // console.log("schoolId", schoolID);
      setFormData((prevState) => {
        return {
          ...prevState,
          SchoolID: null,
        };
      });
    }
    // if (accesLevel === "SCHOOL") {
    //   const accessObject = Object.assign({}, access);
    //   const schoolID = accessObject[0].SchoolId;
    //   console.log("schoolId", schoolID);
    //   setFormData((prevState) => {
    //     return {
    //       ...prevState,
    //       SchoolID: schoolID,
    //     };
    //   });
    // }
  }, []);

  // const _fetchSubjects = async () => {
  //   try {
  //     const response = await getSubjects();
  //     // console.log("Subjects", response);
  //     setSubjects(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      // console.log("Classes", response);
      setClasses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchSubjectVariationVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-subject-variations"
      );
      if (data.data.length > 0) {
        setVariations(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        SchoolID: schoolId,
      };
    });
  };
  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        SchoolID: schoolId,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _SubmitForm = async (e) => {
    e.preventDefault();
    // Get UserID from localStorage 
    const currentUserID = JSON.parse(localStorage.getItem("User")).id;
    const currentDateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

    console.log("Uama", currentUserID);
    // Set UserID in formData
    setFormData((prevState) => ({
      ...prevState,
      CreatedDateTime: currentDateTime,
      UserID: currentUserID,
    }));

    let obj={...formData,UserID: currentUserID,CreatedDateTime: currentDateTime,}
    console.log("Usama",obj);
    const newStaff = await _addTest(obj);
    // console.log(newStaff);

    if (newStaff.hasOwnProperty("success")) {
      setOpen(true);
      fetchTests();
    } else {
      alert("An Error Has Occured");
    }
  };

  const _handleTD = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        TestDate: e.$d.toString(),
      };
    });
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };
  return (
    <Box component="form" onSubmit={_SubmitForm}>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="Test Date"
            inputFormat="DD/MM/YYYY"
            value={formData.TestDate}
            onChange={_handleTD}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{ display: "flex", flex: 1 }}
                required
              />
            )}
          />
        </LocalizationProvider>
        {/*<TextField
          label="Session"
          variant="standard"
          sx={{ display: "flex", flex: 1 }}
          name="Session"
          value={formData.Session}
          InputProps={{
            readOnly: true,
          }}
        />*/}
        <TextField
          id="standard-select-currency"
          select
          label="Session"
          sx={{ display: "flex", flex: 1 }}
          variant="standard"
          // size="small"
          name="Session"
          value={formData.Session}
          onChange={_handleOnChange}
        >
          <MenuItem value="2015-2016">2015-2016</MenuItem>
          <MenuItem value="2016-2017">2016-2017</MenuItem>
          <MenuItem value="2017-2018">2017-2018</MenuItem>
          <MenuItem value="2018-2019">2018-2019</MenuItem>
          <MenuItem value="2019-2020">2019-2020</MenuItem>
          <MenuItem value="2020-2021">2020-2021</MenuItem>
          <MenuItem value="2021-2022">2021-2022</MenuItem>
          <MenuItem value="2022-2023">2022-2023</MenuItem>
          <MenuItem value="2023-2024">2023-2024</MenuItem>
          <MenuItem value="2024-2025">2024-2025</MenuItem>
          <MenuItem value="2025-2026">2025-2026</MenuItem>
          <MenuItem value="2026-2027">2026-2027</MenuItem>
          <MenuItem value="2027-2028">2027-2028</MenuItem>
          <MenuItem value="2028-2029">2028-2029</MenuItem>
          <MenuItem value="2029-2030">2029-2030</MenuItem>
          <MenuItem value="2030-2031">2030-2031</MenuItem>
          <MenuItem value="2031-2032">2031-2032</MenuItem>
          <MenuItem value="2032-2033">2032-2033</MenuItem>
        </TextField>
        <TextField
          id="standard-select-currency"
          select
          label="Test Type"
          sx={{ display: "flex", flex: 1 }}
          variant="standard"
          name="Testype"
          onChange={_handleOnChange}
        >
          <MenuItem value="WEEKLY">WEEKLY</MenuItem>
          <MenuItem value="MONTHLY">MONTHLY</MenuItem>
          <MenuItem value="MID-TERM">MID-TERM</MenuItem>
          <MenuItem value="ANNUAL">ANNUAL</MenuItem>
        </TextField>
        <TextField
          id="standard-select-currency"
          select
          label="Status"
          sx={{ display: "flex", flex: 1 }}
          variant="standard"
          name="Status"
          onChange={_handleOnChange}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </TextField>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          id="standard-select-currency"
          select
          label="Select Class"
          sx={{ display: "flex", flex: 1 }}
          value={formData.ClassID}
          //   onChange={handleChange}
          variant="standard"
          name="ClassID"
          onChange={_handleClassChange}
          defaultValue="Select Class"
        >
          {classes?.map((item, i) => {
            return (
              <MenuItem key={i} value={item.ID}>
                {item.ClassName}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="standard-select-currency"
          select
          label="Select Subject"
          sx={{ display: "flex", flex: 1 }}
          value={formData.SubjectID}
          //   onChange={handleChange}
          variant="standard"
          name="SubjectID"
          onChange={_handleOnChange}
          defaultValue="Select Subject"
        >
          {subjects?.map((sub, i) => {
            return (
              <MenuItem key={i} value={sub.ID}>
                {sub.SubjectName}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="standard-select-currency"
          select
          label="Subject Variation"
          sx={{ display: "flex", flex: 1 }}
          value={formData.SubjectVariation}
          //   onChange={handleChange}
          variant="standard"
          name="SubjectVariation"
          onChange={_handleOnChange}
          defaultValue="Select Subject"
        >
          {variations.map((variation, i) => {
            return (
              <MenuItem key={i} value={variation.Caption}>
                {variation.Caption}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          type="number"
          label="Max Marks"
          variant="standard"
          sx={{ display: "flex", flex: 1 }}
          name="MaxMarks"
          onChange={_handleOnChange}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          type="number"
          label="Passing Percentage"
          variant="standard"
          sx={{ display: "flex", flex: 1 }}
          name="PassingPercentage"
          onChange={_handleOnChange}
        />
      </Stack>
      <Box sx={{ mt: 3 }}>
        <LoadingButton variant="contained" fullWidth type="Submit">
          Create Test
        </LoadingButton>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Test has been added.
        </Alert>
      </Snackbar>
    </Box>
  );
}
