import React from "react";
import {
  Alert,
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance, { baseURL } from "../../Api/axios.instance";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MinioUrl } from "./../../Api/axios.instance";
import useSession from "../../CustomHooks/useSession";

export default function Card({ student, deleteStudent }) {
  const navigate = useNavigate();
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const _viewDetails = () => {
    navigate(`details/${student.SID}`, {
      state: {
        studentData: student,
      },
    });
  };
  const _edit = () => {
    navigate(`edit/${student.SID}`, {
      state: {
        ...student,
      },
    });
  };
  const _deleteStudent = async () => {
    const { SID } = student;
    try {
      const { data } = await axiosInstance.delete(`/students/${SID}`);
      deleteStudent(SID);
      handleClose();
    } catch (e) {
      alert(
        "There was a problem deleting student. Please contact administration"
      );
      handleClose();
    }
  };
  return (
    <Paper square>
      <Stack sx={{ p: 2 }} flexDirection="row">
        <Stack alignItems="center">
          <Avatar
            variant="square"
            alt="Student Name"
            src={
              student.image !== null
                ? `${MinioUrl}/students/${student.image}`
                : ""
            }
            sx={{ width: 56, height: 56 }}
          />
          <Typography
            sx={{ mt: 1, color: "#27ae60" }}
            component={"span"}
            variant="caption"
          >
            {student.StatusCode}
          </Typography>
        </Stack>
        <Stack sx={{ ml: 2 }} flexGrow={1}>
          <Stack flexDirection={"row"} justifyContent="space-between">
            <Typography component={"span"} variant="subtitle2">
              {student.StudentName} | {student.Gender}
            </Typography>
            <Chip label={`GR# ${student.GRNO}`} />
          </Stack>
          <Typography component={"span"} variant="body2">
            {student.FathersName ?? student.GuardianName}
          </Typography>
          <Typography component={"span"} variant="caption">
            {student.Address}
          </Typography>
          <Typography component={"span"} variant="caption">
            FEE: {student.Fee ?? 0}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        sx={{ p: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center">
          {currentPageCRUD.read && (
            <Button onClick={_viewDetails} size="small" variant="outlined">
              View Student
            </Button>
          )}

          {currentPageCRUD.read && (
            <Button
              onClick={_edit}
              sx={{ ml: 1 }}
              size="small"
              color="secondary"
              variant="text"
            >
              Edit
            </Button>
          )}
        </Stack>
        {currentPageCRUD.delete && (
          <IconButton onClick={handleClickOpen} color="error">
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete a student
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this student? It will remove all its
            related data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No, Abort</Button>
          <Button onClick={_deleteStudent} autoFocus>
            Yes, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
