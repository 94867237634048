import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Stack from "@mui/material/Stack";
import {
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../Api/axios.instance";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import useSession from "../../CustomHooks/useSession";
import { getStudents } from "../Students/Students";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddFee = forwardRef(({ fetchList, action, selectedItem }, ref) => {
  const INITIAL_VAL = {
    Fee_Head: "",
    Fee: "",
    Narration: "",
    ClassID: "",
    sid: "",
    Student: "",
    BookNumber: "",
    ReceiptNumber: "",
    Paid: false,
    PaidDate: dayjs(),
  };

  const [selectInputStudent, setSelectedInputStudent] = React.useState("");
  const [formData, setFormData] = useState(INITIAL_VAL);
  const [open, setOpen] = useState(false);
  const { accesLevel, access } = useSession();
  const [classes, setClasses] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [students, setStudents] = useState([]);
  const formRef = useRef(null);
  React.useEffect(() => {
    if (selectedItem !== null) {
      setFormData({
        ClassID: selectedItem.ClassID,
        sid: selectedItem.sid,
        Fee_Head: selectedItem.Fee_Head,
        Fee: selectedItem.Amount,
        Narration: selectedItem.Narration,
        Student: selectedItem.Student,
        BookNumber: selectedItem.BookNumber,
        ReceiptNumber: selectedItem.ReceiptNumber,
        Paid: selectedItem.Paid,
        PaidDate: dayjs(selectedItem.PaidDate),
      });
    }
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      (async () => {
        if (selectedItem.ClassID !== "") {
          try {
            const { data, status } = await axiosInstance.get(
              `/students/by-school-class/${schoolId}/${selectedItem.ClassID}`
            );
            console.log("setStudents =====>", data.data);
            if (status === 200) {
              setStudents(data.data);
            }
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }
  }, [selectedItem]);
  React.useEffect(() => {
    console.log("selectedItem", selectedItem);
    // _fetchStudents();
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
      }
    }
  }, []);
  useImperativeHandle(ref, () => ({
    submitForm() {
      formRef.current.requestSubmit();
    },
  }));
  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ClassID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onStudentChange = (event, newValue) => {
    console.log("newvalue", newValue);
    // const student = students.filter((item) => item.SID === newValue.SID)[0];
    setFormData((prevState) => {
      return {
        ...prevState,
        Student: newValue,
        sid: newValue.SID,
        Fee: newValue.Fee !== null ? newValue.Fee : 0,
      };
    });
  };
  const _onStudentInputValue = (event, newInputValue) => {
    setSelectedInputStudent(newInputValue);
  };

  // const _fetchStudents = async () => {
  //   try {
  //     const schoolId =
  //       localStorage.getItem("UserAccess") !== null
  //         ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
  //         : 0;
  //     const response = await getStudents(schoolId);
  //     setStudents(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      if (e.target.name === "Fee") {
        return {
          ...prevState,
          [e.target.name]: +e.target.value > -1 ? +e.target.value : 0,
        };
      }
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _setDateValue = (type) => (event) => {
    setFormData((prevStat) => {
      return {
        ...prevStat,
        [type]: event,
      };
    });
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };
  const _onPaidChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _SubmitForm = async (e) => {
    e.preventDefault();
    try {
      const post = {
        ...formData,
      };
      if (action === "Add") {
        const { status, data } = await axiosInstance.post("/fees", post);
      } else {
        const { status, data } = await axiosInstance.put(
          `/fees/${selectedItem.id}`,
          post
        );
      }
      fetchList();
      setFormData({ ...INITIAL_VAL });
      setOpen(true);
      console.log("POST", post);
    } catch (error) {
      alert("There was an error, please contact admin");
    }
  };
  return (
    <Box ref={formRef} component="form" onSubmit={_SubmitForm}>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {/*<Autocomplete
          disablePortal
          id="combo-box-teacher"
          options={students}
          getOptionLabel={(option) => option.StudentName ?? "Select Student"}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.SID}>
                {option.StudentName}
              </li>
            );
          }}
          value={formData.Student}
          onChange={_onStudentChange}
          inputValue={selectInputStudent}
          isOptionEqualToValue={(option, value) => option.SID === value.SID}
          onInputChange={_onStudentInputValue}
          sx={{ display: "flex", flex: 1 }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Select Student" required />
          )}
        />*/}
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          id="standard-select-currency"
          select
          label="Select Class"
          sx={{ display: "flex", flex: 1 }}
          value={formData.ClassID}
          variant="outlined"
          name="ClassID"
          required
          onChange={_handleClassChange}
          defaultValue="Select Class"
          size="small"
        >
          {classes.map((item, index) => {
            return (
              <MenuItem key={index} value={item.ClassModel.ID}>
                {item.ClassModel.ClassName}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          id="standard-select-currency"
          select
          label="Select Student"
          sx={{ display: "flex", flex: 1 }}
          value={formData.sid}
          variant="outlined"
          name="sid"
          required
          onChange={_handleOnChange}
          defaultValue="Select Student"
          size="small"
        >
          {students.map((student, index) => {
            return (
              <MenuItem key={index} value={student.SID}>
                {student.StudentName} ({student.FathersName})
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "column" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <TextField
          id="standard-select-currency"
          select
          label="Fee Head"
          sx={{ display: "flex", flex: 1 }}
          value={formData.Fee_Head}
          variant="outlined"
          name="Fee_Head"
          required
          onChange={_handleOnChange}
          defaultValue="Fee Head"
          size="small"
        >
          <MenuItem value="MONTHLY">Monthly Fee</MenuItem>
          <MenuItem value="ADMISSION">Admission Fee</MenuItem>
          <MenuItem value="EXAM">Exam Fee</MenuItem>
          <MenuItem value="ANNUAL">Annual Fee</MenuItem>
          <MenuItem value="BOOKS">Books</MenuItem>
          <MenuItem value="TC">TC</MenuItem>
        </TextField>
        <TextField
          size="small"
          label="Book Number"
          variant="outlined"
          value={formData.BookNumber}
          sx={{ display: "flex", flex: 1 }}
          name="BookNumber"
          type="number"
          required
          onChange={_handleOnChange}
        />
        <TextField
          size="small"
          label="Receipt Number"
          variant="outlined"
          value={formData.ReceiptNumber}
          sx={{ display: "flex", flex: 1 }}
          name="ReceiptNumber"
          type="number"
          required
          onChange={_handleOnChange}
        />
        <TextField
          size="small"
          label="Narration"
          variant="outlined"
          value={formData.Narration}
          sx={{ display: "flex", flex: 1 }}
          name="Narration"
          required
          onChange={_handleOnChange}
        />
        <TextField
          size="small"
          label="Fee"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Fee"
          type="number"
          required
          onChange={_handleOnChange}
          value={formData.Fee}
          focused={true}
        />
        <TextField
          id="standard-select-currency"
          select
          label="Paid"
          sx={{ display: "flex", flex: 1 }}
          value={formData.Paid}
          variant="outlined"
          name="Paid"
          required
          onChange={_handleOnChange}
          defaultValue="Paid"
          size="small"
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            inputFormat="DD-MM-YYYY"
            label="Voucher Date"
            value={formData.PaidDate}
            onChange={_setDateValue("PaidDate")}
            renderInput={(params) => (
              <TextField
                sx={{ display: "flex", flex: 1 }}
                size="small"
                required
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {/* <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Paid</FormLabel>
          <RadioGroup
            row
            defaultValue={formData.Paid}
            name="Paid"
            onChange={_onPaidChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl> */}
      </Stack>
      {/* <Box sx={{ mt: 3 }}>
        <LoadingButton variant="contained" fullWidth type="Submit">
          Generate Fee Voucher
        </LoadingButton>
      </Box> */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Fee Voucher has Been created.
        </Alert>
      </Snackbar>
    </Box>
  );
});
export default AddFee;
