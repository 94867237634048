import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import useSession from "../../CustomHooks/useSession";

export default function FeeCollectionV1() {
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Fee Collection V1 Report
          </Typography>
          <Typography variant="subtitle2" component="span">
            Select Date to generate a new Fee Collection V1 Report
          </Typography>
        </Stack>
        <Box width={300}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Select Date"
              //   value={formData.AssemblyDate}
              //   onChange={_handleAssemblyDate}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1 }}
                  size="small"
                  required
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button variant="outlined" color="primary" sx={{ mr: 1 }}>
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Cluster</TableCell>
                <TableCell align="center">School Name</TableCell>
                <TableCell align="center">Current Month Collection</TableCell>
                <TableCell align="center">Last Month Collection</TableCell>
                <TableCell align="center">Progress / Changes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                // key={String(index)}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  Cluster 1
                </TableCell>
                <TableCell align="center">School 1</TableCell>
                <TableCell align="center">"Month" (15000)</TableCell>
                <TableCell align="center">"Month" (90000)</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ArrowUpwardIcon color="success" sx={{ mr: 1 }} /> Up
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow
                // key={String(index)}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  Cluster 1
                </TableCell>
                <TableCell align="center">School 1</TableCell>
                <TableCell align="center">"Month" (15000)</TableCell>
                <TableCell align="center">"Month" (80000)</TableCell>
                <TableCell align="center" alignItems="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ArrowDownwardIcon color="error" sx={{ mr: 1 }} /> Down
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
