import {
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSession from "../../CustomHooks/useSession";
import { getClusters } from "../Clusters/Clusters";
import { Students } from "..";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PromoteStudents() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [capData, setCapData] = useState([]);
  const [selectedCapData, setSelectedCapData] = useState({});
  const [selectedTeacherAttendance, setSelectedTeacherAttendance] = useState(
    []
  );
  const [selectedParentAttendance, setSelectedParentAttendance] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [promotedStudents, setPromotedStudents] = useState([]);
  const [promoteClasses, setPromoteClasses] = useState([]);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    DateofPosting: dayjs().format("YYYY-MM-DD"),
    ClassID: "",
    CurrentSession: "",
    SessionName: "",
    PromoteClassID: "",
    PromoteClass: "",
  });
  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      const searchParams = new URLSearchParams();
      searchParams.append("school_id", schoolId);
      console.log("school_id", schoolId);
      setSchoolId(schoolId);
      _fetchClassesBySchool(schoolId);
      _fetchStudentHistory(schoolId);
    }
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      console.log("school id", schoolID);
      // setSchoolIdentity(schoolID);
      if (schoolID) {
        setSchoolId(schoolId);
        console.log("schoolId", schoolID);
        _fetchClassesBySchool(schoolID);
        _fetchStudentHistory(schoolID);
      }
    }
    _fetchStudentsData();
  }, []);

  const _fetchClassesBySchool = async (id) => {
    console.log("school_id", id);
    try {
      const { data, status } = await axiosInstance.get("/classes");
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchStudentHistory = async (id) => {
    console.log("School ID", id);
    try {
      const { data, status } = await axiosInstance.get(
        `/promote-student/${id}`
      );
      console.log("setPromotedStudents", data.data);
      let listWithoutDuplicates = data.data.filter(
        (ele, i) =>
          i ===
          data.data.findIndex(
            (elem) => elem.PreviousClassID === ele.PreviousClassID
          )
      );
      console.log("listWithoutDuplicates", listWithoutDuplicates);
      if (status === 200) {
        setPromoteClasses(listWithoutDuplicates);
        setPromotedStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNextClassName = () => {
    let promoteClassIndex = classes.findIndex(
      (item) => item.ID === formData.ClassID
    );
    console.log("promoteClassIndex", promoteClassIndex);
    const classesIndex = classes.length - 1;
    console.log("classesIndex", classesIndex);

    if (promoteClassIndex + 1 > classesIndex) {
      return (promoteClassIndex = "QUIT");
    } else {
      return classes[promoteClassIndex + 1]
        ? classes[promoteClassIndex + 1].ClassName
        : "";
    }
  };

  const getNextClassID = () => {
    let promoteClassIndex = classes.findIndex(
      (item) => item.ID === formData.ClassID
    );
    console.log("promoteClassIndex", promoteClassIndex);
    const classesIndex = classes.length - 1;
    console.log("classesIndex", classesIndex);

    if (promoteClassIndex + 1 > classesIndex) {
      return (promoteClassIndex = classes[classesIndex].ID);
    } else {
      return classes[promoteClassIndex + 1]
        ? classes[promoteClassIndex + 1].ID
        : "";
    }
  };
  const _fetchStudentsData = async () => {
    try {
      if (accesLevel === "SCHOOL" || accesLevel === "ADMIN") {
        // const accessObject = Object.assign({}, access);
        // const schoolId = accessObject[0].SchoolId;
        // const searchParams = new URLSearchParams();
        // searchParams.append("school_id", schoolId);
        const { status, data } = await axiosInstance.get(
          `/students/by-school/${schoolId}`
        );
        console.log("get students", data.data);
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            FathersName: student.FathersName,
            isPresent: true,
          };
        });
        setStudentData(list);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const PromoteClass = getNextClassName();
    console.log("Form Data", formData);
    console.log("PromoteClass", PromoteClass);
    console.log("PromoteClassID", formData.PromoteClassID);
    console.log("ClassID", formData.ClassID);

    const promotedStudentData = students.map((student) => {
      return {
        StatusCode: student.StatusCode,
        PreviousClassID: formData.ClassID,
        // PromotedClassID: student.StatusCode
        //   ? getNextClassID()
        //   : formData.ClassID,
        PromotedClassID:
          student.StatusCode === false || formData.PromoteClassID === "QUIT"
            ? formData.ClassID
            : formData.PromoteClassID,
        sid: student.sid,
        DateofPosting: student.DateofPosting,
        // DateofExit:
        //   PromoteClass === "QUIT" && student.StatusCode !== false
        //     ? student.DateofPosting
        //     : null,
        DateofExit:
          formData.PromoteClassID === "QUIT" && student.StatusCode !== false
            ? student.DateofPosting
            : null,
        GRNO: student.GRNO,
        SessionName: formData.SessionName,
        // StatusCodeText:
        //   PromoteClass === "QUIT" && student.StatusCode !== false
        //     ? "DROPOUT"
        //     : "ACTIVE",
        StatusCodeText:
          formData.PromoteClassID === "QUIT" && student.StatusCode !== false
            ? "PASSOUT"
            : "ACTIVE",
        // Dropout_Date:
        //   PromoteClass === "QUIT" && student.StatusCode !== false
        //     ? student.DateofPosting
        //     : null,
        Dropout_Date:
          formData.PromoteClassID === "QUIT" && student.StatusCode !== false
            ? student.DateofPosting
            : null,
      };
    });

    console.log("promotedStudentData", promotedStudentData);
    console.log("School Id", schoolId);

    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post("/promote-student", {
        promotedStudentData,
      });
      console.log("response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchStudentHistory(schoolId);
        handleAnchorClose();
        handleClose();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();

    // const editCapData = {
    //   CapDate: selectedCapData.CapDate,
    //   Topic: selectedCapData.Topic,
    //   AddressBy: selectedCapData.AddressBy,
    //   ParentAttendanceJSON: selectedParentAttendance,
    //   TeacherAttendanceJSON: selectedTeacherAttendance,
    //   ResponsibleID: selectedCapData.ResponsibleID,
    // };

    // console.log("EDIT DATA", editCapData);

    // try {
    //   setLoading((prevState) => !prevState);
    //   const { data, status } = await axiosInstance.put(
    //     `/cap-attendance/${selectedCapData.CAPID}`,
    //     editCapData
    //   );
    //   console.log("response", data.data);
    //   if (status === 200) {
    //     setLoading((prevState) => !prevState);
    //     setSnackMessage(data.message);
    //     setSnackBar(true);
    //     _fetchCapData();
    //     handleAnchorClose();
    //     handleEditClose();
    //   }
    // } catch (error) {
    //   setLoading((prevState) => !prevState);
    //   console.log(error);
    // }
  };

  // ========= HANDLE CHANGE ==========

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleOnClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });

    console.log("getNextClassID", getNextClassID());
    console.log("getNextClassName", getNextClassName());
  };

  const _handlePromoteStudents = async () => {
    console.log("schoolId ==>", schoolId);
    console.log("form data", formData);
    try {
      const { status, data } = await axiosInstance.get(
        `/students/by-school-class-active-session/${formData.CurrentSession}/${schoolId}/${formData.ClassID}`
      );
      if (status === 200 && data.data.length > 0) {
        setStudents(
          data.data.map((student) => {
            return {
              sid: student.SID,
              StudentName: student.StudentName,
              FathersName: student.FathersName,
              ClassName: student.ClassModel ? student.ClassModel.ClassName : "",
              GRNO: student.GRNO,
              DateofPosting: dayjs().format("YYYY-MM-DD"),
              SessionName: new Date().getFullYear(),
              StatusCode: true,
              DateofExit: null,
            };
          })
        );
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnCheckBoxChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].StatusCode = !list[index].StatusCode;

    setStudents(list);
  };

  const _handleDateofPosting = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        DateofPosting: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  // ========= HANDLE CHANGE ==========

  const handleClick = (item) => (event) => {
    console.log("Selected CapData", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedCapData(item);
    const teacherAttendance = JSON.parse(item.TeacherAttendanceJSON);
    setSelectedTeacherAttendance(teacherAttendance);
    const parentAttendance = JSON.parse(item.ParentAttendanceJSON);
    setSelectedParentAttendance(parentAttendance);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Promote Student Module
            </Typography>
          </Stack>
          <Box width={300}>
            {accesLevel === "ADMIN" ? (
              ""
            ) : (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Create Student Promotion
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexGrow={1}>
            {promoteClasses.length > 0 &&
              promoteClasses.map((classes, index) => {
                return (
                  <Accordion
                    key={String(index)}
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{ mb: 2 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="body1">
                        <strong>
                          {`Promoted Class ${classes.ClassModel?.ClassName}`}
                          <Chip
                            label={`${classes.SessionName}`}
                            sx={{ ml: 2 }}
                            variant="outlined"
                            color="success"
                          />
                        </strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Student Name</TableCell>
                                <TableCell align="center">
                                  Father Name
                                </TableCell>
                                <TableCell align="center">
                                  Is Promoted
                                </TableCell>
                                <TableCell align="center">
                                  Promoted Session
                                </TableCell>
                                <TableCell align="right">
                                  Student Status
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {promotedStudents.map((student, index) => {
                                if (
                                  classes.PreviousClassID ===
                                  student.PreviousClassID
                                ) {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {Object.keys(student.Student).length >
                                          0 && student.Student.StudentName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {Object.keys(student.Student).length >
                                          0 && student.Student.FathersName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {student.StatusCode ? (
                                          <Chip
                                            label="Promoted"
                                            color="success"
                                          />
                                        ) : (
                                          <Chip
                                            label="Not Promoted"
                                            color="error"
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Chip
                                          label={`${student.SessionName}`}
                                          color="secondary"
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Chip
                                          label={`${
                                            Object.keys(student.Student)
                                              .length > 0 &&
                                            student.Student.StatusCode
                                          }`}
                                          color="success"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create Student Promotion</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onSubmit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date Of Posting"
                          value={formData.DateofPosting}
                          name="EndTime"
                          onChange={_handleDateofPosting}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Previous Class"
                        sx={{ display: "flex", flex: 1 }}
                        value={formData.ClassID}
                        variant="outlined"
                        name="ClassID"
                        required
                        onChange={_handleOnClassChange}
                        size="small"
                      >
                        {classes.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.ID}>
                              {item.ClassName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    {/*<Grid item xs={12} md={6}>
                      <TextField
                        label="Promote Class ID"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="PromoteClassID"
                        value={formData.ClassID === "" ? "" : getNextClassID()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Promote Class"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="PromoteClass"
                        value={
                          formData.ClassID === "" ? "" : getNextClassName()
                        }
                      />
                    </Grid>*/}
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Promoted Class"
                        sx={{ display: "flex", flex: 1 }}
                        value={formData.PromoteClassID}
                        variant="outlined"
                        name="PromoteClassID"
                        required
                        onChange={_handleOnClassChange}
                        size="small"
                      >
                        {classes.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.ID}>
                              {item.ClassName}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="QUIT">QUIT</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="From Session"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="CurrentSession"
                        required
                        onChange={_handleOnChange}
                        size="small"
                      >
                        <MenuItem value="2015-2016">2015-2016</MenuItem>
                        <MenuItem value="2016-2017">2016-2017</MenuItem>
                        <MenuItem value="2017-2018">2017-2018</MenuItem>
                        <MenuItem value="2018-2019">2018-2019</MenuItem>
                        <MenuItem value="2019-2020">2019-2020</MenuItem>
                        <MenuItem value="2020-2021">2020-2021</MenuItem>
                        <MenuItem value="2021-2022">2021-2022</MenuItem>
                        <MenuItem value="2022-2023">2022-2023</MenuItem>
                        <MenuItem value="2023-2024">2023-2024</MenuItem>
                        <MenuItem value="2024-2025">2024-2025</MenuItem>
                        <MenuItem value="2025-2026">2025-2026</MenuItem>
                        <MenuItem value="2026-2027">2026-2027</MenuItem>
                        <MenuItem value="2027-2028">2027-2028</MenuItem>
                        <MenuItem value="2028-2029">2028-2029</MenuItem>
                        <MenuItem value="2029-2030">2029-2030</MenuItem>
                        <MenuItem value="2030-2031">2030-2031</MenuItem>
                        <MenuItem value="2031-2032">2031-2032</MenuItem>
                        <MenuItem value="2032-2033">2032-2033</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="To Session"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="SessionName"
                        required
                        onChange={_handleOnChange}
                        size="small"
                      >
                        <MenuItem value="2015-2016">2015-2016</MenuItem>
                        <MenuItem value="2016-2017">2016-2017</MenuItem>
                        <MenuItem value="2017-2018">2017-2018</MenuItem>
                        <MenuItem value="2018-2019">2018-2019</MenuItem>
                        <MenuItem value="2019-2020">2019-2020</MenuItem>
                        <MenuItem value="2020-2021">2020-2021</MenuItem>
                        <MenuItem value="2021-2022">2021-2022</MenuItem>
                        <MenuItem value="2022-2023">2022-2023</MenuItem>
                        <MenuItem value="2023-2024">2023-2024</MenuItem>
                        <MenuItem value="2024-2025">2024-2025</MenuItem>
                        <MenuItem value="2025-2026">2025-2026</MenuItem>
                        <MenuItem value="2026-2027">2026-2027</MenuItem>
                        <MenuItem value="2027-2028">2027-2028</MenuItem>
                        <MenuItem value="2028-2029">2028-2029</MenuItem>
                        <MenuItem value="2029-2030">2029-2030</MenuItem>
                        <MenuItem value="2030-2031">2030-2031</MenuItem>
                        <MenuItem value="2031-2032">2031-2032</MenuItem>
                        <MenuItem value="2032-2033">2032-2033</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={_handlePromoteStudents}
                        >
                          Get Students
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Student List
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Is Promoted</TableCell>
                              <TableCell align="center">Student Name</TableCell>
                              <TableCell align="center">Father Name</TableCell>
                              <TableCell align="right">Class</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {students.length > 0 &&
                              students.map((student, index) => {
                                return (
                                  <TableRow
                                    key={String(index)}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <TableCell component="th" scope="row">
                                        <FormControlLabel
                                          value={student.StatusCode}
                                          name="Present"
                                          control={
                                            <Checkbox
                                              checked={student.StatusCode}
                                            />
                                          }
                                          onChange={(e) =>
                                            _handleOnCheckBoxChange(
                                              e,
                                              index,
                                              student
                                            )
                                          }
                                        />
                                      </TableCell>
                                    </TableCell>
                                    <TableCell align="center">
                                      {student.StudentName}
                                    </TableCell>
                                    <TableCell align="center">
                                      {student.FathersName}
                                    </TableCell>
                                    <TableCell align="right">
                                      {student.ClassName}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading || buttonDisable}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Create Promotion
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
