import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const GenderRatio = ({ genderRatio }) => {
  const [ratio, setRatio] = useState([0, 0]);
  useEffect(() => {
    console.log(genderRatio);
    if (genderRatio !== null) {
      if (typeof genderRatio[0] == "number") {
        setRatio([...genderRatio]);
      } else {
        setRatio([genderRatio.males?.length, genderRatio.females?.length]);
      }
    }
  }, [genderRatio]);

  const data = {
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "Gender",
        data: ratio,
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: "#000000",
      },
    },
  };
  return <Pie data={data} options={options} />;
};

export default GenderRatio;
