import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  MenuItem,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
// import Card from "./Quicks/Card";
import { LoadingButton } from "@mui/lab";
import GroupIcon from "@mui/icons-material/Group";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import axiosInstance from "../Api/axios.instance";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "../Components/Dashboards/Quicks/Card";
import GenderRatio from "../Components/Charts/GenderRatio";
import StudentStrength from "../Components/Charts/StudentStrength";
import useSession from "../CustomHooks/useSession";
import SchoolWiseClassStrength from "../Components/Charts/SchoolWiseClassStrength";
import SevenDaysAttendance from "../Components/Charts/SevenDaysAttendance";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BadgeIcon from "@mui/icons-material/Badge";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Modal from "@mui/material/Modal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

export default function Dashboard(props) {
  const { schools } = useSession();
  const [schoolList, setSchoolList] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [fee, setFee] = useState([]);
  const [sevenDaysAttendance, setSevenDaysAttendance] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [capParentattendance, setCapParentAttendance] = useState([]);
  const [capParentattendanceMonthly, setCapParentAttendanceMonthly] = useState(
    []
  );
  const [ptmParentattendance, setPtmParentAttendance] = useState([]);
  const [ptmParentattendanceMonthly, setPtmParentAttendanceMonthly] = useState(
    []
  );
  const [missingCnicCount, setmissingCnicCount] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [childrenCount, setChildrenCount] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState({});
  const [selectedSchool, setSelectedSchool] = useState({});
  const [data, setData] = useState(null);
  const [classStrengthData, setClassStrengthData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [sid, setSid] = useState(0);
  const [habitualAbsenteDetail, setHabitualAbsenteDetail] = useState([]);
  const [modalOpenHabitualAbsenteDetail, setModalOpenHabitualAbsenteDetail] =
    useState(false);
  const [modalOpenCnicCount, setModalOpenCnicCount] = useState(false);
  const [cnicCountData, setCnicCountData] = useState([{}]);
  const { accesLevel, access, currentPage } = useSession();
  const [formData, setFormData] = useState({
    RegionId: "",
    SchoolId: "",
  });

  const handleClick = () => {
    setModalOpenCnicCount(true);
  };

  const handleClickHabitualAbsentee = (sid) => {
    setSid(sid);
    setModalOpenHabitualAbsenteDetail(true);
  };

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      const schoolName = localStorage.getItem("NameOfSchool");
      if (schoolName) {
        setSchoolName(schoolName);
      }
    }
  }, []);
  useEffect(() => {
    const accessObject = Object.assign({}, access);
    const regionId =
      Object.keys(accessObject).length > 0 ? accessObject[0].RegionId : false;
    const schoolId =
      Object.keys(accessObject).length > 0 ? accessObject[0].SchoolId : false;
    //console.log("accessObject in use session", Object.keys(accessObject).length);
    //console.log("school id in use session", schoolId);
    //console.log("regionId id in use session", regionId);
    //console.log("accesLevel in use session", accesLevel);
    if (
      (accesLevel === "ADMIN" || accesLevel === "CLUSTER") &&
      regionId === false &&
      schoolId === false
    ) {
      setOpen(true);
    } else if (
      (accesLevel === "ADMIN" || accesLevel === "CLUSTER") &&
      regionId !== false &&
      schoolId !== false
    ) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, []);
  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          //console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (sid != 0) {
      getHabitualAbsenteeDetailReport();
    }
  }, [sid]);

  useEffect(() => {
    getSchoolWiseClassStrength();
    getLastSevenDaysAttendance();
    getFeeRecoverMonthBased();
    getHabitualAbsentee();
    getHabitualAbsenteeDetailReport();
    getCapParentCount();
    getCapParentCountMonthly();
    getPtmParentCount();
    getPtmParentCountMonthly();
    getCnicCount();
    getNoOfTeachers();
    getStudentsBelowAgeFour();
    getUnderPrivlagdeChildrenCount();
    //console.log("FEE", fee);
    if (schools.length > 0) {
      (async () => {
        try {
          const { data } = await axiosInstance.post("/dashboard", {
            schools: schools,
          });
          //console.log(data);
          setData(data);
        } catch (error) {
          //console.log(error);
        }
      })();
    }
  }, [schools]);
  const getSchoolWiseClassStrength = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/school-wise-class-occuptaion-chart",
          { params: searchParams }
        );
        console.log("school class strength", data.data);
        if (status === 200) {
          setClassStrengthData(data.data[0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/school-wise-class-occuptaion-chart",
          { params: searchParams }
        );
        console.log("school class strength", data.data);
        if (status === 200) {
          setClassStrengthData(data.data[0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getLastSevenDaysAttendance = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/last-seven-days-attendance-school-wise",
          { params: searchParams }
        );
        console.log("last 7 days attendance", data.data);
        if (status === 200) {
          setSevenDaysAttendance(data.data[0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/last-seven-days-attendance-school-wise",
          { params: searchParams }
        );
        console.log("last 7 days attendance", data.data);
        if (status === 200) {
          setSevenDaysAttendance(data.data[0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getFeeRecoverMonthBased = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/fee-receover-statistics-month-based",
          { params: searchParams }
        );
        console.log("FEE MONTH", data.data);
        if (status === 200) {
          setFee(data.data[0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/fee-receover-statistics-month-based",
          { params: searchParams }
        );
        console.log("FEE MONTH", data.data);
        if (status === 200) {
          setFee(data.data[0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getHabitualAbsentee = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/habitual-absentee-school-based",
          { params: searchParams }
        );
        console.log("habitual absenteeeee", data.data);
        console.log("sid", data.data[0][0].sid);
        if (status === 200) {
          setAttendance(data.data[0]);
          // setSid(data.data[0][9].sid);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/habitual-absentee-school-based",
          { params: searchParams }
        );
        console.log("habitual absenteeeee", data.data);
        console.log("sid", data.data[0][0].sid);
        if (status === 200) {
          setAttendance(data.data[0]);
          // setSid(data.data[0][0].sid);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getHabitualAbsenteeDetailReport = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolid = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("schoolid", schoolid);
        searchParams.append("sid", parseInt(sid));
        const { data, status } = await axiosInstance.get(
          "/dashboard/habitual-absentee-school-based-sid-based",
          { params: searchParams }
        );
        console.log("habitual absenteeeee detail", data.data);
        if (status === 200) {
          setHabitualAbsenteDetail(data.data[0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolid = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        console.log("school id", schoolid);
        const searchParams = new URLSearchParams();
        searchParams.append("schoolid", schoolid);
        searchParams.append("sid", sid);
        const { data, status } = await axiosInstance.get(
          "/dashboard/habitual-absentee-school-based-sid-based",
          { params: searchParams }
        );
        console.log("habitual absenteeeee detail", data.data);
        if (status === 200) {
          setHabitualAbsenteDetail(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCapParentCount = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/cap-parent-count",
          { params: searchParams }
        );

        if (status === 200) {
          setCapParentAttendance(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/cap-parent-count",
          { params: searchParams }
        );
        //console.log("setCapParentAttendance", data.data[0][0]);
        if (status === 200) {
          setCapParentAttendance(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getCapParentCountMonthly = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/cap-parent-count-current-month",
          { params: searchParams }
        );

        if (status === 200) {
          setCapParentAttendanceMonthly(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/cap-parent-count-current-month",
          { params: searchParams }
        );
        if (status === 200) {
          setCapParentAttendanceMonthly(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getPtmParentCount = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/ptm-parents-attendance",
          { params: searchParams }
        );

        if (status === 200) {
          setPtmParentAttendance(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/ptm-parents-attendance",
          { params: searchParams }
        );
        //console.log("setPtmParentAttendance", data.data[0][0]);
        if (status === 200) {
          setPtmParentAttendance(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getPtmParentCountMonthly = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/ptm-parents-attendance-current-month",
          { params: searchParams }
        );

        if (status === 200) {
          setPtmParentAttendanceMonthly(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/ptm-parents-attendance-current-month",
          { params: searchParams }
        );
        if (status === 200) {
          setPtmParentAttendanceMonthly(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  function extractKeys(array) {
    return array.map((item) => {
      const newItem = { ...item };

      Object.keys(newItem).forEach((key) => {
        if (Array.isArray(newItem[key])) {
          // Extract keys from nested arrays
          newItem[key].forEach((nestedItem) => {
            Object.keys(nestedItem).forEach((nestedKey) => {
              newItem[nestedKey] = nestedItem[nestedKey];
            });
          });
          delete newItem[key];
        }
      });

      return newItem;
    });
  }
  const getCnicCount = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/sp-nic-missing",
          { params: searchParams }
        );

        if (status === 200) {
          let returnedObj = JSON.parse(Object.values(data.data[0][0]));
          let array = [...JSON.parse(Object.values(data.data[0][0]))];
          let d = extractKeys(array);
          setCnicCountData(d);
          let mCount = returnedObj[0]["MissingCount"];
          setmissingCnicCount(mCount);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        const { data, status } = await axiosInstance.get(
          "/dashboard/sp-nic-missing",
          { params: searchParams }
        );

        if (status === 200) {
          let returnedObj = JSON.parse(Object.values(data.data[0][0]));
          let array = [...JSON.parse(Object.values(data.data[0][0]))];
          let d = extractKeys(array);
          setCnicCountData(d);
          let mCount = returnedObj[0]["MissingCount"];
          setmissingCnicCount(mCount);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getNoOfTeachers = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/no-of-teachers",
          { params: searchParams }
        );

        if (status === 200) {
          setTeachers(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/no-of-teachers",
          { params: searchParams }
        );
        //console.log("setTeachers", data.data[0][0]);
        if (status === 200) {
          setTeachers(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getStudentsBelowAgeFour = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/students-below-age-four",
          { params: searchParams }
        );

        if (status === 200) {
          setStudents(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/students-below-age-four",
          { params: searchParams }
        );
        //console.log("setStudents", data.data[0][0]);
        if (status === 200) {
          setStudents(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getUnderPrivlagdeChildrenCount = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/under-privlagde-children-count",
          { params: searchParams }
        );

        if (status === 200) {
          setChildrenCount(data.data[0][0]);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        //console.log("school id", schoolID);
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolID);
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/dashboard/under-privlagde-children-count",
          { params: searchParams }
        );
        //console.log("setChildrenCount", data.data[0][0]);
        if (status === 200) {
          setChildrenCount(data.data[0][0]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    //console.log("cluster", cluster);
    //console.log("schools", cluster[0].School);
    setSelectedCluster({
      CityId: cluster[0].CityId,
      ClosedDate: cluster[0].ClosedDate,
      CreatedDate: cluster[0].CreatedDate,
      DataEntryOperator: cluster[0].DataEntryOperator,
      DateDataEntry: cluster[0].DateDataEntry,
      Reason: cluster[0].Reason,
      RegionId: cluster[0].RegionId,
      RegionName: cluster[0].RegionName,
      RegionOrder: cluster[0].RegionOrder,
      Status: cluster[0].Status,
    });
    setSchoolList(cluster[0].School);
    setFormData((prevState) => {
      return {
        ...prevState,
        Cluster: selectedCluster,
        RegionId: e.target.value,
      };
    });
  };
  const _handleOnChange = (e) => {
    const school = schoolList.filter(
      (item) => item.SchoolId === e.target.value
    );
    localStorage.setItem("NameOfSchool", school[0].NameOfSchool);
    setSelectedSchool({
      SchoolId: school[0].SchoolId,
      NameOfSchool: school[0].NameOfSchool,
    });
    setFormData((prevState) => {
      return {
        ...prevState,
        School: selectedSchool,
        SchoolId: e.target.value,
      };
    });
  };
  const _onContinue = (e) => {
    e.preventDefault();
    //console.log("Form Data", formData);
    //console.log("selectedCluster", selectedCluster);
    //console.log("selectedSchool", selectedSchool);
    const schoolData = [
      {
        Cluster: selectedCluster,
        RegionId: formData.RegionId,
        School: selectedSchool,
        SchoolId: formData.SchoolId,
        // UserAccessID: 138,
      },
    ];
    //console.log("schoolData", schoolData);
    if (schoolData.SchoolId !== null) {
      window.localStorage.setItem("UserAccess", JSON.stringify(schoolData));
      setOpen(false);
      window.location.reload();
    }
  };
  const handleClose = () => {
    setOpen(false);
    setModalOpenCnicCount(false);
    setModalOpenHabitualAbsenteDetail(false);
  };

  useEffect(() => {
    console.log("asdasdasd", cnicCountData);
  }, [cnicCountData]);

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "portrait" }); // Change orientation if needed
    const startY = 10; // Adjust the starting Y position
    const spaceBetweenRows = 10; // Adjust the space between rows

    // Add title to the PDF
    doc.setFontSize(18);
    doc.text("Habitual Absent Report - Detail", 10, startY);
    doc.setFontSize(12);

    // Add table headings
    doc.setFont("helvetica", "bold");
    doc.text("Date of Absent", 10, startY + 20);
    doc.text("Name of Student", 60, startY + 20);
    doc.text("GRNO", 120, startY + 20);
    doc.text("Class", 160, startY + 20);
    doc.setFont("helvetica", "normal");

    // Iterate through the rows in the table
    let currentY = startY + 30; // Adjust the initial Y position
    habitualAbsenteDetail.forEach((habitualAbsentee, index) => {
      const date = habitualAbsentee.Att_dateTime
        ? dayjs(habitualAbsentee.Att_dateTime).format("YYYY-MM-DD")
        : "Not Defined";
      doc.text(date, 10, currentY);
      doc.text(habitualAbsentee.StudentName, 60, currentY);
      doc.text(habitualAbsentee.GRNO, 120, currentY);
      doc.text(habitualAbsentee.ClassName, 160, currentY);
      currentY += spaceBetweenRows;
      // Check if there's enough space for the next row
      if (currentY > 280) {
        doc.addPage(); // Add a new page if there's not enough space
        currentY = startY;
      }
    });

    // Save the PDF
    doc.save("Habitual_Absent_Report_Detail.pdf");
  };

  const exportXLSX = (data) => {
    const worksheetData = data.map((item) => ({
      "Date of Absent":
        dayjs(item.Att_dateTime).format("YYYY-MM-DD") || "Not Defined",
      "Name of Student": item.StudentName,
      GRNO: item.GRNO,
      Class: item.ClassName,
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Habitual Absent Report");
    XLSX.writeFile(workbook, "Habitual-Absent-Report.xlsx");
  };

  const style = {
    height: "80%",
    marginTop: "10%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: "#ffffff",
    border: "none !important",
    p: 3,
  };

  const cellStyle = {
    fontSize: "15px",
    border: "0px",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack sx={{ mb: 2 }}>
        <Typography variant="h5" component="h1">
          Dashboard
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={fee?.[0]?.["All Fee Heads Collection"] ?? 0}
            text="All Fee Heads Collection"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={fee?.[0]?.["Expected Fee Collection"] ?? 0}
            text="Expected Fee Collection"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={fee?.[0]?.["Monthly Fee Collection"] ?? 0}
            text="Monthly Fee Collection"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={fee?.[0]?.["shortfall"] ?? 0}
            text="Short Fall"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={teachers?.TeacherCount ?? 0}
            text="No of Teachers"
            icon={<BadgeIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={data?.studentsCount ?? 0}
            text="Student Count"
            icon={<GroupIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3} style={{ whiteSpace: "nowrap" }}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={childrenCount?.studentcount ?? 0}
            text="Under Privileged Students"
            icon={
              <AdminPanelSettingsIcon
                fontSize="large"
                sx={{ color: "white" }}
              />
            }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={students?.Studentcount ?? 0}
            text="Students Below Age Four"
            icon={<GroupIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={data?.orphansCount ?? 0}
            text="Orphan"
            icon={<PeopleAltIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={
              <Typography variant="body2" component="div">
                Yearly: {capParentattendance?.ParentsCount ?? 0} <br />
                Monthly: {capParentattendanceMonthly?.ParentsCount ?? 0}
              </Typography>
            }
            text="CAP Parent Attendance"
            icon={<VerifiedUserIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={
              <Typography variant="body2" component="div">
                Yearly: {ptmParentattendance?.ParentCount ?? 0} <br />
                Monthly: {ptmParentattendanceMonthly?.ParentCount ?? 0}
              </Typography>
            }
            text="PTM Parent Attendance"
            icon={<VerifiedUserIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid onClick={handleClick} item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={missingCnicCount}
            text="MISSING CNIC COUNT"
            icon={<VerifiedUserIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Modal
          open={modalOpenCnicCount}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Missing CNIC Data
            </Typography>
            {cnicCountData && Array.isArray(cnicCountData) && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {cnicCountData &&
                        cnicCountData.length > 0 &&
                        Object.keys(cnicCountData[0])
                          .filter((key) => key !== "MissingCount")
                          .map((key) => <TableCell key={key}>{key}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cnicCountData &&
                      Array.isArray(cnicCountData) &&
                      cnicCountData.length > 0 &&
                      cnicCountData.map((data, index) => (
                        <TableRow key={index}>
                          {Object.entries(data)
                            .filter(([key]) => key !== "MissingCount")
                            .map(([key, value], subIndex) => (
                              <TableCell key={subIndex}>{value}</TableCell>
                            ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Modal>
        <Grid item xs={12} md={8}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Year Wise Student Strength</h3>
            <StudentStrength yearStrength={data?.genderRatio ?? null} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper square sx={{ padding: 1, height: "100%" }}>
            <h3>Student Gender Ratio</h3>
            <GenderRatio genderRatio={data?.genderRatio ?? null} />
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Paper square sx={{ padding: "15px", height: "100%" }}>
            <h3>Fee Heads</h3>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked name="ADMISSION" />}
                label="ADMISSION"
              />
              <FormControlLabel
                control={<Checkbox name="ANNUAL" />}
                label="ANNUAL"
              />
              <FormControlLabel
                control={<Checkbox name="Fees_Head" />}
                label="Fees Head"
              />
              <FormControlLabel
                control={<Checkbox name="MONTHLY" />}
                label="MONTHLY"
              />
            </FormGroup>
          </Paper>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>School Wise Class Capacity Chart</h3>
            <SchoolWiseClassStrength classStrength={classStrengthData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Last 7 days Attendance Chart</h3>
            <SevenDaysAttendance attendance={sevenDaysAttendance} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                <strong>Habitual Absentee</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Student Name</TableCell>
                        <TableCell align="center">GRNO</TableCell>
                        <TableCell align="center">Class</TableCell>
                        <TableCell align="right">Absent Days</TableCell>
                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendance.length > 0 &&
                        attendance.map((student, index) => (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {student.StudentName}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              onClick={() => {
                                handleClickHabitualAbsentee(student.sid);
                              }}
                            >
                              {student.GRNO}
                            </TableCell>
                            <TableCell align="center">
                              {student.ClassName}
                            </TableCell>
                            <TableCell align="right">
                              {student.ABSENTDAYS}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {/* Habitual Absente Detail Report*/}
      <Modal
        open={modalOpenHabitualAbsenteDetail}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Habitual Absent Report - Detail
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box textAlign="right">
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mr: 1 }}
                  onClick={exportPDF}
                >
                  <PictureAsPdfIcon color="primary" />
                </Button>
                <Button
                  onClick={() => exportXLSX(habitualAbsenteDetail)}
                  variant="outlined"
                  color="primary"
                >
                  <FileDownloadIcon color="primary" />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {habitualAbsenteDetail.length > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" style={cellStyle}>
                <strong>Name of Student:</strong>{" "}
                {habitualAbsenteDetail[0].StudentName}
              </Typography>
              <Typography variant="body1" style={cellStyle}>
                <strong>GRNO:</strong> {habitualAbsenteDetail[0].GRNO}
              </Typography>
              <Typography variant="body1" style={cellStyle}>
                <strong>Class:</strong> {habitualAbsenteDetail[0].ClassName}
              </Typography>
            </Grid>
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TableContainer
            component={Paper}
            style={{ overflow: "scroll", height: "65%" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date of Absent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {habitualAbsenteDetail.length > 0 &&
                  habitualAbsenteDetail.map((habitualAbsentee, index) => (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {habitualAbsentee.Att_dateTime
                          ? dayjs(habitualAbsentee.Att_dateTime).format(
                              "YYYY-MM-DD"
                            )
                          : "Not Defined"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>Select School</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box onSubmit={_onContinue} component="form">
              {accesLevel === "ADMIN" && (
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Cluster"
                      sx={{ display: "flex", flex: 1 }}
                      // value={formik.values.SchoolID}
                      variant="outlined"
                      name="RegionId"
                      required
                      onChange={_handleOnAdminClusterChange}
                      size="small"
                    >
                      {clusters.map((cluster, index) => {
                        return (
                          <MenuItem key={index} value={cluster.RegionId}>
                            {cluster.RegionName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select School"
                      sx={{ display: "flex", flex: 1 }}
                      // value={formik.values.TeacherID}
                      variant="outlined"
                      name="SchoolID"
                      required
                      onChange={_handleOnChange}
                      size="small"
                    >
                      {schoolList.map((school, index) => {
                        return (
                          <MenuItem key={index} value={school.SchoolId}>
                            {school.NameOfSchool}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      disabled={loading}
                      // onClick={_editSyllabus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      continue
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}

              {accesLevel === "CLUSTER" && (
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select School"
                      sx={{ display: "flex", flex: 1 }}
                      // value={formik.values.TeacherID}
                      variant="outlined"
                      name="SchoolID"
                      required
                      onChange={_handleOnChange}
                      size="small"
                    >
                      {schoolList.map((school, index) => {
                        return (
                          <MenuItem key={index} value={school.SchoolId}>
                            {school.NameOfSchool}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      disabled={loading}
                      // onClick={_editSyllabus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      continue
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={handleClose}>Close</Button>*/}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
