import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MuiAlert from "@mui/material/Alert";
import useSession from "../../CustomHooks/useSession";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Library() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [classes, setClasses] = useState([]);
  const [pages, setPages] = useState([]);
  const [schoolID, setSchoolID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const [formData, setFormData] = useState({
    AddedOn: dayjs().format("YYYY-MM-DD"),
    ClassID: null,
    NameOfBook: "",
    Author: "",
    Subject: "",
    // BookNumber: null,
    // ReceiptNumber: null,
  });
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const openAnchor = Boolean(anchorEl);

  useEffect(() => {
    console.log("currentPageCRUD", currentPageCRUD);
    if (accesLevel === "SCHOOL" || accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      console.log("schoolId", schoolId);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
      }
      _fetchBooks();
    }
  }, []);

  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (book) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedBook(book);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _onHandleChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        ["SchoolID"]: schoolID,
      };
    });
  };
  const _handleEditChange = (e) => {
    setSelectedBook((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        ["SchoolID"]: schoolID,
      };
    });
  };
  const _fetchBooks = async () => {
    if (
      accesLevel === "SCHOOL" ||
      accesLevel === "ADMIN" ||
      accesLevel === "CLUSTER"
    ) {
      try {
        const { data, status } = await axiosInstance.get("/books");
        if (status === 200) {
          setBooks(data.data);
          console.log("bookList", data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _deleteCurrentBook = async () => {
    console.log("selectedBook on delete", selectedBook);
    if (accesLevel === "ADMIN") {
      try {
        const { data, status } = await axiosInstance.delete(
          `/books/${selectedBook.BookID}`
        );
        console.log("response post data", data);
        if (status === 200) {
          _fetchBooks();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _onUpdate = async () => {
    if (accesLevel === "ADMIN") {
      try {
        const { data, status } = await axiosInstance.put(
          `/books/${selectedBook.BookID}`,
          selectedBook
        );
        console.log("response update data", data);
        if (status === 200) {
          _fetchBooks();
          setEditOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await axiosInstance.post(`books`, formData);
      if (status === 200) {
        _fetchBooks();
        setSnackOpen(true);
        setOpen(false);
      } else {
        setOpen(false);
        console.log("something went wrong!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Library Management Module
            </Typography>
            <Typography variant="subtitle2" component="span">
              Manage Library for respective classes and their subjects. Select a
              Class to continue.
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add Book
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Class Name</TableCell>
                  <TableCell align="left">Book Name</TableCell>
                  {/*<TableCell align="left">Book Number</TableCell>
                  <TableCell align="left">Receipt Number</TableCell>*/}
                  <TableCell align="center">Author Name</TableCell>
                  <TableCell align="center">Related Subject</TableCell>
                  <TableCell align="center">Added on</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {books?.map((book, index) => {
                  return (
                    <>
                      <TableRow
                        //   key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {book.ClassModel ? book.ClassModel.ClassName : ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {book.NameOfBook}
                        </TableCell>
                        {/*<TableCell component="th" scope="row">
                          {book.BookNumber}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {book.ReceiptNumber}
                        </TableCell>*/}
                        <TableCell align="center">{book.Author}</TableCell>
                        <TableCell align="center">{book.Subject}</TableCell>
                        <TableCell align="center">
                          {dayjs(book.AddedOn).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(book)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  navigate("transaction", {
                    state: {
                      BookID: selectedBook.BookID,
                      ClassID: selectedBook.ClassID,
                    },
                  })
                }
              >
                Transaction
              </MenuItem>
              {currentPageCRUD.delete && (
                <MenuItem onClick={_deleteCurrentBook}>Delete</MenuItem>
              )}
              {currentPageCRUD.update && (
                <MenuItem onClick={() => setEditOpen(true)}>Edit</MenuItem>
              )}
            </Menu>
          </TableContainer>
        </Stack>
      </Box>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Add Book</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box onSubmit={_onSubmit} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Class"
                    sx={{ display: "flex", flex: 1 }}
                    // value={selectedBook.ClassID}
                    variant="outlined"
                    name="ClassID"
                    required
                    onChange={_onHandleChange}
                    size="small"
                  >
                    {classes.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ClassModel.ID}>
                          {item.ClassModel.ClassName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    autoFocus
                    label="Book Name"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="NameOfBook"
                    onChange={_onHandleChange}
                  />
                </Grid>
                {/*<Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Book Number"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="BookNumber"
                    onChange={_onHandleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Receipt Number"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="ReceiptNumber"
                    onChange={_onHandleChange}
                  />
                </Grid>*/}
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Author Name"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="Author"
                    onChange={_onHandleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Related Subject"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="Subject"
                    onChange={_onHandleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    // loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Add Book
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}></Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Update Book</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Class"
                    sx={{ display: "flex", flex: 1 }}
                    value={selectedBook.ClassID}
                    variant="outlined"
                    name="ClassID"
                    required
                    onChange={_handleEditChange}
                    size="small"
                  >
                    {classes.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ClassModel.ID}>
                          {item.ClassModel.ClassName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Book Name"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="NameOfBook"
                    value={selectedBook.NameOfBook}
                    onChange={_handleEditChange}
                  />
                </Grid>
                {/*<Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Book Number"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="BookNumber"
                    value={selectedBook.BookNumber}
                    onChange={_handleEditChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Receipt Number"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="ReceiptNumber"
                    value={selectedBook.ReceiptNumber}
                    onChange={_handleEditChange}
                  />
                </Grid>*/}
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Author Name"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="Author"
                    value={selectedBook.Author}
                    onChange={_handleEditChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    // autoFocus
                    label="Related Subject"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="Subject"
                    value={selectedBook.Subject}
                    onChange={_handleEditChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    // loading={loading}
                    // type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={_onUpdate}
                  >
                    Update Book
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}></Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Book has been Added.
        </Alert>
      </Snackbar>
    </>
  );
}
