import React from "react";

import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = window.localStorage.getItem('userToken');
    if (isAuthenticated === '' || isAuthenticated === null) {
        return <Navigate to={'/login'} state={{ from: location }} replace />
    }
    return children;

}

export default ProtectedRoute;