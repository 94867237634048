import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getClusters } from "../Clusters/Clusters";
import { useFormik } from "formik";
import * as yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StaffPerformance() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteSnackbar, setDeleteSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clusters, setClusters] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshops, setSelectedWorkshops] = useState({});
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              CoCurricular Staff Performance Module
            </Typography>
          </Stack>
          <Box width={300}>
            <FormControl fullWidth>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Performance
              </Button>
            </FormControl>
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" component="h1">
            Training Report (TR)
          </Typography>
        </Box>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="center">Event</TableCell>
                  <TableCell align="center">Activity</TableCell>
                  <TableCell align="center">Responsible Name</TableCell>
                  <TableCell align="center">Responsible Designation</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  //   key={String(index)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    Text
                  </TableCell>
                  <TableCell align="center">Text</TableCell>
                  <TableCell align="center">Text</TableCell>
                  <TableCell align="center">Text</TableCell>
                  <TableCell align="center">Text</TableCell>
                  <TableCell align="center">Text</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              //   anchorEl={anchorEl}
              //   open={openAnchor}
              //   onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
              // onClick={() =>
              //   navigate("participant", {
              //     state: {
              //       Cluster: selectedWorkshops.Cluster,
              //       WorkshopID: selectedWorkshops.WorkshopID,
              //     },
              //   })
              // }
              >
                Participant
              </MenuItem>
              <MenuItem
              //   onClick={_onDelete}
              >
                Delete
              </MenuItem>
              <MenuItem
              //   onClick={_onEdit}
              >
                Edit
              </MenuItem>
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create Workshop</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={() => {}} component="form">
                  <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
                    Training Report (TR)
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Select Cluster"
                        sx={{ display: "flex", flex: 1 }}
                        variant="outlined"
                        name="ClustorID"
                        defaultValue="Select Student"
                        size="small"
                        // onChange={formik.handleChange}
                        // value={formik.values.ClustorID}
                      >
                        <MenuItem value="value">Text</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Topic"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Topic"
                        // onChange={formik.handleChange}
                        // value={formik.values.Topic}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        // autoFocus
                        label="Facilitator"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Facilitator"
                        // onChange={formik.handleChange}
                        // value={formik.values.Facilitator}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        // autoFocus
                        label="Venue"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Venue"
                        // onChange={formik.handleChange}
                        // value={formik.values.Venue}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Workshop Date"
                          //   value={formik.values.DateofWorkshop}
                          name="DateofWorkshop"
                          //   onChange={_handleDateOfWorkshop}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Start Time"
                          //   value={formik.values.StartTime}
                          name="StartTime"
                          //   onChange={_handleStartDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="End Time"
                          //   value={formik.values.EndTime}
                          name="EndTime"
                          //   onChange={_handleEndDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        General Remarks
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="General Remarks by Training Incharge"
                        name="Generalremarks"
                        style={{ width: "100%", padding: 15 }}
                        // onChange={formik.handleChange}
                        // value={formik.values.Generalremarks}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ pt: 0 }}>
                      <TextField
                        // autoFocus
                        label="Training Incharge Name"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="TrainerName"
                        // onChange={formik.handleChange}
                        // value={formik.values.TrainerName}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Remarks by AMS Academic and Training
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Remarks by AMS Academic and Training"
                        name="RemarksAMSAcademics"
                        // onChange={formik.handleChange}
                        style={{ width: "100%", padding: 15 }}
                        // value={formik.values.RemarksAMSAcademics}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Remarks by Manager Schools
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Remarks by Manager Schools"
                        style={{ width: "100%", padding: 15 }}
                        name="RemarksSchoolManager"
                        // onChange={formik.handleChange}
                        // value={formik.values.RemarksSchoolManager}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {isEdit ? "Save Changes" : "Create Workshop"}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Text
        </Alert>
      </Snackbar>
    </>
  );
}
