import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
export default function FeeList({
  loading,
  list,
  fetchList,
  edit,
  returenedItem,
  snackOpen,
  snackMessage,
}) {
  console.log("LIST", list);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    console.log("selectedItem", selectedItem);
    // _fetchStudents();
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;

      if (schoolId !== null) {
        setSchoolId(schoolId);
      }
    }
  }, []);
  const handleClick = (item) => async (event) => {
    console.log("ITEM", item);
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
    returenedItem(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClickOpen = () => {
    setDialog(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setDialog(false);
  };
  const _deleteItem = async () => {
    try {
      console.log("selectedItem", selectedItem);
      const { status, data } = await axiosInstance.delete(
        `/fees/${selectedItem.id}`
      );
      if (status === 200) {
        fetchList();
        handleDialogClose();
        snackMessage(data.message);
        snackOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _editCurrentItem = () => {
    edit(selectedItem);
    handleClose();
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Fee Head</TableCell>
            <TableCell align="center">Narration</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Book Number</TableCell>
            <TableCell align="center">Receipt Number</TableCell>
            <TableCell align="center">Class</TableCell>
            <TableCell align="center">Student </TableCell>
            <TableCell align="center">Paid Date</TableCell>
            <TableCell align="right">Paid</TableCell>

            <TableCell align="right">Actions</TableCell>
            {/* <TableCell align="right">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPageCRUD.read &&
            list.map((item, index) => (
              <TableRow
                key={String(index)}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.Fee_Head}
                </TableCell>
                <TableCell align="center">{item.Narration}</TableCell>
                <TableCell align="center">{item.Amount}</TableCell>
                <TableCell align="center">
                  {item.BookNumber !== null && item.BookNumber}
                </TableCell>
                <TableCell align="center">
                  {item.ReceiptNumber !== null && item.ReceiptNumber}
                </TableCell>
                <TableCell align="center">
                  {item.ClassID !== null && item.ClassModel.ClassName}
                </TableCell>
                <TableCell align="center">
                  {item.Student.StudentName} - {item.Student.GRNO}
                </TableCell>
                <TableCell align="center">
                  {dayjs(item.PaidDate).format("DD-MMMM-YYYY")}
                </TableCell>
                <TableCell align="right">{item.Paid ? "YES" : "NO"}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={handleClick(item)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          {loading && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center" colSpan={9}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {currentPageCRUD.delete && (
          <MenuItem onClick={handleDialogClickOpen} color="error">
            Delete
          </MenuItem>
        )}

        {currentPageCRUD.update && (
          <MenuItem onClick={_editCurrentItem}>Edit</MenuItem>
        )}
      </Menu>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete a fee voucher
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={_deleteItem} color="error" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
