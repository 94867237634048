import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Modal from "@mui/material/Modal";
// import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default function TeacherAttendaceSummary() {
  // const [classes, setClasses] = useState([]);
  // const [students, setStudents] = useState([]);
  const [results, setResults] = useState([]);
  const [schoolList, setSchoolList] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpenTeacherAttLog, setModalOpenTeacherAttLog] = useState(false);
  const [teacherAttendanceLog, setTeacherAttendanceLog] = useState([{}]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const open = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    dateFrom: dayjs().format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
  });

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setModalOpenTeacherAttLog(false);
    setSelectedIndex(null);
  };

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    if (accesLevel === "SCHOOL") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    searchParams.append("datefrom", formData.dateFrom);
    searchParams.append("dateto", formData.dateTo);

    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/teacher-attendance-summary",
          {
            params: searchParams,
          }
        );
        console.log("teachetAttendance", data.data[0]);

        setResults(data.data[0]);
      }
      if (accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/teacher-attendance-summary",
          {
            params: searchParams,
          }
        );
        console.log("teachetAttendance", data.data[0]);

        setResults(data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateFrom: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    console.log("cluster", cluster);
    console.log("schools", cluster[0].School);
    setSchoolList(cluster[0].School);
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateTo: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Teacher-Attendance-Summary.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "teacher-attendance-summary.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Teacher Attendance Summary" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Teacher Name",
        C: "Absent Count",
        D: "Present Count",
        E: "",
      },
    ];

    console.log("Results", results);
    results.length > 0 &&
      results.map((item, index) => {
        table.push({
          A: index + 1,
          B: item.TeacherName,
          C: item.Absent_Count,
          D: item.Present_Count,
        });
      });
    //.concat(table).concat([""]);
    // table = [{ A: "Teacher Attendance Summary" }];
    console.log("TableTeacher", table);

    const finalData = [...title, ...table];

    // create workbook
    const wb = XLSX.utils.book_new();

    // create worksheet
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "report-teacher-attendance");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:E2",
      tbodyRange: `A3:E${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:E${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(20);
        sheet.column("E").width(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const getTeacherAttendanceLog = async (teacherID) => {
    try {
      console.log(teacherID);
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        // const teacherID = results.tID;
        const searchParams = new URLSearchParams();
        searchParams.append("schoolid", schoolID);
        searchParams.append("teacherID", teacherID);
        searchParams.append("datefrom", formData.dateFrom);
        searchParams.append("dateto", formData.dateTo);
        try {
          const { data, status } = await axiosInstance.get(
            "/reports/teacher-attendance-log",
            { params: searchParams }
          );
          if (status === 200) {
            setTeacherAttendanceLog(data.data[0]);
            setModalOpenTeacherAttLog(true);
          }
        } catch (err) {
          console.error(err);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        // const teacherID = results.tID;
        const searchParams = new URLSearchParams();
        searchParams.append("schoolid", schoolID);
        searchParams.append("teacherID", teacherID);
        searchParams.append("datefrom", formData.dateFrom);
        searchParams.append("dateto", formData.dateTo);
        try {
          const { data, status } = await axiosInstance.get(
            "/reports/teacher-attendance-log",
            { params: searchParams }
          );
          if (status === 200) {
            setTeacherAttendanceLog(data.data[0]);
            setModalOpenTeacherAttLog(true);
          }
        } catch (err) {
          console.error(err);
        }
      }
    } catch (error) {}
  };

  const style = {
    marginTop: "4%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: "#ffffff",
    border: "none !important",
    p: 3,
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    maxHeight: '80vh',
    overflowY: 'auto',
  }));

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Teacher Attendance Summary
          </Typography>
          <Typography variant="subtitle2" component="span">
            Select Date to generate a new Daily Entries Report
          </Typography>
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/**/}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date From"
              value={formData.dateFrom}
              name="dateFrom"
              required
              onChange={_handleDateFrom}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date To"
              value={formData.dateTo}
              name="dateTo"
              required
              onChange={_handleDateTo}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadingButton
            // loading={loading}
            // disabled={loading}
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Teacher Attendance
          </LoadingButton>
        </Grid>
      </Grid>
      <Modal
      open={modalOpenTeacherAttLog}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Teacher Attendance Log
        </Typography>
        {teacherAttendanceLog && (
          <StyledPaper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {teacherAttendanceLog &&
                      teacherAttendanceLog.length > 0 &&
                      Object.keys(teacherAttendanceLog[0])
                        .filter((key) => key !== 'MissingCount')
                        .map((key) => (
                          <TableCell key={key}>
                            {key === 'Att_dateTime'
                              ? 'Date'
                              : key}
                          </TableCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Map table rows */}
                  {teacherAttendanceLog &&
                    teacherAttendanceLog.length > 0 &&
                    teacherAttendanceLog.map((data, index) => (
                      <TableRow key={index}>
                        {Object.entries(data)
                          .filter(([key]) => key !== 'MissingCount')
                          .map(([key, value], subIndex) => (
                            <TableCell key={subIndex}>
                              {key === 'Att_dateTime'
                                ? dayjs(value).format('DD-MMM-YYYY')
                                : value}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        )}
      </Box>
    </Modal>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="center">TeacherName</TableCell>
                  <TableCell align="center">Absent-Count</TableCell>
                  <TableCell align="center">Present-Count</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.length > 0 &&
                  results.map((item, index) => (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="center">{item.TeacherName}</TableCell>
                      <TableCell align="center">{item.Absent_Count}</TableCell>
                      <TableCell align="center">{item.Present_Count}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          id={`dropdown-button-${item.tID}`}
                          aria-controls={
                            selectedIndex === index
                              ? `dropdown-menu-${item.tID}`
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={selectedIndex === index}
                          onClick={(event) => handleClick(event, index)}
                          aria-label="dropdown"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`dropdown-menu-${item.tID}`}
                          aria-labelledby={`dropdown-button-${item.tID}`}
                          anchorEl={anchorEl}
                          open={selectedIndex === index}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            id={item.tID}
                            onClick={(e) => {
                              let id = e.target.id;
                              console.log("adasdasdas", id);
                              getTeacherAttendanceLog(id);
                            }}
                          >
                            Teacher Attendance Log
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
