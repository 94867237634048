import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "../../Api/axios.instance";
import IconButton from "@mui/material/IconButton";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import useSession from "../../CustomHooks/useSession";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const updateClassName = async (id, updatedClassName) => {
  try {
    const { data, status } = await axiosInstance.put(
      `/classes/${id}`,
      updatedClassName
    );
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
export function getClasses() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/classes");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function insertClasses(post) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.post("/classes", post);
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function Classes({ setClasses: _setClasses }) {
  const [classes, setClasses] = useState([]);
  const [open, setOpen] = useState(false);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [selectedClass, setSelectedClass] = useState({
    ID: null,
    oldValue: null,
    newValue: null,
  });
  const handleOpen = (item) => (e) => {
    setOpen(true);
    setSelectedClass({
      ID: item.ID,
      oldValue: item.ClassName,
      newValue: item.ClassName,
    });
  };
  const handleClose = () => setOpen(false);

  const formRef = useRef(null);
  useEffect(() => {
    _getClasses();
  }, []);
  const _getClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
      _setClasses(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const post = {
      className: formData.get("className"),
    };
    try {
      const response = await insertClasses(post);
      setClasses((prevState) => {
        return [...prevState, response.data];
      });
      formRef.current.reset();
    } catch (error) {
      console.log(error);
    }
  };

  const _UpdateClassName = async (e) => {
    e.preventDefault();
    if (selectedClass.oldValue === selectedClass.newValue) {
      window.alert("Please Enter An Updated Value");
      return;
    }
    try {
      const updateClass = await updateClassName(selectedClass.ID, {
        className: selectedClass.newValue,
      });

      const index = classes.findIndex((item) => item.ID === selectedClass.ID);
      if (index !== -1) {
        setClasses((prevState) => {
          prevState[index].ClassName = selectedClass.newValue;
          return [...prevState];
        });
      }

      handleClose();
    } catch (error) {
      window.alert(error);
    }
  };
  const _handleUpdateClassName = (e) => {
    setSelectedClass((prevState) => {
      return {
        ...prevState,
        newValue: e.target.value,
      };
    });
  };
  return (
    <Paper square>
      <Box sx={{ my: 1, px: 2 }}>
        <Typography variant="subtitle1">Classes</Typography>
      </Box>
      <Box
        ref={formRef}
        onSubmit={_onSubmit}
        component="form"
        sx={{
          display: "flex",
          height: 60,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          px: 1,
        }}
      >
        {/*<TextField
          required={true}
          name="className"
          variant="outlined"
          sx={{ color: "white", display: "flex", flex: 1 }}
          label="Enter Class Name"
          size="small"
          inputProps={{ "aria-label": "Enter Username" }}
        />
        <LoadingButton
          type="submit"
          loading={false}
          variant="text"
          sx={{ ml: 1, color: "white" }}
        >
          ADD
        </LoadingButton>*/}
      </Box>
      <Divider orientation="horizontal" sx={{ my: 1 }} />
      <List>
        {classes.map((item, index) => (
          <ListItem key={String(index)}>
            <Box
              display={"flex"}
              justifyContent="space-between"
              width={"100%"}
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Typography variant="body2">{item.ClassName}</Typography>
              {currentPageCRUD.update && (
                <IconButton aria-label="delete" onClick={handleOpen(item)}>
                  <EditTwoToneIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style} component="form" onSubmit={_UpdateClassName}>
          <TextField
            required={true}
            name="UpdatedClassName"
            variant="outlined"
            sx={{
              color: "white",
              display: "flex",
              flex: 1,
              marginBottom: "20px",
            }}
            label="Enter Updated Class Name"
            size="small"
            inputProps={{ "aria-label": "Enter Updated Class Name" }}
            value={selectedClass.newValue}
            onChange={_handleUpdateClassName}
          />
          <Button variant="contained" type="submit">
            Update Class Name
          </Button>
        </Stack>
      </Modal>
    </Paper>
  );
}
