import React, { useEffect, useState } from "react";
import { StyleProvider, styles } from "../../Styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/axios.instance";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../../Assets/logo.png";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const loginUser = async (data) => {
  try {
    const { data: response, status } = await axiosInstance.post(
      "/users/authenticate",
      data
    );
    console.log("user data", data);
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Baithak School Network (SEW) {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dangerMessage, setDangerMessage] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({
    fullName: "",
    username: "",
  });
  const navigate = useNavigate();
  const _authenticate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const post = {
      username: formData.get("username"),
      password: formData.get("password"),
    };
    setIsLoading(true);
    console.log(isLoading);
    const user = await loginUser(post);
    console.log("user", user);
    if (user != null && user.hasOwnProperty("success")) {
      window.localStorage.setItem("userToken", user.token);
      window.localStorage.setItem("User", JSON.stringify(user.user));
      window.localStorage.setItem("Role", JSON.stringify(user.user.Role));
      window.localStorage.setItem(
        "UserAccess",
        JSON.stringify(user.user.UserAccess)
      );
      window.sessionStorage.setItem("workStation","cluster")
      navigate("/");
    } else {
      window.alert(user.message);
      setIsLoading(false);
    }
    // navigate("/", {
    //   replace: true,
    // });
  };
  const _handleOnChange = (e) => {
    setForgotPassword((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const handleClickShowPassword = () => {
  //   setShowPassword((prevState) => !prevState);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = async () => {
    console.log("forgotPassword", forgotPassword);
    try {
      setIsLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/users/reset-password",
        forgotPassword
      );
      console.log("forgotPassword", data.data);
      if (status === 200) {
        setIsLoading((prevState) => !prevState);
        setOpenAlert(true);
      }
    } catch (error) {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 404) {
          setDangerMessage(error.response.data.message);
          setOpenAlertDanger(true);
        }
      }
      setIsLoading((prevState) => !prevState);
      console.log(error);
    }
    setOpen(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleAlertDangerClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertDanger(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="App">
      <StyleProvider theme={styles}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1462536943532-57a629f6cc60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Baithak Logo"
                src={Logo}
                sx={{ width: 56, height: 56, mb: 2 }}
              />
              <Typography component="h1" variant="h5">
                Baithak School Network (SEW)
              </Typography>
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={_authenticate}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="username"
                  autoFocus
                />
                <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button variant="primary" onClick={handleClickOpen}>
                    Forgot password?
                  </Button>
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Send Password reset Request
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will send a request to your admin to create you a new
              password.
              <Box>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1, mt: 1, mb: 1 }}
                  label="Full Name"
                  name="fullName"
                  required
                  onChange={_handleOnChange}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  label="User Name"
                  name="username"
                  required
                  onChange={_handleOnChange}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleContinue} autoFocus>
              Send Request
            </Button>
          </DialogActions>
        </Dialog>
      </StyleProvider>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Request has been sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertDanger}
        autoHideDuration={6000}
        onClose={handleAlertDangerClose}
      >
        <Alert
          onClose={handleAlertDangerClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {dangerMessage}
        </Alert>
      </Snackbar>
      {/*<Alert severity="success"></Alert>*/}
    </div>
  );
}
