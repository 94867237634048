import { Box, Chip, Divider, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Card from "../../Components/Students/Card";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../Api/axios.instance";
import useSession from "../../CustomHooks/useSession";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export function getStudents(schoolId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school/${schoolId}`
      );
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function StudentsWrapper() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchType, setSearchType] = useState({
    text: "GR NO",
    value: "GRNO",
  });
  const [searchText, setSearchText] = useState("");
  const [students, setStudents] = useState([]);

  const [originalStudents, setOriginalStudents] = useState([]);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (
      accesLevel === "SCHOOL" ||
      accesLevel === "ADMIN" ||
      accesLevel === "CLUSTER"
    ) {
      _fetchStudents();
    }
  }, []);
  const _fetchStudents = async () => {
    const accessObject = Object.assign({}, access);
    try {
      const students = await getStudents(accessObject[0].SchoolId);
      setStudents(students.data);
      // console.log("ALL STUDENT", students.data);
      setOriginalStudents(students.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (param, paramText) => (e) => {
    setSearchType({
      text: paramText,
      value: param,
    });
    setAnchorEl(null);
  };
  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    search(e.target.value);
  };
  const search = debounce((val) => {
    if (val.trim().length === 0) {
      setStudents(originalStudents);
      return;
    }
    const filteredRows = students.filter((row) => {
      return row[searchType.value]
        .toLowerCase()
        .trim()
        .includes(val.toLowerCase().trim());
    });
    setStudents(filteredRows);
  }, 500);
  const _onClickAdd = useCallback(() => {
    navigate("add");
  }, []);
  const _onClickPromote = useCallback(() => {
    navigate("/promote-students");
  }, []);
  const _removeStudent = (id) => {
    setStudents((prevState) => {
      return prevState.filter((item) => item.SID !== id);
    });
  };
  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Grid container spacing={2} mb={2}>
        <Grid Grid item xs={12} md={6}>
          {currentPageCRUD.read && (
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                value={searchText}
                onChange={onSearchChange}
                sx={{ ml: 1, flex: 1, fontSize: 13 }}
                placeholder="Search Students"
                inputProps={{ "aria-label": "search students" }}
              />
              <Button onClick={handleClickOpen}>{searchType.text}</Button>
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          )}
        </Grid>
        <Grid Grid item xs={12} md={6} textAlign="right">
          {currentPageCRUD.create && (
            <Button onClick={_onClickAdd} variant="contained" sx={{ mr: 2 }}>
              Add New Student
            </Button>
          )}
          {currentPageCRUD.create && (
            <Button
              onClick={_onClickPromote}
              color="secondary"
              variant="outlined"
            >
              Promote Student
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 1 }} />
      {/*<Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Chip
            sx={{ mr: 1 }}
            avatar={
              // <Avatar alt="Natacha" src={} />
              <AccountCircleIcon sx={{ width: 20, height: 20 }} />
            }
            className="cursor-pointer"
            label="CLASS WISE STUDENTS"
            variant="outlined"
            onClick={() => navigate("/reports/class-wise-students")}
          />
          <Chip
            sx={{ mr: 1 }}
            avatar={
              // <Avatar alt="Natacha" src={} />
              <AccountCircleIcon sx={{ width: 20, height: 20 }} />
            }
            className="cursor-pointer"
            label="DROPOUTS"
            variant="outlined"
            onClick={() => navigate("/reports/dropout-students")}
          />
          <Chip
            sx={{ mr: 1 }}
            avatar={
              // <Avatar alt="Natacha" src={} />
              <AccountCircleIcon sx={{ width: 20, height: 20 }} />
            }
            className="cursor-pointer"
            label="ORPHANS"
            variant="outlined"
            onClick={() => navigate("/reports/orphans-strength")}
          />
          <Chip
            sx={{ mr: 1 }}
            avatar={
              // <Avatar alt="Natacha" src={} />
              <AccountCircleIcon sx={{ width: 20, height: 20 }} />
            }
            className="cursor-pointer"
            label="MALE FEMALE RATIO"
            variant="outlined"
            onClick={() => navigate("/reports/male-female-ratio")}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 1 }} />*/}
      <Box flexGrow={1}>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {currentPageCRUD.read &&
            students.map((student, index) => (
              <Grid key={index} xs={4}>
                <Card student={student} deleteStudent={_removeStudent} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose("GRNO", "GR NO")}>GRNO</MenuItem>
        <MenuItem onClick={handleClose("StudentName", "Student Name")}>
          Student Name
        </MenuItem>
        <MenuItem onClick={handleClose("FathersName", "Father's Name")}>
          Father's Name
        </MenuItem>
        <MenuItem onClick={handleClose("NIC_Father", "Father's CNIC")}>
          Father's CNIC
        </MenuItem>
      </Menu>
    </Box>
  );
}
