import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const StudentStrength = ({ yearStrength }) => {
  const [males, setMales] = useState([]);
  const [females, setFemales] = useState([]);

  useEffect(() => {
    if (yearStrength !== null) {
      setMales(yearStrength?.males);
      setFemales(yearStrength?.females);
    }
  }, [yearStrength]);
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "#000000",
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Year wise student chart",
      },
    },
  };
  const labels = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );
  console.log(
    "labels",
    labels.map(
      (item) =>
        males.filter((male) => item === Number(male.CurrentSession)).length
    )
  );
  const data = {
    labels,
    datasets: [
      {
        label: "Male",
        data: labels.map(
          (item) =>
            males.filter((male) => item === Number(male.CurrentSession)).length
        ),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Female",
        data: labels.map(
          (item) =>
            females.filter((female) => item === Number(female.CurrentSession))
              .length
        ),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default StudentStrength;
