import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "../../Api/axios.instance";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function addTeacher(post) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.post("/teachers", post);
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function AddTeacher({ teachers, classes }) {
  const [selectClass, setSelectedClass] = React.useState(null);
  const [selectTeacher, setSelectedTeacher] = React.useState(null);
  const [selectInputClass, setSelectedInputClass] = React.useState("");
  const [selectInputTeacher, setSelectedInputTeacher] = React.useState("");
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const [snack, setSnack] = React.useState({
    open: false,
    message: "",
    type: "success",
  });
  const _onTeacherChange = (event, newValue) => {
    setSelectedTeacher(newValue);
  };
  const _onTeacherInputValue = (event, newInputValue) => {
    setSelectedInputTeacher(newInputValue);
  };
  const _onClassChange = (event, newValue) => {
    setSelectedClass(newValue);
  };
  const _onClassInputValue = (event, newInputValue) => {
    setSelectedInputClass(newInputValue);
  };
  const _onAddTeacher = async () => {
    if (selectClass === null && selectTeacher === null) {
      setSnack((prevState) => {
        return {
          open: true,
          type: "error",
          message: "All Fields are required",
        };
      });
      return;
    }
    const post = {
      classId: selectClass.id,
      teacherId: selectTeacher.id,
    };
    try {
      const response = await addTeacher(post);
      if (response.success) {
        setSnack((prevState) => {
          return {
            open: true,
            type: "success",
            message: "Teacher has been added successfully",
          };
        });
      }
    } catch (error) {
      setSnack((prevState) => {
        return {
          open: true,
          type: "error",
          message:
            error.response.data.message ||
            "There was an error, please contact administrator.",
        };
      });
    }
  };
  const handleSnakClose = () => {
    setSnack((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };
  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-teacher"
          options={teachers.map((item, index) => {
            return {
              label: `(${index + 1}) - ${item.TeacherName}`,
              id: item.SchoolTeachersQId,
            };
          })}
          value={selectTeacher}
          onChange={_onTeacherChange}
          inputValue={selectInputTeacher}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onInputChange={_onTeacherInputValue}
          sx={{ display: "flex", flex: 1 }}
          renderInput={(params) => (
            <TextField {...params} key={params.id} label="Select Teacher" />
          )}
        />
        <Autocomplete
          disablePortal
          id="combo-box-class"
          options={classes.map((item, index) => {
            return {
              label: `(${index + 1}) - ${item.ClassName}`,
              id: item.ID,
            };
          })}
          value={selectClass}
          onChange={_onClassChange}
          inputValue={selectInputClass}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onInputChange={_onClassInputValue}
          sx={{ display: "flex", flex: 1 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Class" />
          )}
        />
      </Stack>
      <Box sx={{ mt: 3 }}>
        {currentPageCRUD.create && (
          <LoadingButton onClick={_onAddTeacher} variant="contained" fullWidth>
            Save
          </LoadingButton>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnakClose}
      >
        <Alert
          onClose={handleSnakClose}
          severity={snack.type}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
