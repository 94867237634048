import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { getClasses } from "../ClassSubjects/Classes";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const interviewQuestions = [
  {
    question: "",
    optionType: "",
    options: [],
  },
];

export default function ParentInterview() {
  const [open, setOpen] = useState(false);
  const [openInterviewForm, setOpenInterviewForm] = useState(false);
  const [scoreOpen, setScoreOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState("");
  const [designation, setDesignation] = useState("");
  const [observers, setObservers] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedObservation, setSelectedObservation] = useState({});
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [editVariables, setEditVariables] = useState([]);
  const [selectedObserver, setSelectedObserver] = useState(null);
  const [selectInputObserver, setSelectedInputObserver] = React.useState("");
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [LanguageAtHome, setLanguageAtHome] = useState("");
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [fatherInfo, setFatherInfo] = useState({
    fatherName: "",
    fatherQualification: "",
    fatherDesignation: "",
  });
  const [motherInfo, setMotherInfo] = useState({
    motherName: "",
    motherQualification: "",
    motherDesignation: "",
  });
  const [formData, setFormData] = useState({
    SchoolID: schoolId,
    ClassID: "",
    SID: "",
  });

  const classOrder = [
    "KG I",
    "KG II",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
  ];

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchParentInterviewsBySchool(schoolId);
      }
    }
    const accessObject = Object.assign({}, access);
    const schoolId = accessObject[0].SchoolId;
    setSchoolId(schoolId);
    _fetchClasses();
    _fetchParentInterviewsBySchool(schoolId);
  }, []);

  useEffect(() => {
    _fetchParentInterviewVariables();
  }, []);

  const _handleQuestionChange = (e, key) => {
    const { name, value } = e.target;
    const list = [...questions];
    list[key][name] = value;
    setQuestions(list);
  };

  const _handleLanguageChange = (e) => {
    setLanguageAtHome(e.target.value);
  };

  const _handleFatherInfoChange = (e) => {
    setFatherInfo((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleMotherInfoChange = (e) => {
    setMotherInfo((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ClassID: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${e.target.value}`
      );
      console.log("setStudents", data.data);
      if (status === 200) {
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    // setSelectedObservation(observation);
  };
  const _onInterviewSubmit = async (e) => {
    e.preventDefault();
    const interviewData = {
      SchoolID: schoolId,
      SID: formData.SID,
      questionJson: questions,
    };
    try {
      const { data, status } = await axiosInstance.post(
        "/parent-interviews/single-student-pi",
        interviewData
      );
      console.log("response", data);
      if (status === 200) {
        _fetchParentInterviewsBySchool(schoolId);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpenInterviewForm(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const interviewData = {
      LanguageAtHome,
      motherInfo,
      fatherInfo,
      questions,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/parent-interviews/${selectedInterview.ID}`,
        interviewData
      );
      console.log("response", data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchParentInterviewsBySchool(schoolId);
        setSnackMessage(data.message);
        setSnackOpen(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const _fetchParentInterviewsBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/parent-interviews/${id}`
      );
      console.log("interviews", data.data);
      if (status === 200) {
        const sortedInterviews = data.data.sort((a, b) => {
          const classA = a?.Student?.ClassModel?.ClassName;
          const classB = b?.Student?.ClassModel?.ClassName;
    
          if (classOrder.indexOf(classA) < classOrder.indexOf(classB)) {
            return -1;
          }
          if (classOrder.indexOf(classA) > classOrder.indexOf(classB)) {
            return 1;
          }
          return 0;
        });
        setInterviews(sortedInterviews);
        // setInterviews(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _fetchParentInterviewVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-parent-interview"
      );
      console.log("Variables", data.data);
      if (status === 200) {
        setVariables(data.data);
        setSelectedVariables(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInterviewClose = () => {
    setOpenInterviewForm(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const _handleInterviewOpen = (interview) => () => {
    console.log("interview", interview);
    const questionData =
      interview.AnswerJSON !== null
        ? interview.AnswerJSON
        : interview.QuestionJSON.includes("\\")
        ? JSON.parse(interview.QuestionJSON)
        : interview.QuestionJSON;
    console.log("questionData.questions", questionData);
    if (interview.MotherInfo !== null) {
      setMotherInfo(JSON.parse(interview.MotherInfo));
    }
    if (interview.FatherInfo !== null) {
      setFatherInfo(JSON.parse(interview.FatherInfo));
    }
    if (interview.LanguageAtHome !== null) {
      setLanguageAtHome(interview.LanguageAtHome);
      console.log("LanguageAtHome", interview.LanguageAtHome);
    }
    setSelectedInterview(interview);
    setQuestions(JSON.parse(questionData).questions);
    setOpen(true);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  const _handleScoreOpen = (observation) => () => {
    setSelectedObservation(observation);
    setScoreOpen(true);
  };
  const handleScoreClose = () => {
    setScoreOpen(false);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Parent Interview Form
          </Typography>
        </Stack>
        <Box width={300}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenInterviewForm(true)}
            >
              Add Interview
            </Button>
          </FormControl>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Student Class</TableCell>
                <TableCell align="center">Student GRNO</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read &&
                interviews.map((interview, index) => {
                  if (interview.Student.StatusCode === "ACTIVE") {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell component="th" scope="row">
                          {interview?.Student?.ClassModel?.ClassName}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          {interview?.Student?.ClassModel?.ClassName &&
                            (classOrder.includes(
                              interview.Student.ClassModel.ClassName
                            )
                              ? interview.Student.ClassModel.ClassName
                              : "")}
                        </TableCell>
                        <TableCell align="center">
                          {interview.Student.GRNO}
                        </TableCell>
                        <TableCell align="center">
                          {interview.Student.StudentName}
                        </TableCell>
                        <TableCell align="center">{interview.Status}</TableCell>
                        <TableCell align="center">
                          {dayjs(interview.createdAt).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="right">
                          {/*<IconButton onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>*/}
                          <Button
                            color="primary"
                            onClick={_handleInterviewOpen(interview)}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            //   onClick={_onDelete}
            color="error"
          >
            Details
          </MenuItem>
        </Menu>*/}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openInterviewForm}
        onClose={handleInterviewClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Create Parent Interview Form
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onInterviewSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="ClassID"
                  required
                  onChange={_handleClassChange}
                  size="small"
                >
                  {classes.length > 0 &&
                    classes.map((item, index) => (
                      <MenuItem key={index} value={item.ID}>
                        {item.ClassName}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Student"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="SID"
                  required
                  onChange={_handleOnChange}
                  size="small"
                >
                  {students.map((student, index) => {
                    return (
                      <MenuItem key={index} value={student.SID}>
                        {student.StudentName} ({student.FathersName})
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Create Interview
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInterviewClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Parent Interview Form
            </Typography>
            <Typography variant="body1" component="h4">
              <strong>Student Name:</strong>{" "}
              {Object.keys(selectedInterview).length > 0 &&
                selectedInterview.Student.StudentName}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                {/* <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Language at home"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  value={LanguageAtHome}
                  name="LanguageAtHome"
                  required
                  onChange={_handleLanguageChange}
                  size="small"
                >
                  <MenuItem value="URDU">URDU</MenuItem>
                  <MenuItem value="SINDHI">SINDHI</MenuItem>
                  <MenuItem value="PANJABI">PANJABI</MenuItem>
                  <MenuItem value="BALOCHI">BALOCHI</MenuItem>
                  <MenuItem value="PASHTO">PASHTO</MenuItem>
                  <MenuItem value="HINDHKO">HINDHKO</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  label="Father Name"
                  value={
                    Object.keys(selectedInterview).length > 0 &&
                    selectedInterview.Student.FathersName
                  }
                  name="fatherName"
                  onChange={_handleFatherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Qualification"
                  name="fatherQualification"
                  value={
                    Object.keys(selectedInterview).length > 0 &&
                    selectedInterview.Student.Father_Qualification
                  }
                  onChange={_handleFatherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Designation"
                  name="fatherDesignation"
                  value={
                    Object.keys(selectedInterview).length > 0 &&
                    selectedInterview.Student.Father_Occupation
                  }
                  onChange={_handleFatherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Mother Name"
                  name="motherName"
                  // value={selectedInterview.Student.Father_Qualification}
                  onChange={_handleMotherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Qualification"
                  name="motherQualification"
                  value={
                    Object.keys(selectedInterview).length > 0 &&
                    selectedInterview.Student.Mother_qualification
                  }
                  onChange={_handleMotherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ display: "flex", flex: 1 }}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Designation"
                  name="motherDesignation"
                  value={
                    Object.keys(selectedInterview).length > 0 &&
                    selectedInterview.Student.Mother_Occupation
                  }
                  onChange={_handleMotherInfoChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <SectionDivider text="Questions" />
              </Grid>
              <Grid item xs={12} md={12}>
                {questions?.length > 0 &&
                  questions.map((question, index) => {
                    return (
                      <Grid
                        key={index}
                        container
                        spacing={2}
                        sx={{ mb: 2 }}
                        alignItems="center"
                      >
                        <Grid item xs={12} md={9}>
                          <Typography variant="body1" component="h4">
                            {question.question}
                          </Typography>
                        </Grid>
                        {question.optionType === "INPUT" && (
                          <Grid item xs={12} md={3}>
                            <TextareaAutosize
                              aria-label="minimum height"
                              minRows={3}
                              placeholder="Answer"
                              name="answer"
                              value={question.answer}
                              onChange={(e) => _handleQuestionChange(e, index)}
                              style={{ width: "100%", padding: 15 }}
                            />
                          </Grid>
                        )}
                        {question.optionType === "SELECT" && (
                          <Grid item xs={12} md={3}>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Answer"
                              sx={{ display: "flex", flex: 1 }}
                              value={question.answer}
                              variant="outlined"
                              name="answer"
                              required
                              onChange={(e) => _handleQuestionChange(e, index)}
                              size="small"
                            >
                              {question.options.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  //   loading={loading}
                  //   disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Save Interview
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            //   onSubmit={_onEdit}
            component="form"
          >
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Update Observation
                  {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={scoreOpen}
        onClose={handleScoreClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Excellent</TableCell>
                    <TableCell align="center">Good</TableCell>
                    <TableCell align="center">Better</TableCell>
                    <TableCell align="right">Need Improvement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    //   key={String(index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">4</TableCell>
                    <TableCell align="center">3</TableCell>
                    <TableCell align="center">2</TableCell>
                    <TableCell align="right">1</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <SectionDivider text="Scores" />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*{Object.keys(selectedObservation).length > 0 &&
                    selectedObservation.ClassObservationScore.map(
                      (observation, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {observation.Variable.Caption}
                            </TableCell>
                            <TableCell align="right">
                              {observation.score}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}*/}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScoreClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
