import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  SchoolID: yup.string("Enter School").required("School is required"),
  TeacherID: yup.string("Enter Teacher").required("Teacher is required"),
  ClassID: yup.string("Enter Class").required("Class is required"),
  SubjectID: yup.string("Enter Subject").required("Subject is required"),
  ObserverID: yup.string("Enter Observer").required("Observer is required"),
  ObservationDate: yup
    .string("Enter Observation Date")
    .required("Observation Date is required"),
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function ClassObservation() {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [school, setSchool] = useState("");
  const [designation, setDesignation] = useState("");
  const [observers, setObservers] = useState([]);
  const [selectedObserver, setSelectedObserver] = useState(null);
  const [selectInputObserver, setSelectedInputObserver] = React.useState("");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { accesLevel, access } = useSession();
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchObservers();
    }

    if (accesLevel === "CLUSTER") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchool(accessObject[0].School.NameOfSchool);
      setSchoolId(schoolId);
      if (schoolId !== null) {
        _fetchClassesBySchool(schoolId);
        _fetchTeachersBySchool(schoolId);
      }
      _fetchObservers();
    }
  }, []);
  const okFunc = () => {
    alert("OKKKKKKKK");
  };
  const formik = useFormik({
    initialValues: {
      SchoolID: schoolId,
      TeacherID: "",
      ClassID: "",
      SubjectID: "",
      ObserverID: "",
      Comments: "",
      ObservationDate: dayjs().format("YYYY-MM-DD"),
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      alert("ok");
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
        console.log("formData", formData);
      }
      console.log("formDatas");
    },
  });
  const _fetchObservers = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school-designation`
      );
      console.log("observers", data.data);
      if (status === 200) {
        setObservers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchClassesBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-school/${id}`
      );
      console.log("classes", data.data);
      if (status === 200) {
        setClasses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchTeachersBySchool = async (id) => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school/${id}`
      );
      console.log("setTeachers", data.data);
      if (status === 200) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleClassChange = async (e) => {
    formik.setFieldValue("ClassID", e.target.value);
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
      if (status === 200) {
        setSubjects(data.data);
      }
      // formik.setFieldValue("DateofWorkshop", dayjs(e));
    } catch (error) {
      console.log(error);
    }
  };
  const _handleDateOfObservation = (e) => {
    formik.setFieldValue("ObservationDate", dayjs(e));
  };
  const _onObserverChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedObserver(newValue);
    setDesignation(newValue.TeachersDesignation);
    formik.setFieldValue("ObserverID", newValue.id);
    // formik.setFieldValue("ObserverID", newValue.id)
  };
  const _onObserverInputValue = (event, newInputValue) => {
    setSelectedInputObserver(newInputValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    // setSelectedParticipant(participant);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const deleteSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setDeleteSnackOpen(false);
  };
  return (
    <Box>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Class Teacher Observation Module
            </Typography>
          </Stack>
          <Box width={300}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Observation
              </Button>
            </FormControl>
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Participant</TableCell>
                  <TableCell align="center">Baithak School</TableCell>
                  <TableCell align="center">Arrival</TableCell>
                  <TableCell align="center">Departure</TableCell>
                  <TableCell align="center">
                    Remarks by Training Incharge
                  </TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  //   key={String(index)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    text
                  </TableCell>
                  <TableCell align="center">text</TableCell>
                  <TableCell align="center">
                    {/* {dayjs(participant.ArrivalDatetime).format("DD-MM-YYYY")} */}
                    text
                  </TableCell>
                  <TableCell align="center">text</TableCell>
                  <TableCell align="center">text</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleAnchorClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              // onClick={_onDelete}
              color="error"
            >
              Delete
            </MenuItem>
            <MenuItem>Edit</MenuItem>
          </Menu>
        </Stack>
      </Box>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Teacher Observation Form (during class)
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box flexGrow={1} onSubmit={formik.handleSubmit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                  <Grid item xs={12} md={12}>
                    {/* <Typography variant="span" color="error" component="h4">
                      Note: Please Select Class first before Subject.
                    </Typography> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      sx={{ display: "flex", flex: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                      label="School Name"
                      name="SchoolID"
                      value={school}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Teacher"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="TeacherID"
                      value={formik.values.TeacherID}
                      required
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.TeacherID &&
                      //   Boolean(formik.errors.TeacherID)
                      // }
                      // helperText={
                      //   formik.touched.TeacherID && formik.errors.TeacherID
                      // }
                      size="small"
                    >
                      {teachers.map((teacher, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={teacher.SchoolTeachersQId}
                          >
                            {teacher.TeacherName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Class"
                      sx={{ display: "flex", flex: 1 }}
                      value={formik.values.ClassID}
                      variant="outlined"
                      name="ClassID"
                      required
                      onChange={_handleClassChange}
                      // error={
                      //   formik.touched.TeacherID &&
                      //   Boolean(formik.errors.TeacherID)
                      // }
                      // helperText={
                      //   formik.touched.TeacherID && formik.errors.TeacherID
                      // }
                      size="small"
                    >
                      {classes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.ClassModel.ID}>
                            {item.ClassModel.ClassName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Subjects"
                      sx={{ display: "flex", flex: 1 }}
                      value={formik.values.SubjectID}
                      variant="outlined"
                      name="SubjectID"
                      required
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.TeacherID &&
                      //   Boolean(formik.errors.TeacherID)
                      // }
                      // helperText={
                      //   formik.touched.TeacherID && formik.errors.TeacherID
                      // }
                      size="small"
                    >
                      {subjects.map((subject, index) => {
                        return (
                          <MenuItem key={index} value={subject.ID}>
                            {subject.SubjectName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SectionDivider text="Questions" />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mb: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={9}>
                        {/*
                  <Typography variant="body1" component="h4">
                      Lorem ipsum dolor sit, amet consectetur adipisicing
                      elit. Delectus, sit?
                    </Typography> */}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {/*
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Performance"
                      sx={{ display: "flex", flex: 1 }}
                      value={formik.values.SubjectID}
                      variant="outlined"
                      name="SchoolID"
                      required
                      // onChange={_handleSchoolChange}
                      // error={
                      //   formik.touched.SchoolID && Boolean(formik.errors.SchoolID)
                      // }
                      // helperText={
                      //   formik.touched.SchoolID && formik.errors.SchoolID
                      // }
                      size="small"
                    >
                      <MenuItem>Excellent</MenuItem>
                      <MenuItem>Good</MenuItem>
                      <MenuItem>Better</MenuItem>
                      <MenuItem>Need Improvement</MenuItem>
                    </TextField>
                  */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SectionDivider text="Observation Detail" />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Narration"
                      name="Comments"
                      value={formik.values.Comments}
                      onChange={formik.handleChange}
                      style={{ width: "100%", padding: 15 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mb: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-teacher"
                          options={observers.map((item, index) => {
                            return {
                              // ...item,
                              TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                              label: `(${item.SchoolTeachersQId}) - ${
                                item.TeacherName
                              } - ${
                                item.School !== null && item.School.NameOfSchool
                              }`,
                              id: item.SchoolTeachersQId,
                            };
                          })}
                          value={selectedObserver}
                          onChange={_onObserverChange}
                          inputValue={selectInputObserver}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                          }
                          onInputChange={_onObserverInputValue}
                          sx={{ display: "flex", flex: 1 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              key={params.id}
                              label="Select Teacher"
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ display: "flex", flex: 1 }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={designation}
                          label="Observer Designation"
                          name="TeachersDesignation"
                          //   onChange={formik.handleChange}
                          //   value={formik.values.FathersName}
                          //   error={
                          //     formik.touched.FathersName &&
                          //     Boolean(formik.errors.FathersName)
                          //   }
                          //   helperText={
                          //     formik.touched.FathersName && formik.errors.FathersName
                          //   }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            inputFormat="DD-MM-YYYY"
                            label="Date of Observation"
                            name="ObservationDate"
                            value={formik.values.ObservationDate}
                            onChange={_handleDateOfObservation}
                            //   error={
                            //     formik.touched.DepartureDateTime &&
                            //     Boolean(formik.errors.DepartureDateTime)
                            //   }
                            //   helperText={
                            //     formik.touched.DepartureDateTime &&
                            //     formik.errors.DepartureDateTime
                            //   }
                            renderInput={(params) => (
                              <TextField
                                sx={{ display: "flex", flex: 1 }}
                                size="small"
                                required
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mb: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={12}>
                        <Typography variant="span" color="error" component="h4">
                          Note: Principal should observe one teacher per week
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      // loading={loading}
                      // disabled={loading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      // onClick={formik.handleSubmit}
                    >
                      Create Observation
                      {/*{isEdit ? "Update Observation" : "Create Observation"}*/}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {/*
      {isEdit
        ? "Participant has been Updated."
        : "Participant has been Added."}
    */}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={deleteSnackOpen}
        autoHideDuration={6000}
        onClose={deleteSnack_handleClose}
      >
        <Alert
          onClose={deleteSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction has been Deleted.
        </Alert>
      </Snackbar>
    </Box>
  );
}
