import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axiosInstance from "../../Api/axios.instance";
import Box from "@mui/material/Box";
import AddStudents from "./AddStudents";
import StudentList from "./StudentsList";
import VariablesTable from "./VariablesTable";
export function getStudents(schoolId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get(`/students/by-school/${schoolId}`);
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Students() {
  const [value, setValue] = React.useState(0);
  const [students, setStudents] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    _fetchStudents();
  }, []);
  const _fetchStudents = async () => {
    try {
      const schoolId = localStorage.getItem("UserAccess") !== null ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId : 0;
      const response = await getStudents(schoolId);
      setStudents(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Students" {...a11yProps(0)} />
          <Tab label="Add Students" {...a11yProps(1)} />
          <Tab label="Student Social Status Questions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StudentList students={students} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddStudents fetchStudents={_fetchStudents} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <VariablesTable />
      </TabPanel>
    </Box>
  );
}
