import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import { useNavigate } from "react-router-dom";
import useSession from "../../CustomHooks/useSession";

export default function StaffList({ staff }) {
  const [staffArray, setStaffArray] = useState([]);
  const navigate = useNavigate();
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  useEffect(() => {
    setStaffArray(staff);
    console.log("STAFF", staff);
  }, [staff]);
  const _onDetailsClick = (details) => (e) => {
    console.log("details",details);
    
    navigate(`staffDetails/${details.SchoolTeachersQId}`, {
      state: {
        staffDetails: details,
      },
    });
    console.log(details);
  };
  const _onNumberClick = (number, type) => (event) => {
    if (type === "CELL") {
      window.open(`tel:${number}`);
    } else if (type === "WHATSAPP") {
      window.open(`https://wa.me/15551234567`, "_blank").focus();
    }
  };
  const _renderContact = (row) => {
    if (row.Teacher !== null) {
      return (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          {row.Teacher && row.ContactNo !== null ? (
            <Chip
              onClick={_onNumberClick(row.ContactNo, "CELL")}
              icon={<LocalPhoneTwoToneIcon fontSize="small" />}
              label={`CELL: ${row.ContactNo}`}
              variant="outlined"
            />
          ) : (
            "Not Defined"
          )}
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          {row.Cell_No && (
            <Chip
              onClick={_onNumberClick(row.Cell_No, "CELL")}
              icon={<LocalPhoneTwoToneIcon fontSize="small" />}
              label={`Cell: ${row.Cell_No}`}
            />
          )}
          {row.whatsapp_no && (
            <Chip
              onClick={_onNumberClick(row.whatsapp_no, "WHATSAPP")}
              icon={<LocalPhoneTwoToneIcon fontSize="small" />}
              label={`Whatsapp: ${row.whatsapp_no}`}
              variant="outlined"
            />
          )}
        </Stack>
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Full Name</TableCell>
            <TableCell align="center">CNIC</TableCell>
            <TableCell align="center">Designation</TableCell>
            <TableCell align="center">Cell # | Whatsapp #</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPageCRUD.read &&
            staffArray.map((row, index) => (
              <TableRow
                key={String(index)}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.FullName || row.TeacherName || "Not Defined"}
                </TableCell>
                <TableCell align="center">
                  {row.CNIC || row.Teacher?.CNIC || "Not Defined"}
                </TableCell>
                <TableCell align="center">
                  {row.Designation
                    ? row.Designation.TeachersDesignation
                    : "Not Defined"}
                </TableCell>
                <TableCell align="center">{_renderContact(row)}</TableCell>
                <TableCell align="center">
                  {row.Address || "Not Defined"}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={_onDetailsClick(row)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
