import React from "react";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { TextField, Box, MenuItem } from "@mui/material";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../Api/axios.instance";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMemo } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  FullName: null,
  //   CNIC: null,
  //   Designation: null,
  //   Address: null,
  //   Cell_No: "",
  //   whatsapp_no: "",
  //   email: null,
  //   dateofbirth: null,
  //   gender: null,
  //   home_phone_no: null,
  //   Qualification: null,
  //   ProfessionalQualification: null,
  //   exp_years: null,
  //   Basic_Salary: null,
  //   Allowances: null,
  //   Total_salary: null
  FullName: yup.string("Enter Full Name").required("Full Name is required"),
  whatsapp_no: yup
    .string("Enter Whatsapp No")
    .required("Whatsapp No is required"),
  CNIC: yup.string("Enter CNIC No").required("CNIC No is required"),
  dateofbirth: yup
    .string("Set date of birth")
    .required("Date of birth is required"),
  email: yup
    .string("Enter Email Address")
    .required("Email Address is required"),
  gender: yup.string("Select Gender").required("Gender is required"),
  Basic_Salary: yup
    .string("Enter Basic Salary")
    .required("Basic Salary is required"),
  Allowances: yup.string("Enter Allowances").required("Allowances is required"),
});

const _addStaff = async (data) => {
  try {
    const { data: reponse, status } = await axiosInstance.post("/staff", data, {
      headers: { "Content-Type": "application/json" },
    });
    if (status === 200) {
      return reponse;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
const CNICInput = React.forwardRef(function CNICInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="00000-0000000-0"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CNICInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AddStaff({ getStaff }) {
  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  useEffect(() => {
    getStaff();
  }, []);
  // const [formData, setFormData] = useState({
  //   FullName: null,
  //   CNIC: null,
  //   Designation: null,
  //   Address: null,
  //   Cell_No: "",
  //   whatsapp_no: "",
  //   email: null,
  //   dateofbirth: null,
  //   gender: null,
  //   home_phone_no: null,
  //   Qualification: null,
  //   ProfessionalQualification: null,
  //   exp_years: null,
  //   Basic_Salary: null,
  //   Allowances: null,
  //   Total_salary: null
  // });
  const [open, setOpen] = useState(false);
  const [BasicSalary, setBasicSalary] = useState(0);
  const [Allowances, setAllowances] = useState(0);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      FullName: "",
      CNIC: 0,
      Designation: "",
      Address: "",
      Cell_No: "",
      whatsapp_no: "",
      email: "",
      dateofbirth: dayjs(),
      gender: "",
      home_phone_no: "",
      Qualification: "",
      ProfessionalQualification: "",
      exp_years: "",
      Basic_Salary: 0,
      Allowances: 0,
      Total_salary: 0,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      const newStaff = await _addStaff(formData);
      console.log(newStaff);

      if (newStaff.hasOwnProperty("success")) {
        await getStaff();
        setOpen(true);
      } else {
        alert("An Error Has Occured");
      }
    },
  });

  // const _handleOnChange = (e) => {
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       [e.target.name]: e.target.value,
  //     };
  //   });
  // };
  // const _SubmitForm = async (e) => {
  //   e.preventDefault();
  //   const newStaff = await _addStaff(formData);
  //   console.log(newStaff)

  //   if (newStaff.hasOwnProperty('success')) {
  //     setOpen(true);
  //   } else {
  //     alert('An Error Has Occured')
  //   }
  // }
  const _handleDOB = (e) => {
    formik.setFieldValue("dateofbirth", e.$d);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setOpen(false);
  };
  const _handleBasicSalaryCHange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setBasicSalary(e.target.value);
  };
  const _handleAllowanceChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setAllowances(e.target.value);
  };
  useMemo(() => {
    formik.setFieldValue(
      "Total_salary",
      Number(BasicSalary) + Number(Allowances)
    );
  }, [BasicSalary, Allowances]);
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <TextField
          size="small"
          label="Full Name"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="FullName"
          error={formik.touched.FullName && Boolean(formik.errors.FullName)}
          helperText={formik.touched.FullName && formik.errors.FullName}
          value={formik.values.FullName}
          onChange={formik.handleChange}
        />
        {/* <Input
          name="CNIC"
          placeholder="CNIC"
          id="formatted-text-mask-input"
          inputComponent={CNICInput}
          sx={{ display: "flex", flex: 1 }}
          onChange={handleCNICChange}
          // value={formik.CNIC}
        /> */}
        <TextField
          size="small"
          label="CNIC"
          variant="outlined"
          placeholder="CNIC"
          sx={{ display: "flex", flex: 1 }}
          name="CNIC"
          error={formik.touched.CNIC && Boolean(formik.errors.CNIC)}
          helperText={formik.touched.CNIC && formik.errors.CNIC}
          onChange={formik.handleChange}
          value={formik.values.CNIC}
        />
        <TextField
          size="small"
          label="Designation"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Designation"
          error={
            formik.touched.Designation && Boolean(formik.errors.Designation)
          }
          helperText={formik.touched.Designation && formik.errors.Designation}
          value={formik.values.Designation}
          onChange={formik.handleChange}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          size="small"
          label="Address"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Address"
          error={formik.touched.Address && Boolean(formik.errors.Address)}
          helperText={formik.touched.Address && formik.errors.Address}
          value={formik.values.Address}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          type="number"
          label="Cell #"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Cell_No"
          error={formik.touched.Cell_No && Boolean(formik.errors.Cell_No)}
          helperText={formik.touched.Cell_No && formik.errors.Cell_No}
          value={formik.values.Cell_No}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          type="number"
          label="Whatsapp #"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="whatsapp_no"
          error={
            formik.touched.whatsapp_no && Boolean(formik.errors.whatsapp_no)
          }
          helperText={formik.touched.whatsapp_no && formik.errors.whatsapp_no}
          value={formik.values.whatsapp_no}
          onChange={formik.handleChange}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="Date of Birth"
            inputFormat="MM/DD/YYYY"
            error={
              formik.touched.dateofbirth && Boolean(formik.errors.dateofbirth)
            }
            helperText={formik.touched.dateofbirth && formik.errors.dateofbirth}
            value={formik.values.dateofbirth}
            onChange={_handleDOB}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
              />
            )}
          />
        </LocalizationProvider>
        <TextField
          size="small"
          label="Email"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          id="outlined-select-currency"
          select
          label="Gender"
          sx={{ display: "flex", flex: 1 }}
          //   value={currency}
          //   onChange={handleChange}
          variant="outlined"
          name="gender"
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          value={formik.values.gender}
          onChange={formik.handleChange}
          defaultValue="Select Gender"
        >
          <MenuItem key={"0"} value={"Select Gender"}>
            Select Gender
          </MenuItem>
          <MenuItem key={"1"} value={"MALE"}>
            MALE
          </MenuItem>
          <MenuItem key={"2"} value={"FEMALE"}>
            FEMALE
          </MenuItem>
        </TextField>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          size="small"
          type="number"
          label="Home #"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="home_phone_no"
          error={
            formik.touched.home_phone_no && Boolean(formik.errors.home_phone_no)
          }
          helperText={
            formik.touched.home_phone_no && formik.errors.home_phone_no
          }
          value={formik.values.home_phone_no}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          label="Qualification"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Qualification"
          error={
            formik.touched.Qualification && Boolean(formik.errors.Qualification)
          }
          helperText={
            formik.touched.Qualification && formik.errors.Qualification
          }
          value={formik.values.Qualification}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          label="Professional Qualification"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="ProfessionalQualification"
          error={
            formik.touched.ProfessionalQualification &&
            Boolean(formik.errors.ProfessionalQualification)
          }
          helperText={
            formik.touched.ProfessionalQualification &&
            formik.errors.ProfessionalQualification
          }
          value={formik.values.ProfessionalQualification}
          onChange={formik.handleChange}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <TextField
          size="small"
          type="number"
          label="Experience in Years"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="exp_years"
          error={formik.touched.exp_years && Boolean(formik.errors.exp_years)}
          helperText={formik.touched.exp_years && formik.errors.exp_years}
          value={formik.values.exp_years}
          onChange={formik.handleChange}
        />
        <TextField
          size="small"
          type="number"
          label="Basic Salary"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Basic_Salary"
          error={
            formik.touched.Basic_Salary && Boolean(formik.errors.Basic_Salary)
          }
          helperText={formik.touched.Basic_Salary && formik.errors.Basic_Salary}
          value={formik.values.Basic_Salary}
          onChange={_handleBasicSalaryCHange}
        />
        <TextField
          size="small"
          type="number"
          label="Allowance"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Allowances"
          error={formik.touched.Allowances && Boolean(formik.errors.Allowances)}
          helperText={formik.touched.Allowances && formik.errors.Allowances}
          value={formik.values.Allowances}
          onChange={_handleAllowanceChange}
        />
        <TextField
          size="small"
          id="filled-read-only-input"
          InputProps={{
            readOnly: true,
          }}
          type="number"
          label="Total Salary"
          variant="outlined"
          sx={{ display: "flex", flex: 1 }}
          name="Total_salary"
          error={
            formik.touched.Total_salary && Boolean(formik.errors.Total_salary)
          }
          helperText={formik.touched.Total_salary && formik.errors.Total_salary}
          value={formik.values.Total_salary}
          // onChange={formik.handleChange}
        />
      </Stack>
      <Box sx={{ mt: 3 }}>
        <LoadingButton variant="contained" fullWidth type="Submit">
          Save
        </LoadingButton>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Staff has been added.
        </Alert>
      </Snackbar>
    </Box>
  );
}
