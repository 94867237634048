import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'


export default function Schools({ schools }) {
  const navigate = useNavigate();

  const _onDetailsClick = (details) => (event) => {
    navigate('/clusters/school/' + details.SchoolId, {
      state: {
        schoolData: details
      }
    });
    // console.log(details.SchoolId)
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={250}>School Name</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Registered</TableCell>
            <TableCell align="left"># Rooms</TableCell>
            <TableCell align="left">Onwership Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schools.map((row, index) => (
            <TableRow key={String(index)} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.NameOfSchool}
              </TableCell>
              <TableCell align="left">{row.Address}</TableCell>
              <TableCell align="left">{row.Registered}</TableCell>
              <TableCell align="left">{row.NoOfRooms}</TableCell>
              <TableCell align="left">{row.OwnerShipStatus}</TableCell>
              <TableCell align="center">
                <Button variant="contained" onClick={_onDetailsClick(row)}>View Details</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
