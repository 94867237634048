import React from "react";
import { DrawerHeader, Header, Sidebar } from "./Components/Layout";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { StyleProvider, styles } from "./Styles";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute";
// import StaticSideBar from "./Components/Layout/StaticSideBar";

function App() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className="App">
      <ProtectedRoute>
        <StyleProvider theme={styles}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Header
              open={true}
              appBarOpen={handleDrawerOpen}
              appBarClose={handleDrawerClose}
            />
            <Sidebar
              open={true}
              appBarOpen={handleDrawerOpen}
              appBarClose={handleDrawerClose}
            />
            {/*<StaticSideBar
              open={true}
              appBarOpen={handleDrawerOpen}
              appBarClose={handleDrawerClose}
            />*/}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              <Outlet />
            </Box>
          </Box>
        </StyleProvider>
      </ProtectedRoute>
    </div>
  );
}

export default App;
