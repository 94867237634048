import React from "react";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { TextField, Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../Api/axios.instance";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function addUser(post) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.post("/users", post);
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function AddUser({ staff, clusters, roles, refetch }) {
  const [loading, setLoading] = useState(false);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [selection, setSelection] = useState({
    accessLevel: "ADMIN",
    role: "",
    cluster: "",
    school: "",
    staffId: "",
    username: "",
    password: "",
  });
  const [schools, setSchools] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [snack, setSnack] = React.useState({
    open: false,
    message: "",
    type: "success",
  });
  const handleChange = (e) => {
    setSelection((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    if (e.target.name === "cluster") {
      setSelection((prevState) => {
        return {
          ...prevState,
          school: "",
        };
      });
      const schools = clusters.filter(
        (item) => item.RegionId === e.target.value
      );
      setSchools(schools[0].School);
    }
  };
  const _renderSchools = () => {
    if (
      schools.length > 0 &&
      selection.accessLevel !== "ADMIN" &&
      selection.accessLevel !== "CLUSTER"
    ) {
      return (
        <FormControl sx={{ display: "flex", flex: 1 }}>
          <InputLabel id="demo-simple-select-label-school">
            Select School
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-school"
            id="demo-simple-select"
            value={selection.school}
            label="Select Access Level"
            onChange={handleChange}
            name="school"
          >
            {schools.map((school, index) => (
              <MenuItem key={String(index)} value={school.SchoolId}>
                {school.NameOfSchool}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };
  const _onFormSubmit = async (e) => {
    e.preventDefault();
    const post = {
      accessLevel: selection.accessLevel,
      roleId: selection.role,
      username: selection.username,
      password: selection.password,
      teacherId: selection.staffId === "" ? "" : selection.staffId,
      clusterId: selection.cluster === "" ? "" : selection.cluster,
      schoolId: selection.school === "" ? "" : selection.school,
    };
    setLoading((prevState) => !prevState);
    console.log("post", post);
    try {
      const response = await addUser(post);
      if (response.success) {
        setSnack((prevState) => {
          return {
            open: true,
            type: "success",
            message: "User has been added successfully",
          };
        });
        setSelection({
          accessLevel: "ADMIN",
          role: "",
          cluster: "",
          school: "",
          staffId: "",
          username: "",
          password: "",
        });
        setLoading((prevState) => !prevState);
      }
      refetch();
    } catch (error) {
      setSnack((prevState) => {
        return {
          open: true,
          type: "error",
          message:
            error.response.data.message ||
            "There was an error, please contact administrator.",
        };
      });
      setLoading((prevState) => !prevState);
    }
  };
  const handleSnakClose = () => {
    setSnack((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };
  return (
    <Box component="form" onSubmit={_onFormSubmit}>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <FormControl sx={{ display: "flex", flex: 1 }}>
          <InputLabel id="demo-simple-select-label">
            Select Access Level
          </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="accessLevel"
            value={selection.accessLevel}
            label="Select Access Level"
            onChange={handleChange}
          >
            <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
            <MenuItem value={"CLUSTER"}>CLUSTER</MenuItem>
            <MenuItem value={"SCHOOL"}>SCHOOL</MenuItem>
            <MenuItem value={"STAFF"}>STAFF</MenuItem>
            {/* <MenuItem value={"TEACHER"}>TEACHER</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl sx={{ display: "flex", flex: 1 }}>
          <InputLabel id="demo-simple-select-label-role">
            Select Role
          </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label-role"
            id="demo-simple-select"
            value={selection.role}
            name="role"
            label="Select Access Level"
            onChange={handleChange}
          >
            {roles.map((role, index) => (
              <MenuItem key={String(index)} value={role.RoleId}>
                {role.RoleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {selection.accessLevel !== "ADMIN" && (
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          sx={{ mb: 2 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
        >
          <FormControl sx={{ display: "flex", flex: 1 }}>
            <InputLabel id="demo-simple-select-label-cluster">
              Select Custer
            </InputLabel>
            <Select
              required={selection.accessLevel !== "ADMIN"}
              labelId="demo-simple-select-label-cluster"
              id="demo-simple-select"
              value={selection.cluster}
              label="Select Access Level"
              onChange={handleChange}
              name="cluster"
            >
              {clusters.map((cluster, index) => (
                <MenuItem key={String(index)} value={cluster.RegionId}>
                  {cluster.RegionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {_renderSchools()}
        </Stack>
      )}
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        sx={{ mb: 2 }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <TextField
          required
          label="Username"
          variant="standard"
          sx={{ display: "flex", flex: 1 }}
          name="username"
          onChange={handleChange}
        />
        <FormControl sx={{ display: "flex", flex: 1 }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
      <Box sx={{ mt: 3 }}>
        {currentPageCRUD.create && (
          <LoadingButton
            variant="contained"
            fullWidth
            type="Submit"
            loading={loading}
            disabled={loading}
          >
            Save
          </LoadingButton>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnakClose}
      >
        <Alert
          onClose={handleSnakClose}
          severity={snack.type}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
