import React, { useEffect, useState } from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const SevenDaysAttendance = ({ attendance }) => {
  const [presentcount, setPresentcount] = useState([]);
  const [dates, setDates] = useState([]);
  const [colors, setColors] = useState([]);
  useEffect(() => {
    console.log("attendance ==>", attendance);
    console.log("presentcount ==>", presentcount);
    console.log("dates ==>", dates);
    console.log("colors ==>", colors);
    if (attendance.length > 0) {
      setPresentcount(attendance.map((item) => item.presentcount));
      setDates(
        attendance.map((item) => dayjs(item.att_date).format("YYYY-MM-DD"))
      );
      setColors(attendance.map((item) => item.COLORCODE));
    }
  }, [attendance]);
  const options = {
    responsive: true,
    plugins: {
      datalabels:{
        color: "#000000",
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Last 7 days Attendance Chart",
      },
    },
  };
  const labels = dates;
  const data = {
    labels,
    datasets: [
      {
        label: "Present Students",
        data: presentcount,
        backgroundColor: colors,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default SevenDaysAttendance;
