import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useFormik } from "formik";
import * as yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  SchoolID: yup.string("Enter School").required("School is required"),
  TeacherID: yup.string("Enter Teacher").required("Teacher is required"),
  ArrivalDatetime: yup
    .string("Enter Arrival Date Time")
    .required("Arrival Date Time is required"),
  DepartureDateTime: yup
    .string("Enter Departure Date Time")
    .required("Departure Date Time is required"),
});

export default function Participant() {
  const { state: locationState } = useLocation();
  const { Cluster, WorkshopID } = locationState;
  const [loading, setLoading] = useState(false);
  const { currentPage } = useSession();
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const { accesLevel, access } = useSession();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  console.log("Cluster", Cluster);
  console.log("WorkshopID", WorkshopID);
  useEffect(() => {
    _fetchParticipants();
    if (Object.keys(Cluster).length > 0) {
      setSchools(Cluster.School);
    }
  }, [WorkshopID]);

  useEffect(() => {
    console.log("PARTICIPANTS", participants);
  }, [participants]);

  const formik = useFormik({
    initialValues: {
      SchoolID: "",
      TeacherID: "",
      ArrivalDatetime: dayjs().format("YYYY-MM-DD"),
      DepartureDateTime: dayjs().format("YYYY-MM-DD"),
      Remarks: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
        console.log("formData", formData);
      }
      try {
        if (isEdit) {
          setLoading((prevState) => !prevState);
          const { data } = await axiosInstance.put(
            `/workshops/participants/${selectedParticipant.WS_DetailID}`,
            formData
          );
          console.log("DATA", data);
          if (Object.keys(data).length > 0) {
            setSnackOpen(true);
            setOpen(false);
            setIsEdit(false);
            setLoading((prevState) => !prevState);
            resetForm();
            _fetchParticipants();
          } else {
            setSnackOpen(false);
            setOpen(true);
            setLoading((prevState) => !prevState);
          }
          console.log("Is Edit", isEdit);
        } else {
          setLoading((prevState) => !prevState);
          const { data } = await axiosInstance.post(
            `/workshops/participant/${WorkshopID}`,
            formData
          );
          console.log("DATA", data);
          if (Object.keys(data).length > 0) {
            setSnackOpen(true);
            setOpen(false);
            setLoading((prevState) => !prevState);
            resetForm();
            _fetchParticipants();
          } else {
            setSnackOpen(false);
            setOpen(true);
            setLoading((prevState) => !prevState);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const _fetchParticipants = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/workshops/participants/${WorkshopID}`
      );
      if (data.data.length > 0) {
        setParticipants(data.data);
      } else {
        setParticipants([]);
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };
  const _handleSchoolChange = async (e) => {
    formik.setFieldValue("SchoolID", e.target.value);
    try {
      if (isEdit) {
        const { data } = await axiosInstance.get(
          `/teachers/by-school/${e.target.value}`
        );
        console.log("Teachers", data.data);
        if (Object.keys(data).length > 0) {
          setTeachers(data.data);
        }
      } else {
        const { data } = await axiosInstance.get(
          `/teachers/by-school/${e.target.value}`
        );
        console.log("Teachers", data.data);
        if (Object.keys(data).length > 0) {
          setTeachers(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleDOA = (e) => {
    formik.setFieldValue("ArrivalDatetime", dayjs(e));
  };
  const _handleDOD = (e) => {
    formik.setFieldValue("DepartureDateTime", dayjs(e));
  };
  const handleClick = (event, participant) => {
    console.log("event", event);
    setIsEdit(false);
    setAnchorEl(event.currentTarget);
    setSelectedParticipant(participant);
  };
  const handleOpen = () => {
    setIsEdit(false);
    setOpen(true);
    for (let value in formik.values) {
      if (value === "ArrivalDatetime" || value === "DepartureDateTime") {
        formik.setFieldValue(value, dayjs(value).format("YYYY-MM-DD"));
      } else {
        formik.setFieldValue(value, "");
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const _onEdit = async () => {
    setIsEdit(true);
    setOpen(true);
    for (let value in formik.values) {
      if (value === "ArrivalDatetime" || value === "DepartureDateTime") {
        formik.setFieldValue(value, dayjs(selectedParticipant[value]));
      } else {
        formik.setFieldValue(value, selectedParticipant[value]);
      }
    }
    try {
      const { data } = await axiosInstance.get(
        `/teachers/by-school/${selectedParticipant.SchoolID}`
      );
      console.log("Teachers", data.data);
      if (Object.keys(data).length > 0) {
        setTeachers(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onDelete = async () => {
    console.log("selectedParticipant", selectedParticipant);
    try {
      const { data } = await axiosInstance.delete(
        `/workshops/participants/${selectedParticipant.WS_DetailID}`
      );
      console.log("DATA", data);
      if (Object.keys(data).length > 0) {
        setDeleteSnackOpen(true);
        _fetchParticipants();
      } else {
        setDeleteSnackOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const deleteSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setDeleteSnackOpen(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Participant Management Module
            </Typography>
            <Typography variant="subtitle2" component="span">
              Manage Participant for respective classes and their subjects.
              Select a Class to continue.
            </Typography>
          </Stack>
          <Box width={300}>
            {accesLevel === "SCHOOL" && (
              <FormControl fullWidth>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Create Participant
                </LoadingButton>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Participant</TableCell>
                  <TableCell align="center">Baithak School</TableCell>
                  <TableCell align="center">Arrival</TableCell>
                  <TableCell align="center">Departure</TableCell>
                  <TableCell align="center">
                    Remarks by Training Incharge
                  </TableCell>
                  {accesLevel === "SCHOOL" && (
                    <TableCell align="right">Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {participants.length > 0 &&
                  participants.map((participant, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {participant.Teacher.TeacherName}
                      </TableCell>
                      <TableCell align="center">
                        {participant.School.NameOfSchool}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(participant.ArrivalDatetime).format(
                          "DD-MM-YYYY - HH:mm"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(participant.DepartureDateTime).format(
                          "DD-MM-YYYY - HH:mm"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {participant.Remarks}
                      </TableCell>
                      {accesLevel === "SCHOOL" && (
                        <TableCell align="right">
                          <IconButton
                            onClick={(e) => {
                              console.log(participant);
                              handleClick(e, participant);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {accesLevel === "SCHOOL" && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={_onDelete} color="error">
                Delete
              </MenuItem>

              <MenuItem onClick={_onEdit}>Edit</MenuItem>
            </Menu>
          )}
        </Stack>
      </Box>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Create Participant</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box flexGrow={1}>
              <Divider sx={{ mt: 2, mb: 2 }} />

              <Box>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select School"
                      sx={{ display: "flex", flex: 1 }}
                      value={formik.values.SchoolID}
                      variant="outlined"
                      name="SchoolID"
                      required
                      onChange={_handleSchoolChange}
                      error={
                        formik.touched.SchoolID &&
                        Boolean(formik.errors.SchoolID)
                      }
                      helperText={
                        formik.touched.SchoolID && formik.errors.SchoolID
                      }
                      size="small"
                    >
                      {schools?.map((school, index) => {
                        return (
                          <MenuItem key={index} value={school.SchoolId}>
                            {school.NameOfSchool}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Teacher"
                      sx={{ display: "flex", flex: 1 }}
                      value={formik.values.TeacherID}
                      variant="outlined"
                      name="TeacherID"
                      required
                      onChange={formik.handleChange}
                      error={
                        formik.touched.TeacherID &&
                        Boolean(formik.errors.TeacherID)
                      }
                      helperText={
                        formik.touched.TeacherID && formik.errors.TeacherID
                      }
                      size="small"
                    >
                      {teachers?.map((teacher, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={teacher.SchoolTeachersQId}
                          >
                            {teacher.TeacherName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Date of Arrival"
                        value={formik.values.ArrivalDatetime}
                        onChange={_handleDOA}
                        name="ArrivalDatetime"
                        error={
                          formik.touched.ArrivalDatetime &&
                          Boolean(formik.errors.ArrivalDatetime)
                        }
                        helperText={
                          formik.touched.ArrivalDatetime &&
                          formik.errors.ArrivalDatetime
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                        // ampm={ampm}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Date of Departure"
                        name="DepartureDateTime"
                        value={formik.values.DepartureDateTime}
                        onChange={_handleDOD}
                        error={
                          formik.touched.DepartureDateTime &&
                          Boolean(formik.errors.DepartureDateTime)
                        }
                        helperText={
                          formik.touched.DepartureDateTime &&
                          formik.errors.DepartureDateTime
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            required
                            {...params}
                          />
                        )}
                        // ampm={ampm}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Remarks by Training Incharge"
                      name="Remarks"
                      value={formik.values.Remarks}
                      onChange={formik.handleChange}
                      style={{ width: "100%", padding: 15 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      disabled={loading}
                      // onClick={_editSyllabus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {isEdit ? "Update Participant" : "Create Participant"}
                    </LoadingButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}></Grid>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {isEdit
            ? "Participant has been Updated."
            : "Participant has been Added."}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={deleteSnackOpen}
        autoHideDuration={6000}
        onClose={deleteSnack_handleClose}
      >
        <Alert
          onClose={deleteSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Participant has been Deleted.
        </Alert>
      </Snackbar>
    </>
  );
}
