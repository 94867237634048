import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ActiveSchoolCount = ({ activeSchoolCount }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Ownership Status",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    if (activeSchoolCount[0].length > 0) {
      console.log(activeSchoolCount);
      let labelsArray = [];
      let dataArray = [];
      activeSchoolCount[0].forEach((element) => {
        labelsArray.push(element.OwnershipStatus);
        dataArray.push(element.TotalNumber);
      });

      const backgroundColor = [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ];
      const borderColor = [
        "rgba(54, 162, 235, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ];

      setChartData({
        labels: labelsArray,
        datasets: [
          {
            label: "Ownership Status",
            data: dataArray,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
          },
        ],
      });
    }
  }, [activeSchoolCount]);

  const options = {
    plugins: {
      datalabels: {
        color: "#000000",
      },
    },
  };

  return <Pie data={chartData} options={options} />;
};

export default ActiveSchoolCount;
