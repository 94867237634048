import React from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Button,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
  Snackbar,
  Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { getClasses } from "../ClassSubjects/Classes";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import Alert from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { getStudents } from "../Students/Students";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

function fetchStudents(schoolId, classId) {
  return new Promise(async (res, rej) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/students/by-school-class/${schoolId}/${classId}`
      );
      if (status === 200) {
        res(data.data);
      } else {
        res(null);
      }
    } catch (error) {
      rej(error);
    }
  });
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const SectionDivider = ({ text, changeDate }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography
        component="span"
        variant="subtitle2"
        sx={{ mx: 1, display: "flex", alignItems: "center" }}
      >
        {text}
        <IconButton onClick={changeDate} size="small">
          <CalendarTodayOutlinedIcon fontSize="10" />
        </IconButton>
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

const AlertSnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
export default function Attendance() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackEditOpen, setSnackEditOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [dateOpen, setDateOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(false);
  const [selectedStudentAttendance, setSelectedStudentAttendance] =
    React.useState({});
  const [classes, setClasses] = React.useState([]);
  const [classesAttendance, setClassesAttendance] = React.useState([]);
  const [loadingClass, setLoadingClass] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [students, setStudents] = React.useState([]);
  const [selectStudents, setSelectStudents] = React.useState([]);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [attendanceData, setAttendanceData] = React.useState([]);
  const [attendance, setAttendance] = React.useState([]);
  const [today, setToday] = React.useState(dayjs());
  const { currentPage } = useSession();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBox = Boolean(anchorEl);
  const [currentPageCRUD] = React.useState(
    currentPage[0] ? currentPage[0] : ""
  );
  const { accesLevel, access } = useSession();
  React.useEffect(() => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    _fetchAttendance(currentDate);
    _fetchClasses();
    _fetchSelectStudents();
  }, []);

  React.useEffect(() => {
    console.log("classesAttendance=>", classesAttendance);
  }, [classesAttendance]);

  React.useEffect(() => {
    if (selectedClass !== "") {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        _fetchStudents(schoolId, selectedClass);
      }
    }
    console.log("selectedClass", selectedClass);
  }, [selectedClass]);
  const _fetchAttendance = async (date) => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        searchParams.append("date", date);
        const { status, data } = await axiosInstance.get(
          "/attendance/current-date",
          {
            params: searchParams,
          }
        );
        console.log("all data", data.data);
        const classData = data.data.map((item) => {
          return {
            index: item.Student.ClassModel && item.Student.ClassModel.indexId,
            class_id: item.Student.ClassModel && item.Student.ClassModel.ID,
            class_name:
              item.Student.ClassModel && item.Student.ClassModel.ClassName,
            current_date: dayjs(item.Att_date).format("YYYY-MM-DD"),
          };
        });
        const classWithoutDuplication = classData.filter(
          (ele, i) =>
            i ===
            classData.findIndex(
              (elem) =>
                elem.class_id === ele.class_id &&
                elem.index === ele.index &&
                elem.class_name === ele.class_name
            )
        );
        const sortedClass = classWithoutDuplication.sort(
          (a, b) => a.index - b.index
        );
        console.log("classData", classData);
        console.log("classWithoutDuplication", classWithoutDuplication);
        console.log("sortedClass", sortedClass);
        const classWiseAttendanceData = sortedClass.map((x) => {
          return {
            ...x,
            attendance: data.data.filter(
              (y) => y.Student.ClassModel.ID === x.class_id
            ),
          };
        });
        console.log("classWiseAttendanceData", classWiseAttendanceData);
        setClassesAttendance(classWiseAttendanceData);
        setAttendanceData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchSelectStudents = async () => {
    try {
      const schoolId =
        localStorage.getItem("UserAccess") !== null
          ? JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId
          : 0;
      const response = await getStudents(schoolId);
      setSelectStudents(response.data);
      console.log("selectStudents", selectStudents);
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchStudents = async (schoolId, classId) => {
    setLoadingClass(true);
    try {
      const response = await fetchStudents(schoolId, classId);
      console.log("Response before if", response);
      if (response !== null) {
        console.log("Response in if", response);
        setStudents(response);
        setAttendance(
          response.map((student) => {
            return {
              name: student.StudentName,
              father: student.FathersName,
              class_id: classId,
              school_id: schoolId,
              id: student.SID,
              present: true,
              date: today.format("YYYY-MM-DD"),
            };
          })
        );
      } else {
        alert("no students found...");
      }
      setLoadingClass(false);
    } catch (error) {
      console.log(error);
      setLoadingClass(false);
    }
  };
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateClickOpen = () => {
    setDateOpen(true);
  };

  const handleDateClose = () => {
    setDateOpen(false);
  };
  const handleClick = (item) => (event) => {
    console.log("ITEM", item);
    setAnchorEl(event.currentTarget);
    setSelectedStudentAttendance(item);
    // returenedItem(item);
  };
  const handleCloseBox = () => {
    setAnchorEl(null);
  };
  const handleSelectedDateOpen = () => {
    setSelectedDate(true);
  };

  const handleSelectedDateClose = () => {
    setSelectedDate(false);
  };
  const handleEditToggle = (item) => (event) => {
    setSelectedStudentAttendance((prevState) => {
      return {
        ...prevState,
        Present: !item.Present,
      };
    });
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleChange = (e) => {
    setSelectedClass(e.target.value);
  };
  const handleToggle = (id) => (event) => {
    setAttendance((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            present: !item.present,
          };
        }
        return item;
      });
    });
  };
  const _submitAttendance = async () => {
    setLoading((prevState) => !prevState);
    try {
      const class_id = attendance.filter(
        (ele, i) =>
          i === attendance.findIndex((elem) => elem.class_id === ele.class_id)
      )[0].class_id;
      console.log("class_id", class_id);
      const isClassHasAttendance = classesAttendance.find(
        (item) => item.class_id === class_id
      );
      console.log("classesAttendance", classesAttendance);
      console.log("isClassHasAttendance", isClassHasAttendance);
      console.log("attendance", attendance);
      if (isClassHasAttendance) {
        setLoading((prevState) => !prevState);
        console.log("ATTENDANCE IN IF");
        setSnackOpen(true);
        return;
      } else if (!isClassHasAttendance) {
        // setLoading((prevState) => !prevState);
        const date = attendance[0].date;
        console.log("ATTENDANCE IN ELSE IF");
        const { status, data } = await axiosInstance.post(
          "attendance/",
          attendance
        );
        if (status === 200) {
          setLoading((prevState) => !prevState);
          setShowSuccess(true);
          _fetchAttendance(date);
          handleClose();
        }
      } else {
        setLoading((prevState) => !prevState);
        console.log("ATTENDANCE IN ELSE");
        console.log("Both conditions are failed!");
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();
    console.log("selectedStudentAttendance ==>", selectedStudentAttendance);
    try {
      const { status, data } = await axiosInstance.put(
        `/attendance/${selectedStudentAttendance.id}`,
        selectedStudentAttendance
      );
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackEditOpen(true);
        _fetchAttendance();
        handleEditClose();
        handleCloseBox();
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const _deleteAttendance = async (attendanceArray, deleteArray,classIndex) => {
    try {
      let ids = [];
      console.log(deleteArray)
      deleteArray.forEach((element) => {
        ids.push(attendanceArray[element.index].id);
      });
      console.log("row=>", ids);
      const { status, data } = await axiosInstance.delete(`/attendance`, {
        data: { ids }, // Pass ids in the `data` field
      });
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackEditOpen(true);
        const updatedAttendance = attendanceArray.filter(
          (row) => !ids.includes(row.id)
        );
        let classatt=classesAttendance.map((classItem, index) =>
          index === classIndex
            ? { ...classItem, attendance: updatedAttendance }
            : classItem
        )
        console.log(classatt)
        // Update the specific class in the classesAttendance state
        setClassesAttendance((prevClassesAttendance) =>
          prevClassesAttendance.map((classItem, index) =>
            classItem.index === classIndex
              ? { ...classItem, attendance: updatedAttendance }
              : classItem
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const snack_handleEditClose = () => {
    console.log("Snack bar Closed");
    setSnackEditOpen(false);
  };

  const handleEdit = (rowData) => {
    setSnackMessage(rowData);
    setOpenEdit(true);
    setSelectedStudentAttendance(rowData);
  };

  const columns = [
    {
      label: "Student",
      name: "Student.StudentName",
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      label: "Father",
      name: "Student.FathersName",
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      label: "Class",
      name: "Student.ClassModel.ClassName",
      options: {
        customBodyRender: (value) => value,
      },
    },
    { label: "Date", name: "Att_date" },
    {
      name: "Present",
      label: "Attendance",
      options: {
        customBodyRender: (value) =>
          value ? (
            <Chip sx={{ ml: 1 }} label="P" color="success" />
          ) : (
            <Chip sx={{ ml: 1 }} label="A" color="error" />
          ),
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, meta) => (
          <>
            {/* Edit Icon */}
            <IconButton
              aria-label="edit"
              onClick={() => {
                console.log("ROWDATA");
                console.log(value);
                console.log(meta);
                handleEdit(classesAttendance[value].attendance[meta.rowIndex]);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Student Attendance Management Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Click Generate Attendance button to generate a new attendance sheet
          </Typography>
        </Stack>
        {currentPageCRUD.create && (
          <Button variant="contained" onClick={handleClickOpen}>
            Generate Attendance
          </Button>
        )}
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <SectionDivider
        text={`Students Attendance for ${today.format("dddd DD MMM, YYYY")}`}
        changeDate={handleSelectedDateOpen}
      />
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Box sx={{ mt: 2, mb: 2 }}>
        {currentPageCRUD.read && classesAttendance.length > 0 ? (
          classesAttendance.map((classes, formNumber) => (
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              key={classes.class_name}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2">
                  <strong>
                    Attendance of Class {`${classes.class_name}`}
                    <Chip
                      sx={{ ml: 1 }}
                      label={`Date: ${classes.current_date}`}
                      color="success"
                    />
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Paper>
                      <MUIDataTable
                        title="Test List"
                        columns={columns}
                        data={classes.attendance.map((row) => {
                          const attDate = moment.isDate(row.Att_date)
                            ? moment(row.Att_date).format("DD-MM-YYYY")
                            : row.Att_date;

                          return {
                            "Student.StudentName": row.Student
                              ? row.Student.StudentName
                              : "",
                            "Student.FathersName": row.Student
                              ? row.Student.FathersName
                              : "",
                            "Student.ClassModel.ClassName":
                              row.Student && row.Student.ClassModel
                                ? row.Student.ClassModel.ClassName
                                : "",
                            Att_date: attDate,
                            Present: row.Present,
                            actions: formNumber,
                          };
                        })}
                        options={{
                          onRowsDelete: (e) => {
                            _deleteAttendance(classes.attendance, e.data,classes.index);
                          },
                        }}
                        pagination
                        selectableRows
                        onRowClick={(rowData) => {
                          console.log("Clicked Row:", rowData);
                        }}
                      />
                    </Paper>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box component={Paper} sx={{ padding: 1, textAlign: "center" }}>
            <Typography variant="subtitle2" component="span">
              <strong>No Attendance has been added yet!</strong>
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" variant="h6">
          Attendance Sheet
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            textAlign="center"
            variant="subtitle2"
            alignItems="center"
          >
            Attendance for {today.format("dddd DD MMM, YYYY")}{" "}
            <IconButton onClick={handleDateClickOpen} size="small">
              <CalendarTodayOutlinedIcon fontSize="8" />
            </IconButton>
          </DialogContentText>
          {showSuccess && (
            <Alert severity="success" sx={{ mt: 1 }}>
              Attendance has been successfully added.
            </Alert>
          )}
          <Box sx={{ mb: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Class
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={selectedClass}
                label="Select Class"
                onChange={handleChange}
                endAdornment={
                  loadingClass ? <CircularProgress size={20} /> : null
                }
              >
                <MenuItem value="">Select A Class</MenuItem>
                {classes.map((item, index) => (
                  <MenuItem key={String(index)} value={item.ID}>
                    {item.ClassName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2, mb: 1 }}>
            <List sx={{ width: "100%" }}>
              {attendance.map((student, index) => (
                <ListItem key={String(index)}>
                  <ListItemText
                    id="switch-list-label-wifi"
                    primary={`${student.name} - ${student.father}`}
                  />
                  <Switch
                    edge="end"
                    onChange={handleToggle(student.id)}
                    checked={student.present}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-wifi",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={_submitAttendance}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleDateClose} open={dateOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="body2">Select new date</DialogTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 4 }}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Set a new date"
              value={today}
              onChange={(e) => {
                setToday(e);
                handleDateClose();
                _fetchAttendance(dayjs(e).format("YYYY-MM-DD"));
                setAttendance((prevState) => {
                  return prevState.map((item) => {
                    return {
                      ...item,
                      date: dayjs(e).format("YYYY-MM-DD"),
                    };
                  });
                });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Dialog>

      <Dialog
        onClose={handleSelectedDateClose}
        open={selectedDate}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle variant="body2">Select new date</DialogTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 4 }}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Set a new date"
              value={today}
              onChange={(e) => {
                setToday(e);
                handleSelectedDateClose();
                _fetchAttendance(dayjs(e).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Update Student Attendance
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Attendance of Date:{" "}
                  {`${dayjs(selectedStudentAttendance.Att_date).format(
                    "YYYY-MM-DD"
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ mt: 1, mb: 1 }} component={Paper}>
                  <List sx={{ width: "100%" }}>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary={`${
                          selectedStudentAttendance.Student &&
                          selectedStudentAttendance.Student.StudentName
                        } - ${
                          selectedStudentAttendance.Student &&
                          selectedStudentAttendance.Student.FathersName
                        }`}
                      />
                      <Switch
                        edge="end"
                        onChange={handleEditToggle(selectedStudentAttendance)}
                        checked={selectedStudentAttendance.Present}
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
          <Button onClick={_onEdit}>Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <AlertSnack
          onClose={snack_handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          This class already has Attendance of today! more then one attendance
          are not allowed.
        </AlertSnack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackEditOpen}
        autoHideDuration={6000}
        onClose={snack_handleEditClose}
      >
        <AlertSnack
          onClose={snack_handleEditClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </AlertSnack>
      </Snackbar>
    </Box>
  );
}
