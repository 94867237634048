import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Syllabus(props) {
  const navigate = useNavigate();
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [syllabusList, setSyllabusList] = useState([]);
  const [classId, setClassId] = useState("");
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState(null);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [syllabus, setSyllabus] = useState({
    ProposedCompletionDate: dayjs(),
    StartingDate: dayjs(),
    ChaperName: "",
  });

  useEffect(() => {
    _fetchClasses();
  }, []);
  useEffect(() => {
    if (subject !== null) {
      _fetchSyllabus();
    }
  }, [subject]);
  const _fetchSyllabus = async () => {
    const { id } = subject;
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      try {
        const { data } = await axiosInstance.get(
          `/syllabus/by-school-subject/${schoolId}/${id}`
        );
        setSyllabusList(data.data[0].SyllabusDetail);

        console.log("syllabusList", syllabusList);
      } catch (error) {
        console.log(error);
      }
    }
    if (accesLevel === "ADMIN") {
      const schoolID = localStorage.getItem("UserAccess")[0].SchoolId;
      console.log("schoolID", schoolID);
      try {
        const { data } = await axiosInstance.get(
          `/syllabus/by-school-subject/${schoolID}/${id}`
        );
        setSyllabusList(data.data[0].SyllabusDetail);
        console.log("syllabusList", syllabusList);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _fetchClasses = async () => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data } = await axiosInstance.get(
          `/class-subjects/by-school/${schoolId}`
        );
        setClassList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = (item) => (e) => {
    setSubject(item);
    navigate("add", {
      state: {
        subject: item,
        create: currentPageCRUD.create,
        read: currentPageCRUD.read,
        update: currentPageCRUD.update,
        IsDelete: currentPageCRUD.delete,
      },
    });
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClassChange = (e) => {
    setClassId(e.target.value);
    _fetchSubject(e.target.value);
  };
  const _fetchSubject = async (id) => {
    try {
      if (
        accesLevel === "SCHOOL" ||
        accesLevel === "ADMIN" ||
        accesLevel === "CLUSTER"
      ) {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data } = await axiosInstance.get(
          `/class-subjects/by-school-class/${id}/${schoolId}`
        );
        console.log("SubjectList", data.data);
        setSubjectList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onFormValue = (e) => {
    setSyllabus((prevState) => {
      return {
        ...prevState,
        ChaperName: e.target.value,
      };
    });
  };
  const _setDateValue = (type) => (event) => {
    setSyllabus((prevStat) => {
      return {
        ...prevStat,
        [type]: event,
      };
    });
  };
  const _handleOnChange = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        ChaperName: e.target.value,
      };
    });
  };
  const _handlePCD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        ProposedCompletionDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleCD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        CompletionDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleSD = (e) => {
    setSelectedSyllabus((prevState) => {
      return {
        ...prevState,
        StartingDate: dayjs(e.$d).format("YYYY-MM-DD"),
      };
    });
  };
  const _submitSyllabus = async () => {
    const { ChaperName, StartingDate, ProposedCompletionDate } = syllabus;
    if (ChaperName.trim().length === 0) {
      setValidationError(true);
      return;
    }
    setValidationError(false);
    setLoading(true);
    try {
      if (accesLevel === "ADMIN") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const formData = new FormData();
        formData.append("ChaperName", ChaperName);
        formData.append(
          "StartingDate",
          dayjs(StartingDate).format("YYYY-MM-DD")
        );
        formData.append(
          "ProposedCompletionDate",
          dayjs(ProposedCompletionDate).format("YYYY-MM-DD")
        );
        formData.append("SchoolID", schoolId);
        formData.append("SubjectID", subject?.id);
        const { data } = await axiosInstance.post(`/syllabus`, formData);
        console.log(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const _onAccordionChange = (row) => (e) => {
    setSelectedSyllabus(row);
    console.log("selectedSyllabus", selectedSyllabus);
  };
  const _onSyllabusEdit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const { data } = await axiosInstance.put("/sadasd", formData);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Syllabus Management Module
          </Typography>
          <Typography variant="subtitle2" component="span">
            Manage Syllabus for respective classes and their subjects. Select a
            Class to continue.
          </Typography>
        </Stack>
        <Box width={300}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={classId}
              label="Age"
              onChange={handleClassChange}
            >
              <MenuItem value="" disabled></MenuItem>
              {currentPageCRUD.read &&
                classList.map((item, index) => (
                  <MenuItem key={String(index)} value={item.Classid}>
                    {item.ClassModel.ClassName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Box flex={1} display="flex">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageCRUD.read &&
                subjectList.map((row, index) => (
                  <TableRow
                    key={String(index)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Subject.SubjectName}
                    </TableCell>
                    {currentPageCRUD.read && (
                      <TableCell align="right">
                        <Button onClick={handleClickOpen(row)} size="small">
                          Add or View Syllabus
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
            <DialogTitle>
              Syllabus for {subject?.Subject.SubjectName}
            </DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Stack component={Paper} sx={{ mt: 2, mb: 2, p: 2 }}>
                  <Stack>
                    <TextField
                      error={validationError}
                      value={syllabus.ChaperName}
                      onChange={_onFormValue}
                      fullWidth
                      size="small"
                      label="Chapter Name"
                      variant="outlined"
                    />
                  </Stack>
                  <Stack
                    sx={{ mt: 2, mb: 2 }}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          onChange={_setDateValue("StartingDate")}
                          value={syllabus.StartingDate}
                          inputFormat="DD-MM-YYYY"
                          label="Starting Date"
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <Box sx={{ m: 1 }} />
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          onChange={_setDateValue("ProposedCompletionDate")}
                          value={syllabus.ProposedCompletionDate}
                          inputFormat="DD-MM-YYYY"
                          label="Proposed Completion Date"
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ mt: 1 }}>
                    <LoadingButton
                      loading={loading}
                      onClick={_submitSyllabus}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Add new
                    </LoadingButton>
                  </Stack>
                </Stack>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box display="flex" flex={1} flexDirection="column">
                  {currentPageCRUD.read &&
                    syllabusList.length > 0 &&
                    syllabusList.map((item, index) => (
                      <Accordion
                        key={String(index)}
                        expanded={selectedSyllabus?.id === item.id}
                        onChange={_onAccordionChange(item)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{item?.ChaperName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* <Typography>
                          {selectedSyllabus?.ID} === {item.ID}Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Suspendisse
                          malesuada lacus ex, sit amet blandit leo lobortis
                          eget.
                        </Typography> */}
                          <Box onSubmit={_onSyllabusEdit} component="form">
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  autoFocus
                                  label="Chapter Name"
                                  variant="outlined"
                                  size="small"
                                  sx={{ display: "flex", flex: 1 }}
                                  name="ChaperName"
                                  defaultValue={item?.ChaperName}
                                  onChange={_handleOnChange}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    label="Starting Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={
                                      selectedSyllabus?.StartingDate ?? dayjs()
                                    }
                                    onChange={_handleSD}
                                    renderInput={(params) => (
                                      <TextField
                                        name="StartingDate"
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        sx={{ display: "flex", flex: 1 }}
                                        required
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    label="Completion Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={
                                      selectedSyllabus?.CompletionDate ?? ""
                                    }
                                    onChange={_handleCD}
                                    renderInput={(params) => (
                                      <TextField
                                        name="CompletionDate"
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        sx={{ display: "flex", flex: 1 }}
                                        required
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    label="Propose Complition Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={
                                      selectedSyllabus?.ProposedCompletionDate
                                    }
                                    onChange={_handlePCD}
                                    renderInput={(params) => (
                                      <TextField
                                        name="ProposedCompletionDate"
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        sx={{ display: "flex", flex: 1 }}
                                        required
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <LoadingButton
                                  loading={loading}
                                  type="submit"
                                  // onClick={_editSyllabus}
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                >
                                  Update
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      </Box>
    </Box>
  );
}
