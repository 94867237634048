import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axiosInstance from "../../Api/axios.instance";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

export default function StaffDeatils(params) {
  const { id } = useParams();
  const [staffData, setStaffData] = useState({});
  let { state: locationState, pathname } = useLocation();
  useEffect(() => {
    if (locationState !== undefined) {
      setStaffData(locationState.staffDetails);
    } else {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get(`/staff/${id}`);
          if (status === 200) {
            setStaffData(data.data);
            console.log(staffData);
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      })();
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Card variant="outlined">
        <CardContent>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Staff Name:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.TeacherName ||
                  staffData.Teacher?.TeacherName ||
                  "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Designation:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Designation?.TeachersDesignation ||
                  staffData.Designation?.TeachersDesignation ||
                  "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Address:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Address ? staffData.Address : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                ClassID:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.ClassID ? staffData.ClassID : "Undefined"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Cell No:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.ContactNo ? staffData.ContactNo : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Whatsapp Number:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.ContactNo ? staffData.ContactNo : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Email:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Email ? staffData.Email : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Active:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.active ? "Active" : "Not Active"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Date Of Birth:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.dateofbirth ? staffData.dateofbirth : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Gender:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.gender ? staffData.gender : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Home Phone Number:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.home_phone_no
                  ? staffData.home_phone_no
                  : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Qualification:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.QualificationId
                  ? staffData.QualificationId
                  : "Not Defined"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Professional Qualification:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.QualificationId
                  ? staffData.QualificationId
                  : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Exp Years:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.exp_years ? staffData.exp_years : "Undefined"}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Basic Salary
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Basic_Salary ? staffData.Basic_Salary : "Undefined"}
              </Typography>
            </Box>

            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Allowances:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Allowances ? staffData.Allowances : "Not Defined"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Typography
                sx={{ fontSize: 20 }}
                color="text.primary"
                gutterBottom
              >
                Total Salary:
              </Typography>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                {staffData.Total_salary ? staffData.Total_salary : "Undefined"}
              </Typography>
            </Box>
            {staffData.Teacher ? (
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom
                >
                  First Salary
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {staffData.Teacher.FirstSalary}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {staffData.Teacher ? (
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom
                >
                  CNIC
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {staffData.Teacher.CNIC
                    ? staffData.Teacher.CNIC
                    : "Not Defined"}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {staffData.Teacher ? (
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom
                >
                  Date Joining
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {staffData.Teacher.DateJoining
                    ? dayjs(staffData.Teacher.DateJoining).format("DD-MM-YYYY")
                    : "Not Defined"}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            sx={{ mb: 2 }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            {staffData.Teacher ? (
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom
                >
                  Date Left
                </Typography>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {staffData.Teacher.DateLeft
                    ? dayjs(staffData.Teacher.DateLeft).format("DD-MM-YYYY")
                    : "Not Defined"}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
