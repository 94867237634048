import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getStudents } from "../../Pages/Students/Students";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Transactions(params) {
  const { state: locationState } = useLocation();
  const { BookID, ClassID } = locationState;
  const { accesLevel, access } = useSession();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [editSnackOpen, setEditSnackOpen] = useState(false);
  const [deleteSnackOpen, setDeleteSnackOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState({});
  const [schoolID, setSchoolID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [formData, setFormData] = useState({
    DateofIssue: dayjs().format("YYYY-MM-DD"),
    SID: null,
    BookID: null,
  });
  useEffect(() => {
    _fetchTransactions();
    _fetchStudents();
  }, []);
  const handleClick = (transaction) => (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedTransactions(transaction);
    console.log("transactions", transaction);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        BookID: BookID,
      };
    });
  };
  const _handleOnEditChange = (e) => {
    setSelectedTransactions((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        BookID: BookID,
      };
    });
  };
  const _handleIssueDate = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        DateofIssue: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleEditIssueDate = (e) => {
    setSelectedTransactions((prevState) => {
      return {
        ...prevState,
        DateofIssue: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _updateOnReturn = (id) => async (event) => {
    setTransactions((prevState) => {
      return prevState.map((item, index) => {
        if (item.TrID === id) {
          return {
            ...item,
            DateofReturn: event,
          };
        }
        return item;
      });
    });
  };
  const onAccept = (id) => async (event) => {
    try {
      const { data } = await axiosInstance.put(
        `/book-transactions/update-return/${id}`,
        { DateofReturn: event }
      );
      if (Object.keys(data).length > 0) {
        _fetchTransactions();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchStudents = async () => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      setSchoolID(schoolId);
      try {
        const { data, status } = await axiosInstance.get(
          `/students/by-school-class/${schoolId}/${ClassID}`
        );
        console.log("response", data.data);
        setStudents(data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _fetchTransactions = async () => {
    const accessObject = Object.assign({}, access);
    const schoolId = accessObject[0].SchoolId;
    // setSchoolID(schoolId);
    if (schoolId !== null) {
      try {
        const { status, data } = await axiosInstance.get(
          `/book-transactions/by-book/${schoolId}/${BookID}`
        );
        if (status === 200) {
          setTransactions(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _onDelete = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `/book-transactions/${selectedTransactions.TrID}`,
        selectedTransactions
      );
      if (status === 200) {
        _fetchTransactions();
        setDeleteSnackOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onUpdate = async (e) => {
    e.preventDefault();
    try {
      const { status } = await axiosInstance.put(
        `/book-transactions/${selectedTransactions.TrID}`,
        selectedTransactions
      );
      if (status === 200) {
        _fetchTransactions();
        setEditSnackOpen(true);
        setOpenEdit(false);
      } else {
        setOpenEdit(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const bookTransactionData = {
      BookID: formData.BookID,
      DateofIssue: formData.DateofIssue,
      SID: formData.SID,
      SchoolID: schoolID,
    };
    // console.log("bookTransactionData", bookTransactionData);
    try {
      const { status } = await axiosInstance.post(
        `/book-transactions`,
        bookTransactionData
      );
      if (status === 200) {
        _fetchTransactions();
        setSnackOpen(true);
        setOpen(false);
      } else {
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  const editSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setEditSnackOpen(false);
  };
  const deleteSnack_handleClose = () => {
    console.log("Snack bar Closed");
    setDeleteSnackOpen(false);
  };
  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Transaction Management Module
            </Typography>
            <Typography variant="subtitle2" component="span">
              Manage Transaction for respective classes and their subjects.
              Select a Class to continue.
            </Typography>
          </Stack>
          <Box width={300}>
            <FormControl fullWidth>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Create Transaction
              </Button>
            </FormControl>
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Book Name</TableCell>
                  <TableCell align="center">Student Name</TableCell>
                  <TableCell align="center">Date of issue</TableCell>
                  <TableCell align="center">Date of Return</TableCell>
                  <TableCell align="center">Return Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.map((transaction, index) => {
                  return (
                    <TableRow
                      key={String(index)}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {transaction.Book.NameOfBook}
                      </TableCell>
                      <TableCell align="center">
                        {transaction.Student.StudentName}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(transaction.DateofIssue).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            inputFormat="DD-MM-YYYY"
                            label="Date of Return"
                            value={transaction.DateofReturn ?? ""}
                            onChange={_updateOnReturn(transaction.TrID)}
                            onAccept={onAccept(transaction.TrID)}
                            renderInput={(params) => (
                              <TextField
                                sx={{ display: "flex", flex: 1 }}
                                size="small"
                                required
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align="center">{`${
                        transaction.ReturnStatus ? "Returned" : "Not Return"
                      }`}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={handleClick(transaction)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleAnchorClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={_onDelete} color="error">
              Delete
            </MenuItem>
            <MenuItem onClick={() => setOpenEdit(true)}>Edit</MenuItem>
          </Menu>
        </Stack>
      </Box>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Create Transaction</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box onSubmit={_onSubmit} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Student"
                    sx={{ display: "flex", flex: 1 }}
                    // value={formData.SID}
                    variant="outlined"
                    name="SID"
                    required
                    onChange={_handleOnChange}
                    defaultValue="Select Student"
                    size="small"
                  >
                    {students.map((student, index) => {
                      return (
                        <MenuItem key={index} value={student.SID}>
                          {student.StudentName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      inputFormat="DD-MM-YYYY"
                      label="Date of issue"
                      value={formData.DateofIssue}
                      onChange={_handleIssueDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ display: "flex", flex: 1 }}
                          size="small"
                          required
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    // loading={loading}
                    type="submit"
                    // onClick={_editSyllabus}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Create Transaction
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}></Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Update Transaction</DialogTitle>
        <DialogContent>
          <Box flexGrow={1}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box onSubmit={_onUpdate} component="form">
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Student"
                    sx={{ display: "flex", flex: 1 }}
                    value={selectedTransactions.SID}
                    variant="outlined"
                    name="SID"
                    required
                    onChange={_handleOnEditChange}
                    defaultValue="Select Student"
                    size="small"
                  >
                    {students.map((student, index) => {
                      return (
                        <MenuItem key={index} value={student.SID}>
                          {student.StudentName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      inputFormat="DD-MM-YYYY"
                      label="Date of issue"
                      value={selectedTransactions.DateofIssue}
                      onChange={_handleEditIssueDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ display: "flex", flex: 1 }}
                          size="small"
                          required
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton
                    // loading={loading}
                    type="submit"
                    // onClick={_editSyllabus}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Update Transaction
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}></Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction has been Added.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={editSnackOpen}
        autoHideDuration={6000}
        onClose={editSnack_handleClose}
      >
        <Alert
          onClose={editSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction has been Updated.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={deleteSnackOpen}
        autoHideDuration={6000}
        onClose={deleteSnack_handleClose}
      >
        <Alert
          onClose={deleteSnack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction has been Deleted.
        </Alert>
      </Snackbar>
    </>
  );
}
