import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import Classes from "./Classes";
import Subjects from "./Subjects";
import SubjectAttachment from "./SubjectAttachment";
import useSession from "../../CustomHooks/useSession";

export default function ClassSubject(params) {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");

  const _setClasses = React.useCallback(
    (classes) => {
      setClasses(classes);
    },
    [setClasses]
  );

  const _setSubjects = React.useCallback(
    (subjects) => {
      setSubjects(subjects);
    },
    [setSubjects]
  );
  return (
    <Stack
      direction={{ xs: "column", sm: "row", md: "row" }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      <Box display={"flex"} sx={{ flex: 1 }} flexDirection="column">
        {currentPageCRUD.read && <Classes setClasses={_setClasses} />}
      </Box>
      <Box display={"flex"} sx={{ flex: 1 }} flexDirection="column">
        {currentPageCRUD.read && <Subjects setSubjects={_setSubjects} />}
      </Box>
      <Box display={"flex"} sx={{ flex: 1 }} flexDirection="column">
        {currentPageCRUD.read && (
          <SubjectAttachment classes={classes} subjects={subjects} />
        )}
      </Box>
    </Stack>
  );
}
