import React, { useEffect, useState } from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const SchoolWiseClassStrength = ({ classStrength }) => {
  const [studentStrenth, setStudentStrenth] = useState([]);
  const [classes, setClasses] = useState([]);
  const [colors, setColors] = useState([]);
  useEffect(() => {
    if (classStrength.length > 0) {
      setStudentStrenth(classStrength.map((item) => item.studentcount));
      setClasses(classStrength.map((item) => item.ClassName));
      setColors(classStrength.map((item) => item.ColorCode));
    }
  }, [classStrength]);
  const options = {
    responsive: true,
    plugins: {
      datalabels:{
        color: "#000000",
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "School Wise Class Capacity Chart",
      },
    },
  };
  const labels = classes;
  const data = {
    labels,
    datasets: [
      {
        label: "Student Strength",
        data: studentStrenth,
        backgroundColor: colors,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default SchoolWiseClassStrength;
