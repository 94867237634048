import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "../../Api/axios.instance";
import IconButton from "@mui/material/IconButton";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Modal from "@mui/material/Modal";
import useSession from "../../CustomHooks/useSession";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const updateSubjectName = async (id, updatedSubjectName) => {
  try {
    const { data, status } = await axiosInstance.put(
      `/subjects/${id}`,
      updatedSubjectName
    );
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function getSubjects() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.get("/subjects");
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function insertClasses(post) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await axiosInstance.post("/subjects", post);
      if (status === 200) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export default function Subjects({ setSubjects: _setSubjects }) {
  const [open, setOpen] = useState(false);
  const [classes, setClasses] = useState([]);
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const formRef = useRef(null);
  const [selectedSubject, setSelectedSubject] = useState({
    ID: null,
    oldValue: null,
    newValue: null,
  });
  useEffect(() => {
    _getSubjects();
  }, []);
  const _getSubjects = async () => {
    try {
      const response = await getSubjects();
      setClasses(response.data);
      _setSubjects(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const post = {
      subjectName: formData.get("subjectName"),
    };
    try {
      const response = await insertClasses(post);
      setClasses((prevState) => {
        return [...prevState, response.data];
      });
      formRef.current.reset();
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpen = (item) => (e) => {
    setOpen(true);
    setSelectedSubject({
      ID: item.ID,
      oldValue: item.SubjectName,
      newValue: item.SubjectName,
    });
  };
  const handleClose = () => setOpen(false);
  const _UpdateSubjectName = async (e) => {
    e.preventDefault();
    if (selectedSubject.oldValue === selectedSubject.newValue) {
      window.alert("Please Enter An Updated Value");
      return;
    }
    try {
      const updateClass = await updateSubjectName(selectedSubject.ID, {
        subjectName: selectedSubject.newValue,
      });

      const index = classes.findIndex((item) => item.ID === selectedSubject.ID);
      if (index !== -1) {
        setClasses((prevState) => {
          prevState[index].SubjectName = selectedSubject.newValue;
          return [...prevState];
        });
      }

      handleClose();
    } catch (error) {
      window.alert(error);
    }
  };
  const _handleUpdateSubjectName = (e) => {
    setSelectedSubject((prevState) => {
      return {
        ...prevState,
        newValue: e.target.value,
      };
    });
  };
  return (
    <Paper square>
      <Box sx={{ my: 1, px: 2 }}>
        <Typography variant="subtitle1">Subjects</Typography>
      </Box>
      <Box
        ref={formRef}
        onSubmit={_onSubmit}
        component="form"
        sx={{
          display: "flex",
          height: 60,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          px: 1,
        }}
      >
        {/*<TextField
          required={true}
          name="subjectName"
          variant="outlined"
          sx={{ color: "white", display: "flex", flex: 1 }}
          label="Enter Subject Name"
          size="small"
          inputProps={{ "aria-label": "Enter Username", "aria-required": true }}
        />
        <LoadingButton
          type="submit"
          loading={false}
          variant="text"
          sx={{ ml: 1, color: "white" }}
        >
          ADD
        </LoadingButton>*/}
      </Box>
      <Divider orientation="horizontal" sx={{ my: 1 }} />
      <List>
        {classes.map((item, index) => (
          <ListItem key={String(index)}>
            <Box
              display={"flex"}
              justifyContent="space-between"
              width={"100%"}
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Typography variant="body2">{item.SubjectName}</Typography>
              {currentPageCRUD.update && (
                <IconButton aria-label="delete" onClick={handleOpen(item)}>
                  <EditTwoToneIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style} component="form" onSubmit={_UpdateSubjectName}>
          <TextField
            required={true}
            name="UpdatedSubjectName"
            variant="outlined"
            sx={{
              color: "white",
              display: "flex",
              flex: 1,
              marginBottom: "20px",
            }}
            label="Enter Updated Subject Name"
            size="small"
            inputProps={{ "aria-label": "Enter Updated Subject Name" }}
            value={selectedSubject.newValue}
            onChange={_handleUpdateSubjectName}
          />
          <Button variant="contained" type="submit">
            Update Subject Name
          </Button>
        </Stack>
      </Modal>
    </Paper>
  );
}
