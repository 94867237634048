import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function ClustersWrapper(props) {
  return (
    <Box display={"flex"} flexDirection="column">
      <Outlet />
    </Box>
  );
}
